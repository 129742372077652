import React from 'react'
import { ReactSVG } from 'react-svg'

import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'

import StarBorderIcon from '../../assets/images/star_border_icon.svg'
import CheckboxField from '../fields/CheckboxField'
import SelectField from '../fields/SelectField'
import { LabelMultiple } from './LabelMultiple'
import { TooltipActions } from './TooltipActions'

const orderOptions = [
  { value: 'sent_in desc', label: 'Más recientes primero' },
  { value: 'sent_in asc', label: 'Más antiguos primero' }
]

const useStyles = makeStyles({
  iconButton: {
    color: '#26292E',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#F2F5F8'
    },
    '&:focus': {
      backgroundColor: '#FFFFFF'
    }
  }
})

export const MessagesOptionsTop = ({
  messagesLength,
  multipleOption,
  checkedTop,
  handleCheckTop,
  handleHighlightTop,
  handleLabelTop
}) => {
  const classes = useStyles()

  return (
    <section className="md:flex items-center justify-between pb-6 mb-6 border-b">
      <article className="flex items-center">
        {messagesLength > 0 && (
          <CheckboxField
            className="hidden md:block px-5 w-"
            name="checkHeader"
            margin="none"
            checked={checkedTop}
            onChange={handleCheckTop}
          />
        )}
        {multipleOption && (
          <>
            <TooltipActions text="Destacar" placement="bottom">
              <IconButton classes={{ root: classes.iconButton }} disableRipple onClick={handleHighlightTop}>
                <ReactSVG src={StarBorderIcon} />
              </IconButton>
            </TooltipActions>
            <LabelMultiple handleMultipleTagged={handleLabelTop} />
          </>
        )}
      </article>
      <SelectField
        name="sort"
        parentClassName="md:w-90"
        containerClassName=""
        label="Ordenar por:"
        orientation="horizontal"
        options={orderOptions}
        defaultOption=""
      />
    </section>
  )
}

MessagesOptionsTop.propTypes = {
  messagesLength: PropTypes.number,
  multipleOption: PropTypes.bool,
  checkedTop: PropTypes.bool,
  handleCheckTop: PropTypes.func,
  handleHighlightTop: PropTypes.func,
  handleLabelTop: PropTypes.func
}
