import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../api/apiCore'
import { Container } from '../../components/Container'
import ReceiptsTable from '../../components/admin/ReceiptsTable'
import { Button } from '../../components/fields/Button'
import * as validations from '../../components/fields/Validations'
import ReceiptForm from '../../components/forms/admin/ReceiptForm'
import Breadcrumb from '../../components/ui/Breadcrumb'
import FormsHeader from '../../components/ui/FormsHeader'
import HeadingTag from '../../components/ui/HeadingTag'
import { MessageCreateSteps } from '../../configs/messages'
import {
  convertDataToBreadcrumbRoute,
  getJsonItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemToLocalStorage
} from '../../helpers/helpers'

export const MultipleSendingPage = () => {
  const [receipts, setReceipts] = useState(getJsonItemFromLocalStorage('addresseData') || [])
  const navigate = useNavigate()
  const breadcrumbData = convertDataToBreadcrumbRoute('Nueva notificación')

  const handleAddRecipientButton = async (values, form) => {
    if (receipts.length === 10) return { [FORM_ERROR]: 'Agregaste el máximo de 10 destinatarios permitidos.' }

    const { citizen_id_code, citizen_id_number } = values

    if (validations.maxLength(8)(citizen_id_number) || validations.minLength(8)(citizen_id_number))
      return { [FORM_ERROR]: 'El número de DNI debe tener 8 dígitos. Ingresa un número válido.' }

    if (receipts.find(receipt => receipt.citizen_id_number === citizen_id_number))
      return { [FORM_ERROR]: 'El usuario con este DNI ya está agregado como destinatario.' }

    const user = await request({ url: '/admin/messages/verify-user.json', data: values, method: 'POST' })
    if (user.response?.data.error) return { [FORM_ERROR]: 'El DNI no está registrado en Casilla Única Electrónica' }

    form.restart()

    const { id, last_names, first_names } = user

    setReceipts(
      receipts.concat({ ...values, citizen_id_code: citizen_id_code.toUpperCase(), first_names, last_names, id })
    )
  }

  const handleDeleteReceipt = id => setReceipts(receipts.filter(receipt => receipt.id !== id))

  const handleContinueButton = () => {
    setItemToLocalStorage('addresseData', JSON.stringify(receipts))
    navigate('/admin/informacion-mensaje')
  }

  const handleCancelButton = () => {
    removeItemFromLocalStorage(['addresseData', 'messageInfoData', 'messageType'])
    navigate('/admin/notificaciones')
  }

  return (
    <>
      <Breadcrumb routesKey="notifications" data={breadcrumbData} />
      <Container className="px-5 pt-8 pb-14 md:px-20 md:pt-14 md:pb-18">
        <FormsHeader
          title="Enviar notificación"
          steps={MessageCreateSteps}
          activeStep={MessageCreateSteps.SET_USER_INFO}
        />
        <section className="border border-gray-250 rounded shadow-message-detail-card p-8 mb-10">
          <article className="text-center">
            <HeadingTag as="h2" title="Agrega a los destinatarios" className="text-2xl font-bold pb-4" />
            <HeadingTag
              as="h3"
              title="Podrás añadir un máximo de 10 destinatarios por notificación"
              className="pb-7.5"
            />
          </article>
          <ReceiptForm handleSubmit={handleAddRecipientButton} />
          <ReceiptsTable data={receipts} onDeleteReceipt={handleDeleteReceipt} />
        </section>
        <section className="flex justify-end gap-6">
          <Button size="full" className="max-w-45" variant="secondary" onClick={handleCancelButton}>
            Cancelar
          </Button>
          <Button size="full" className="max-w-45" disabled={!receipts.length} onClick={handleContinueButton}>
            Continuar
          </Button>
        </section>
      </Container>
    </>
  )
}
