import React from 'react'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import PropTypes from 'prop-types'

const WhenFieldChanges = ({ field, set, to, condition = (a, b) => a !== b, multipleChoiceValue = null }) => {
  return (
    <Field name={set} subscription={{}}>
      {({ input: { onChange } }) => (
        <OnChange name={field}>
          {(value, previous) => {
            if (
              condition(value, previous) &&
              (!multipleChoiceValue || (multipleChoiceValue && !value?.includes(multipleChoiceValue)))
            ) {
              onChange(to)
            }
          }}
        </OnChange>
      )}
    </Field>
  )
}

WhenFieldChanges.propTypes = {
  field: PropTypes.string,
  set: PropTypes.string,
  to: PropTypes.string,
  condition: PropTypes.string,
  multipleChoiceValue: PropTypes.number
}

export default WhenFieldChanges
