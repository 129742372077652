import React from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'

import AutoSave from '../../components/AutoSave'
import { Container } from '../../components/Container'
import DocumentCard from '../../components/documents/DocumentCard'
import Feedback from '../../components/feedback/Feedback'
import SearchField from '../../components/fields/SearchField'
import Breadcrumb from '../../components/ui/Breadcrumb'
import HeadingTag from '../../components/ui/HeadingTag'
import { Pagination } from '../../components/ui/Pagination'
import { convertDataToBreadcrumbRoute, setItemToLocalStorage } from '../../helpers/helpers'
import useFilters from '../../hooks/useFilters'

export const DocumentsSectionPage = () => {
  const { institutionId, categoryId } = useParams()
  const navigate = useNavigate()

  const configOptions = {
    'byInstitution': {
      searchUrl: `documents_user.json?institution_id=${institutionId}`,
      defaultFilters: {
        sort: 'created_at desc',
        institution_id: institutionId
      }
    },
    'byCategory': {
      searchUrl: `documents_user.json?category_id=${categoryId}`,
      defaultFilters: {
        sort: 'created_at desc',
        category_id: categoryId
      }
    },
    'all': {
      searchUrl: 'documents_user.json'
    }
  }

  const handleSelectedCard = documentId => {
    setItemToLocalStorage(
      'breadcrumbItem',
      JSON.stringify({
        parentTitle: categoryId ? documents.category_name : documents.abbreviation,
        route: location.pathname
      })
    )
    navigate(`/documentos/${documentId}`)
  }

  const {
    onFilter,
    results: documents,
    initialFilters
  } = useFilters(
    institutionId
      ? configOptions.byInstitution.searchUrl
      : categoryId
      ? configOptions.byCategory.searchUrl
      : configOptions.all.searchUrl,
    institutionId
      ? configOptions.byInstitution.defaultFilters
      : categoryId
      ? configOptions.byCategory.defaultFilters
      : ''
  )

  if (!documents) return
  const breadcrumbData = convertDataToBreadcrumbRoute(categoryId ? documents.category_name : documents.abbreviation)

  return (
    <>
      <Breadcrumb routesKey="documentsSection" data={breadcrumbData} />
      <Container>
        <Form onSubmit={onFilter} initialValues={initialFilters}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <AutoSave debounce={1000} save={onFilter} />
              <div className="px-5 md:px-20 pt-8 md:pt-14 pb-14 md:pb-18">
                <div className="border-b pb-8 mb-8 md:pb-10 md:mb-10">
                  <HeadingTag
                    title={categoryId ? documents.category_name : `${documents.name} (${documents.abbreviation})`}
                    className="font-bold text-3xl md:text-4xl"
                  />
                  <p className="pt-4 md:pt-5">
                    {categoryId
                      ? documents.category_description
                      : 'Estamos trabajando para incluir todos los documentos de esta entidad. Si no encuentras el documento que estás buscando, visita la plataforma o servicios digitales de la entidad para poder solicitarlo.'}
                  </p>
                  <SearchField
                    name="search"
                    placeholder="Buscar por nombre de documento"
                    containerClassName="mt-7.5 sm:max-w-84 !mb-0"
                  />
                </div>
                {documents.total_count === 0 ? (
                  <div className="font-bold flex justify-center">No se encontraron resultados</div>
                ) : (
                  <div className="md:grid grid-cols-2 gap-5">
                    {documents.data?.map((document, index) => (
                      <DocumentCard
                        key={index}
                        documentData={document}
                        handleSelectedCard={() => handleSelectedCard(document.id)}
                        parentClassName="mt-5 md:mt-0"
                      />
                    ))}
                  </div>
                )}
                {documents.total_count > 0 && <Pagination numPages={documents.total_pages} />}
              </div>
            </form>
          )}
        </Form>
        <Feedback senderType="citizen" />
      </Container>
    </>
  )
}
