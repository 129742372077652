import React from 'react'
import { useField } from 'react-final-form'
import { AsyncPaginate } from 'react-select-async-paginate'

import PropTypes from 'prop-types'

import Error from './Error'

const customStyles = {
  control: base => ({
    ...base,
    '&:hover': { borderColor: '#222222' },
    boxShadow: 'none',
    borderColor: '#222222',
    minHeight: '3rem',
    borderWidth: '2px',
    borderRadius: 0
  })
}

const noOptionsMessage = input =>
  input.inputValue.length >= 3 ? 'No se encontraron resultados' : 'Ingresa al menos 3 caracteres'

const SearchSelectField = ({
  name,
  label,
  validate,
  placeholder = 'Selecciona una opción',
  labelClassName = 'block font-bold mb-2 mt-4 min-w-25',
  className,
  loadOptions,
  isDisabled = false,
  ...props
}) => {
  const { input } = useField(name, { validate, ...props })
  return (
    <>
      {label && (
        <label htmlFor={`${name}-input`} className={labelClassName}>
          {label}
        </label>
      )}
      <AsyncPaginate
        isClearable
        isSearchable
        loadOptions={loadOptions}
        className={className}
        name={name}
        styles={customStyles}
        noOptionsMessage={noOptionsMessage}
        placeholder={placeholder}
        additional={{ page: 1 }}
        cacheOptions
        defaultOptions
        isDisabled={isDisabled}
        {...input}
      />
      <Error name={name} />
    </>
  )
}

export default SearchSelectField

SearchSelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  loadOptions: PropTypes.func,
  isDisabled: PropTypes.bool
}
