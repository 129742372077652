import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { axiosRequest } from '../../../api/apiCore'
import { Container } from '../../../components/Container'
import { Button } from '../../../components/fields/Button'
import OAuthForm from '../../../components/forms/admin/OAuthForm'
import Breadcrumb from '../../../components/ui/Breadcrumb'
import FormsHeader from '../../../components/ui/FormsHeader'
import { moduleManageSteps } from '../../../configs/modules'
import { CONTENT_TYPES, OAUTH_GRANT_TYPES, SERVICE_OK } from '../../../constants/globals'
import {
  convertDataToBreadcrumbRoute,
  getItemFromLocalStorage,
  getJsonItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemToLocalStorage
} from '../../../helpers/helpers'
import { useModuleData } from '../../../hooks/useModules'

const inputsConfig = {
  'client_credentials': {
    firstInput: { label: 'client_id', name: 'client_id' },
    secondInput: { label: 'client_secret', name: 'client_secret' }
  },
  'password': {
    firstInput: { label: 'username', name: 'username' },
    secondInput: { label: 'password', name: 'password' }
  }
}

const initialFormValues = { auth_url: '', content_type: CONTENT_TYPES[1], grant_type: OAUTH_GRANT_TYPES[0] }

export const ServiceInfoPage = () => {
  const { module_id } = useParams()
  const [isVerified, setIsVerified] = useState(false)
  const [inputOptions, setInputOptions] = useState(inputsConfig[OAUTH_GRANT_TYPES[0]])
  const { data: module } = useModuleData(module_id)
  const navigate = useNavigate()

  const isCreateMode = getItemFromLocalStorage('configType') === 'create'
  const serviceData = getJsonItemFromLocalStorage('serviceData')
  const titlePage = `${isCreateMode ? 'Activar' : 'Editar'} módulo Notificaciones`
  const breadcrumbData = convertDataToBreadcrumbRoute(titlePage)
  const initialValues = module?.service_configurations
    ? { ...module?.service_configurations }
    : serviceData ?? initialFormValues
  const formErrorMessage = { [FORM_ERROR]: 'Ocurrió un error. Revisa las credenciales ingresadas' }

  const handleGrantType = value => setInputOptions(inputsConfig[value])

  const handleCancelButton = () => {
    removeItemFromLocalStorage(['serviceData', 'configType'])
    navigate('/admin/modulos')
  }

  const handleSubmit = async values => {
    try {
      if (!isVerified) {
        const { auth_url, client_id, client_secret, grant_type, content_type, authorization, username, password } =
          values

        const dataByGrantType =
          grant_type === OAUTH_GRANT_TYPES[0] ? { client_id, client_secret } : { username, password }

        const response = await axiosRequest({
          url: '/api/v1/admin/service_configurations/verify',
          data: { auth_url, grant_type, content_type, authorization, ...dataByGrantType },
          method: 'POST'
        })

        if (response.status !== SERVICE_OK) return formErrorMessage

        setIsVerified(true)
      } else {
        const serviceData = {
          ...values,
          institution_module_id: module_id,
          service_configuration_id: module?.service_configurations?.id
        }
        setItemToLocalStorage('serviceData', JSON.stringify(serviceData))
        navigate(`/admin/modulos/${module_id}/horarios`)
      }
    } catch (error) {
      return formErrorMessage
    }
  }

  return (
    <>
      <Breadcrumb routesKey="modulesManagement" data={breadcrumbData} />
      <Container className="px-5 pt-8 pb-14 md:px-20 md:pt-14 md:pb-18">
        <FormsHeader title={titlePage} steps={moduleManageSteps} activeStep={moduleManageSteps.SET_WS_INFO} />
        <OAuthForm
          title={moduleManageSteps.SET_WS_INFO.label}
          subtitle={moduleManageSteps.SET_WS_INFO.hint}
          isVerifiedService={isVerified}
          setIsVerifiedService={setIsVerified}
          firstInputOptions={inputOptions.firstInput}
          secondInputOptions={inputOptions.secondInput}
          initialValues={initialValues}
          handleFormSubmit={handleSubmit}
          handleGrantType={handleGrantType}
        />
        <section className="flex gap-5 mx-auto max-w-xl mt-10">
          <Button size="full" variant="secondary" onClick={handleCancelButton}>
            Cancelar
          </Button>
          <Button type="submit" form="serviceForm" size="full" disabled={!isVerified}>
            Continuar
          </Button>
        </section>
      </Container>
    </>
  )
}
