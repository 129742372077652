const defaultMessagesInitialRoutes = [{ route: '/admin/inicio', label: 'Inicio', showInMobile: false }]
const defaultCitizenInitialRoutes = [{ route: '/inicio', label: 'Inicio', showInMobile: false }]

export const allRoutes = {
  notifications: [
    ...defaultMessagesInitialRoutes,
    { route: '/admin/notificaciones', label: 'Notificaciones', showInMobile: true }
  ],
  communications: [
    ...defaultMessagesInitialRoutes,
    { route: '/admin/notificaciones', label: 'Comunicaciones', showInMobile: true }
  ],
  modulesManagement: [
    ...defaultMessagesInitialRoutes,
    { route: '/admin/modulos', label: 'Configuración de módulos', showInMobile: true }
  ],
  documentsManager: [
    ...defaultMessagesInitialRoutes,
    { route: '/admin/documentos-y-categorias', label: 'Admin mis documentos', showInMobile: true }
  ],
  users: [{ route: '/admin/areas-y-usuarios', label: 'Usuarios', showInMobile: true }],
  roles: [
    ...defaultMessagesInitialRoutes,
    { route: '/admin/roles-y-ciudadanos', label: 'Roles y ciudadanos', showInMobile: true }
  ],
  myNotifications: [{ route: '/notificaciones', label: 'Mis notificaciones', showInMobile: true }],
  documentDetail: [
    ...defaultCitizenInitialRoutes,
    { route: '/documentos', label: 'Mis documentos', showInMobile: false }
  ],
  documentsSection: [
    ...defaultCitizenInitialRoutes,
    { route: '/documentos', label: 'Mis documentos', showInMobile: true }
  ],
  webService: [
    ...defaultCitizenInitialRoutes,
    { route: '/admin/web-services', label: 'Web services', showInMobile: true }
  ],
  myProfile: [{ route: '/inicio', label: 'Inicio', showInMobile: true }]
}
