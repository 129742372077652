import React, { useEffect } from 'react'
import { ReactSVG } from 'react-svg'

import { Close as CloseIcon } from '@material-ui/icons'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Button } from '@/components/fields/Button'

import SuccessAlertIconNew from '../../assets/images/success_alert_icon_new.svg'

const alertConfig = {
  success: {
    color: 'text-green-800 bg-green-400 shadow-alert-success',
    icon: <ReactSVG src={SuccessAlertIconNew} className="text-green-600" />
  }
}

const AlertNew = ({
  type,
  children,
  autoHideDuration,
  onClose,
  className,
  hideCloseIcon = false,
  classNameText,
  innerHTML
}) => {
  const { icon, color } = alertConfig[type]

  useEffect(() => {
    if (autoHideDuration) {
      setTimeout(() => onClose(), autoHideDuration)
    }
  }, [children])

  return (
    <div
      className={clsx('p-4 md:p-6 flex gap-6 md:gap-10 justify-between rounded-lg self-start', color, className)}
      role="alert"
    >
      <div className="flex gap-4">
        {icon}
        <span
          className={clsx('font-medium flex-1 text-sm md:text-base', classNameText)}
          dangerouslySetInnerHTML={{ __html: innerHTML }}
        >
          {children}
        </span>
      </div>
      {!hideCloseIcon && (
        <Button endIcon={<CloseIcon />} variant="text" fullWidth={false} aria-label="Cerrar" onClick={onClose}>
          <div className="bold text-sm">Cerrar</div>
        </Button>
      )}
    </div>
  )
}

AlertNew.propTypes = {
  type: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func,
  hideCloseIcon: PropTypes.bool,
  classNameText: PropTypes.string,
  innerHTML: PropTypes.string
}

export default AlertNew
