import React from 'react'
import { ReactSVG } from 'react-svg'

import DialogMui from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import CloseIcon from '~/assets/images/close_icon.svg'
import { Button } from '~/components/fields/Button'

const Dialog = ({ open, onClose, children }) => {
  return (
    <DialogMui
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 4,
          boxShadow: '2px 3px 20px 0px rgba(203, 218, 232, 0.50)',
          border: '1px solid #DEE3EA'
        }
      }}
    >
      <div className="p-7.5 px-9 pb-9">
        <div className="flex justify-end pb-5">
          <Button
            startIcon={<ReactSVG src={CloseIcon} />}
            variant="text"
            fullWidth={false}
            onClick={onClose}
            className="text-blue-700"
          >
            Cerrar
          </Button>
        </div>
        {children}
      </div>
    </DialogMui>
  )
}
Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.element
}
export default Dialog
