import React, { Fragment } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../../api/apiCore'
import { useCategoryData } from '../../../hooks/useCategories'
import { Container } from '../../Container'
import { CategoryForm } from '../../forms/admin/documents/CategoryForm'
import HeadingTag from '../../ui/HeadingTag'

export const CategoryEdit = () => {
  const { id: categoryId } = useParams()
  const url = '/admin/categories'

  const { data: category, status } = useCategoryData(categoryId, url)
  const navigate = useNavigate()
  const onSubmit = async data => {
    try {
      await request({ url: `/admin/categories/${categoryId}.json`, data, method: 'PATCH' })
      navigate('/admin/documentos-y-categorias')
    } catch (error) {
      return { [FORM_ERROR]: 'Ocurrio un error al registrar la categoría.' }
    }
  }

  if (status !== 'success') return null

  return (
    <Fragment>
      <Container className="py-16 pl-20 px-6">
        <HeadingTag title="Editar categoría" className="font-bold mb-4 text-3xl" />
        <CategoryForm initialValues={category} onSubmit={onSubmit} />
      </Container>
    </Fragment>
  )
}
