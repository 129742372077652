import React from 'react'
import { ReactSVG } from 'react-svg'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import CloseIconAlert from '../../assets/images/close_icon_alert.svg'
import CheckCircleIcon from '../../assets/images/success_alert_icon.svg'
import { getItemFromLocalStorage, removeItemFromLocalStorage } from '../../helpers/helpers'
import ScrollToTop from './ScrollToTop'

const alertConfig = {
  success: {
    icon: <ReactSVG src={CheckCircleIcon} className="text-green-700 mr-3.5 !text-2xl" />,
    color: 'bg-green-40 border-green-600 bg-opacity-5 text-green-800'
  },
  danger: {
    icon: <ErrorOutlineIcon className="text-red-700 mr-3.5 !text-2xl" />,
    color: 'bg-red-100 text-red-800'
  },
  warning: {
    icon: <ErrorOutlineIcon className="text-yellow-700 mr-3.5 !text-2xl" />,
    color: 'bg-yellow-100 text-yellow-800'
  }
}

const Alert = ({ children, autoHideDuration, type, setActionAlert }) => {
  const { icon, color } = alertConfig[type]
  const onClose = () => {
    removeItemFromLocalStorage(['actionAlert'])
    setActionAlert(getItemFromLocalStorage('actionAlert'))
  }

  setTimeout(() => {
    onClose()
  }, autoHideDuration)

  return (
    <div id="ref" className={clsx('flex items-center border-solid border mb-8 px-5 py-3', `${color}`)}>
      {icon}
      <p className="w-full text-base font-normal">{children}</p>
      {
        <ReactSVG
          src={CloseIconAlert}
          className={`text-${color}-700 cursor-pointer !text-lg	ml-2.5 !font-thin`}
          onClick={onClose}
        />
      }
      <ScrollToTop />
    </div>
  )
}

Alert.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  autoHideDuration: PropTypes.number,
  type: PropTypes.string,
  setActionAlert: PropTypes.func
}

export default Alert
