import React from 'react'
import { ReactSVG } from 'react-svg'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ErrorRuleIcon from '../../assets/images/error_rule_icon.svg'
import SuccessRuleIcon from '../../assets/images/success_rule_icon.svg'

const PasswordRules = ({ title, value, rules }) => {
  return (
    <ul className="pb-5">
      <div className="font-semibold pb-3.5">{title}</div>
      {rules.map((rule, index) => (
        <li
          key={index}
          className={clsx(!rule.validate(value) ? 'text-green-500' : 'text-red-700', 'flex items-center pb-2')}
        >
          {!rule.validate(value) ? <ReactSVG src={SuccessRuleIcon} /> : <ReactSVG src={ErrorRuleIcon} />}
          <span className="pl-3">{rule.text}</span>
        </li>
      ))}
    </ul>
  )
}

PasswordRules.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  rules: PropTypes.array
}

export default PasswordRules
