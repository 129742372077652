import { useQuery } from 'react-query'

import { request } from '../api/apiCore'

const fetchInstitutionById = id => request({ url: `/admin/institutions/${id}.json` })

const fetchUserInstitutions = () => request({ url: '/institutions_messages_user.json' })

const fetchInstitutionsByGobpeId = (gobpe_id, root) =>
  request({ url: `${root}/${gobpe_id}.json/?gobpe_id=${gobpe_id}` })

export const useInstitutionGobpeIdData = (gobpe_id, root) =>
  useQuery(['institution', gobpe_id], () => fetchInstitutionsByGobpeId(gobpe_id, root))

export const useInstitutionData = id => useQuery(['institution', id], () => fetchInstitutionById(id))

export const useUserInstitutionsData = () => useQuery('user-institutions', () => fetchUserInstitutions())
