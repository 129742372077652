import React from 'react'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'

import AreaIcon from '../../assets/images/area_icon.svg'
import DatetimeIcon from '../../assets/images/datetime_icon.svg'
import EntityIcon from '../../assets/images/entity_icon.svg'
import ProcedureIcon from '../../assets/images/procedure_icon.svg'
import { pluralize } from '../../helpers/helpers'
import { MessageContentBody } from './MessageContentBody'
import { MessageContentSubtitle } from './MessageContentSubtitle'
import { MessageContentTitle } from './MessageContentTitle'
import MessageFile from './MessageFile'

const subtitleItems = {
  institution: {
    label: 'Entidad:',
    icon: <ReactSVG src={EntityIcon} />,
    show: ['notification', 'communication']
  },
  area_name: {
    label: 'Área emisora:',
    icon: <ReactSVG src={AreaIcon} />,
    show: ['notification', 'communication']
  },
  procedure_name: {
    label: 'Trámite:',
    icon: <ReactSVG src={ProcedureIcon} />,
    show: ['notification']
  },
  sent_in: {
    label: 'Fecha y hora de envío:',
    icon: <ReactSVG src={DatetimeIcon} />,
    show: ['notification', 'communication']
  }
}

const getSubtitleItems = message =>
  Object.entries(subtitleItems).map(
    ([key, params], index) =>
      message[key] &&
      params.show.includes(message.message_type) && (
        <MessageContentSubtitle
          key={index}
          icon={params.icon}
          subtitle={params.label}
          description={message[key]}
          contentClassName="mt-4"
          subtitleClassName="pl-3 leading-4"
        />
      )
  )

const AttachedFilesLabel = ({ filesNumber, hasFiles = filesNumber > 0 }) => (
  <p className={`font-bold ${hasFiles && 'pb-0 md:pb-6'} `}>
    {`${hasFiles ? pluralize(filesNumber, 'archivo adjunto:', 'archivos adjuntos:') : 'No hay archivos adjuntos'}`}
  </p>
)

export const MessageContent = ({ message }) => (
  <div className="mx-5 mt-8 mb-16 md:mx-20 md:mt-12">
    <div className="bg-gray-40 p-6 md:p-9 rounded">
      <MessageContentTitle title={message.subject} type={message.message_type} />
      {getSubtitleItems(message)}
      <MessageContentBody messageDescription={message.content} />
      <AttachedFilesLabel
        filesNumber={message.message_type === 'notification' ? message.files.length + 1 : message.files.length}
      />
      <section className="md:grid md:grid-cols-2 md:gap-5">
        {message.files.map((file, index) => (
          <MessageFile key={index} description={file.name} downloadUrl={`/file-infos-user/${file.id}.pdf`} />
        ))}
        {message.message_type === 'notification' && (
          <MessageFile
            description="Acuse de recibo"
            downloadUrl={`/messages-user/${message.id}.pdf?export_type=receipt`}
          />
        )}
      </section>
    </div>
  </div>
)

MessageContent.propTypes = {
  message: PropTypes.object
}

AttachedFilesLabel.propTypes = {
  filesNumber: PropTypes.number,
  hasFiles: PropTypes.bool
}
