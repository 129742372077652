import React from 'react'

import { Container } from '../../components/Container'
import FeedbackCard from '../../components/feedback/FeedbackCard'
import BoxTable from '../../components/ui/BoxTable'
import PageHeading from '../../components/ui/PageHeading'
import { Pagination } from '../../components/ui/Pagination'
import { ORDER_OPTIONS } from '../../constants/globals'
import useFilters from '../../hooks/useFilters'

export const FeedbackPage = () => {
  const {
    initialFilters,
    results: feedbacks,
    onFilter
  } = useFilters('admin/feedbacks.json', { sort: 'created_at desc' })

  return (
    <Container>
      <div className="px-5 md:px-20 pt-8 md:pt-14 pb-14 md:pb-18">
        <PageHeading title="Feedbacks" pageDescription="Revisa los feedbacks de ciudadanos y funcionarios." />
        <BoxTable
          displayHeader={false}
          initialFilters={initialFilters}
          onFilter={onFilter}
          sortOptions={ORDER_OPTIONS}
          values={feedbacks}
          searchPlaceholder="Buscar por descripción"
          displayTotal={true}
          searchFieldName="search"
        >
          {feedbacks.data.map(feedback => (
            <FeedbackCard key={feedback.id} feedback={feedback} />
          ))}
          <Pagination numPages={feedbacks.total_pages} />
        </BoxTable>
      </div>
    </Container>
  )
}
