import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../../api/apiCore'
import { convertDataToBreadcrumbRoute } from '../../../helpers/helpers'
import { useRoleData } from '../../../hooks/useRoles'
import { Container } from '../../Container'
import { RolesForm } from '../../forms/admin/RolesForm'
import Breadcrumb from '../../ui/Breadcrumb'
import HeadingTag from '../../ui/HeadingTag'

export const RoleEdit = () => {
  const breadcrumbData = convertDataToBreadcrumbRoute('Editar rol gestión')
  const { id: roleId } = useParams()
  const { data: role, status } = useRoleData(roleId)
  const navigate = useNavigate()
  const onSubmit = async ({ name, modules, roles_allowed_create }) => {
    try {
      const role_modules_attributes = []
      role?.role_modules.map(
        m =>
          !modules?.includes(m.app_module_id) &&
          role_modules_attributes.push({ id: m.id, app_module_id: m.app_module_id, _destroy: true })
      )
      modules.map(
        m =>
          !role?.role_modules?.find(rm => rm.app_module_id === m) && role_modules_attributes.push({ app_module_id: m })
      )
      const data = {
        name,
        role_modules_attributes,
        roles_allowed_create: roles_allowed_create?.map(el => el.value) || []
      }
      const response = await request({ url: `/admin/roles/${roleId}.json`, data, method: 'PATCH' })
      if (response.response?.data.error) return { [FORM_ERROR]: `Ocurrió un error: ${response.response.data.error}` }
      navigate('/admin/roles-y-ciudadanos')
    } catch (error) {
      return { [FORM_ERROR]: 'Ocurrio un error al editar el rol.' }
    }
  }
  if (status !== 'success') return null
  return (
    <Fragment>
      <Breadcrumb routesKey="roles" data={breadcrumbData} />
      <Container className="py-16 pl-20 px-6">
        <HeadingTag title="Editar rol gestión" className="mb-4 font-bold text-3xl" />
        <RolesForm initialValues={role} onSubmit={onSubmit} />
      </Container>
    </Fragment>
  )
}
