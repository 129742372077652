import React, { Fragment } from 'react'
import { useQueryClient } from 'react-query'

import { request } from '../../../api/apiCore'
import { ORDER_OPTIONS } from '../../../constants/globals'
import useFilters from '../../../hooks/useFilters'
import BoxTable from '../../ui/BoxTable'
import CategoryTable from './CategoryTable'

export const Categories = () => {
  const url = 'admin/categories'
  const queryClient = useQueryClient()
  const handleDestroy = async category => {
    if (window.confirm(`¿Estás seguro que deseas eliminar la categoría ${category.name}?`)) {
      try {
        await request({ url: `${url}/${category.id}`, method: 'DELETE' })
        queryClient.invalidateQueries(`${url}.json`)
      } catch (error) {
        window.alert(error.response.data.message)
      }
    }
  }

  const { onFilter, initialFilters, results: categories } = useFilters(`${url}.json`, { sort: 'created_at desc' })

  return (
    <Fragment>
      <BoxTable
        title="Categorías"
        initialFilters={initialFilters}
        onFilter={onFilter}
        buttons={[{ Text: 'Crear nueva categoría', cardUrl: '/admin/categorias/crear', isActiveButton: true }]}
        sortOptions={ORDER_OPTIONS}
        values={categories}
        searchPlaceholder="Buscar por nombre"
        displayTotal={true}
        searchFieldName="search"
      >
        <CategoryTable data={categories.data} onDestroy={handleDestroy} totalPages={categories.total_pages} />
      </BoxTable>
    </Fragment>
  )
}
