import React from 'react'
import { ReactSVG } from 'react-svg'

import ModalMobileLabelForm from '~/components/notifications/ModalMobileLabelForm'

import AddIcon from '../../assets/images/add_icon.svg'
import useToggle from '../../hooks/useToogle'

export const LabelButtonMobile = () => {
  const [toggleFilter, settoggleFilter] = useToggle(false)

  return (
    <div className="md:hidden">
      <button onClick={() => settoggleFilter()} className="text-blue-700 flex">
        <ReactSVG src={AddIcon} />
        <div className="ml-2 font-medium">Nueva etiqueta</div>
      </button>
      <ModalMobileLabelForm
        toggle={toggleFilter}
        setToggle={settoggleFilter}
        modalTitle="Nueva etiqueta:"
        createMode={true}
      />
    </div>
  )
}
