import React from 'react'
import TextField from './TextField'
import * as validations from './Validations'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'
import LinkIcon from '../../assets/images/link.svg'
import GoToLink from '../../assets/images/go_to_link.svg'

const stopPropagation = event => {
  event.stopPropagation()
}

const setURL = url => {
  try {
    new URL(url)
    return url
  } catch (error) {
    return `http://${url}`
  }
}

const LinkBox = ({ inputValue }) => {
  return (
    <a
      href={setURL(inputValue)}
      className="w-1/5 bg-sky-blue-400 flex justify-center items-center py-3 border border-l-0 border-black border-solid"
      target="_blank"
      rel="noreferrer"
    >
      <img src={GoToLink} alt="" className="cursor-pointer w-5 h-4" />
    </a>
  )
}

const LinkToolBarBox = ({ currentState, onChange, doCollapse }) => {
  const onEraseHandler = () => {
    onChange()
    doCollapse()
  }

  const linkTitle = currentState.link?.title
  const linkTarget = currentState.link?.target

  const initialValues = {
    url: linkTarget,
    selectionText: currentState.selectionText || linkTitle
  }

  const boxesHavePrevContent = linkTitle && linkTarget

  const send = (form, valid, values) => {
    form.focus('url')
    form.blur('url')
    form.focus('selectionText')
    form.blur('selectionText')
    valid ? onChange('link', values.selectionText, values.url, '_blank') : false
  }

  return (
    <div
      className="absolute -bottom-53.25 bg-neutral-300 px-4 py-2 w-68 z-10 border-2 border-solid border-black"
      onClick={stopPropagation}
      onFocus={stopPropagation}
      onBlur={stopPropagation}
    >
      <div className="relative">
        <Form onSubmit={send} initialValues={initialValues}>
          {({ handleSubmit, form, valid, values }) => (
            <form className="" onSubmit={handleSubmit}>
              <TextField
                name="url"
                label={`${boxesHavePrevContent ? 'Edita' : 'Inserta'} la URL`}
                validate={validations.mix(validations.required(), validations.isURL())}
                size="xl"
                labelClassName="text-sm"
                errorClassName="text-xs"
                rightDecorator={LinkBox}
              />
              <TextField
                name="selectionText"
                label="Texto"
                validate={validations.required()}
                size="xl"
                labelClassName="text-sm"
                margin="dense"
                errorClassName="text-xs"
              />
              <div className="flex justify-end mb-2">
                {boxesHavePrevContent ? (
                  <button className="text-blue-700 font-bold text-sm mr-4 leading-none" onClick={onEraseHandler}>
                    Eliminar URL
                  </button>
                ) : null}
                <button
                  className="text-blue-700 font-bold text-sm leading-none"
                  type="button"
                  onClick={() => send(form, valid, values)}
                >
                  {boxesHavePrevContent ? 'Editar' : 'Insertar'}
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

export const LinkToolBar = props => {
  return (
    <div className="relative" onClick={props.onExpandEvent}>
      <img src={LinkIcon} alt="" className="cursor-pointer" />
      {props.expanded ? <LinkToolBarBox {...props} /> : null}
    </div>
  )
}

LinkToolBarBox.propTypes = {
  currentState: PropTypes.object,
  onChange: PropTypes.func,
  doCollapse: PropTypes.func
}

LinkBox.propTypes = {
  inputValue: PropTypes.string
}

LinkToolBar.propTypes = {
  onExpandEvent: PropTypes.func,
  expanded: PropTypes.bool
}
