import React, { Fragment } from 'react'
import { useQueryClient } from 'react-query'

import { request } from '../../../api/apiCore'
import { ORDER_OPTIONS } from '../../../constants/globals'
import useFilters from '../../../hooks/useFilters'
import BoxTable from '../../ui/BoxTable'
import DocumentTable from './DocumentTable'

export const Documents = () => {
  const queryClient = useQueryClient()
  const url = 'admin/documents'
  const { onFilter, initialFilters, results: documents } = useFilters(`${url}.json`, { sort: 'created_at desc' })

  const handleDestroy = async document => {
    if (window.confirm(`¿Estás seguro que deseas eliminar el documento ${document.name}?`)) {
      try {
        await request({ url: `${url}/${document.id}`, method: 'DELETE' })
        queryClient.invalidateQueries(`${url}.json`)
      } catch (error) {
        window.alert(error.response.data.message)
      }
    }
  }

  return (
    <Fragment>
      <BoxTable
        title="Documentos"
        initialFilters={initialFilters}
        onFilter={onFilter}
        buttons={[{ Text: 'Crear nuevo documento', cardUrl: '/admin/documentos/crear', isActiveButton: true }]}
        sortOptions={ORDER_OPTIONS}
        values={documents}
        searchPlaceholder="Buscar por nombre"
        displayTotal={true}
        searchFieldName="search"
      >
        <DocumentTable data={documents.data} onDestroy={handleDestroy} totalPages={documents.total_pages} />
      </BoxTable>
    </Fragment>
  )
}
