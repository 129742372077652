import React from 'react'
import { useField } from 'react-final-form'

import RadioGroup from '@material-ui/core/RadioGroup'
import PropTypes from 'prop-types'

import Error from '../../components/fields/Error'
import { RadioOption } from './RadioOption'

const RadioFieldInfo = ({ label, name, validate, options = [], containerClassName = 'mb-8', ...props }) => {
  const { input } = useField(name, { validate, ...props })

  return (
    <div className="relative">
      <fieldset className={containerClassName}>
        <legend className="font-bold mb-1.5">{label}</legend>
        <RadioGroup
          aria-label={label}
          name={name}
          {...input}
          onChange={e => {
            input.onChange(e.target.value)
            props.onChange && props.onChange(e.target.value)
          }}
          {...props}
        >
          {options.map(option => (
            <RadioOption key={option.id} option={option} />
          ))}
        </RadioGroup>
        <Error name={name} className="absolute -bottom-4" />
      </fieldset>
    </div>
  )
}

export default RadioFieldInfo

RadioFieldInfo.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  onChange: PropTypes.func
}
