import React from 'react'
import { useField } from 'react-final-form'
import { ReactSVG } from 'react-svg'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import checkboxIcon from '../../assets/images/check_icon.svg'
import checkedIcon from '../../assets/images/checked_icon.svg'
import { marginSizes } from '../../utils/sizes'
import Error from './Error'

const RadioButtonField = ({
  name,
  validate,
  label,
  margin = 'normal',
  className,
  formControlClassName,
  hideError = false,
  disabled = false,
  icon = <ReactSVG src={checkboxIcon} />,
  checkIcon = <ReactSVG src={checkedIcon} />,
  customClass,
  ...props
}) => {
  const { input } = useField(name, { validate, type: 'radio', ...props })
  const classes = customClass()
  return (
    <div className={clsx(className, marginSizes[margin], 'flex items-baseline relative js-field-container')}>
      <FormControlLabel
        control={
          <Radio
            {...input}
            disableRipple
            color="default"
            name={name}
            disabled={disabled}
            icon={icon}
            checkedIcon={checkIcon}
            classes={{ root: classes.checkboxInput }}
            {...props}
          />
        }
        classes={{ label: '!font-bold', root: classes.contolLabel }}
        className={formControlClassName}
        label={label}
      />
      {hideError ? null : <Error name={name} className="absolute -bottom-4" />}
    </div>
  )
}

export default RadioButtonField

RadioButtonField.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
  label: PropTypes.node,
  margin: PropTypes.string,
  className: PropTypes.string,
  formControlClassName: PropTypes.string,
  hideError: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  checkIcon: PropTypes.element,
  customClass: PropTypes.func
}
