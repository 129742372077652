import React from 'react'

import { HomePage } from '../pages/HomePage'
import { AreasAndUsersPage } from '../pages/admin/AreasAndUserPage'
import { DocumentsAndCategoriesPage } from '../pages/admin/DocumentsAndCategoriesPage'
import { FeedbackPage } from '../pages/admin/FeedbackPage'
import { HomePage as HomeOfficial } from '../pages/admin/HomePage'
import { MessagesPage as OfficialMessagePage } from '../pages/admin/MessagesPage'
import { RolesAndCitizensPage } from '../pages/admin/RolesAndCitizensPage'
import { WebServicesPage } from '../pages/admin/WebServicesPage'
import { InstitutionModules } from '../pages/admin/modules/InstitutionModules'
import { DocumentsPage } from '../pages/documents/DocumentsPage'
import { MessagesPage } from '../pages/notifications/MessagesPage'

const getTabsTree = (isAdminTab, isUserTab, admin) => [
  { name: 'Inicio', path: '/admin/inicio', component: <HomeOfficial />, visible: isAdminTab },
  {
    name: 'Notificaciones',
    path: '/admin/notificaciones',
    component: <OfficialMessagePage />,
    visible: isAdminTab
  },
  {
    name: 'Configuración',
    path: '',
    component: null,
    visible: isAdminTab && admin.is_not_collaborator,
    subMenu: [
      {
        name: 'Configuración de módulos',
        path: '/admin/modulos',
        component: <InstitutionModules />,
        visible: admin.is_any_admin,
        isChildTab: true
      },
      {
        name: 'Áreas y usuarios',
        path: '/admin/areas-y-usuarios',
        component: <AreasAndUsersPage />,
        visible: true,
        isChildTab: true
      },
      {
        name: 'Roles y Ciudadanos',
        path: '/admin/roles-y-ciudadanos',
        component: <RolesAndCitizensPage />,
        visible: admin.is_admin_carpeta,
        isChildTab: true
      },
      {
        name: 'Admin Mis documentos',
        path: '/admin/documentos-y-categorias',
        component: <DocumentsAndCategoriesPage />,
        visible: admin.is_admin_carpeta,
        isChildTab: true
      },
      {
        name: 'Feedbacks',
        path: '/admin/feedbacks',
        component: <FeedbackPage />,
        visible: admin.is_admin_carpeta,
        isChildTab: true
      },
      {
        name: 'Web services',
        path: '/admin/web-services',
        component: <WebServicesPage />,
        visible: admin.is_admin_carpeta,
        isChildTab: true
      }
    ]
  },
  { name: 'Inicio', path: '/inicio', component: <HomePage />, visible: isUserTab },
  {
    name: 'Mis documentos',
    path: '/documentos',
    component: <DocumentsPage />,
    visible: isUserTab
  },
  {
    name: 'Notificaciones',
    path: '/notificaciones',
    component: <MessagesPage />,
    visible: isUserTab
  }
]

const flatTabsTreeAndFilterVisible = tabs => {
  let allTabs = [...tabs]
  tabs.forEach(tab => {
    if (tab.subMenu?.length) {
      allTabs = [...allTabs, ...tab.subMenu]
    }
  })
  return allTabs.filter(tab => tab.visible)
}

const getSubTabsSelectedAndVisible = (tabsTree, parentTabSel) =>
  tabsTree.find(menu => menu.name === parentTabSel)?.subMenu.filter(subTab => subTab.visible)

const isParentTab = tab => (tab.subMenu ? true : false)
const hasChildTabSelected = (tabs, pathname) => tabs.subMenu?.find(child => child.path === pathname)
const isLastParentTab = (index, tabs) => index + 1 === tabs.filter(tab => !tab.isChildTab).length

export {
  flatTabsTreeAndFilterVisible,
  getSubTabsSelectedAndVisible,
  getTabsTree,
  hasChildTabSelected,
  isLastParentTab,
  isParentTab
}
