import React, { Fragment } from 'react'
import { useQueryClient } from 'react-query'

import { request } from '../../../api/apiCore'
import { ORDER_OPTIONS } from '../../../constants/globals'
import useFilters from '../../../hooks/useFilters'
import BoxTable from '../../ui/BoxTable'
import RolesTable from './RolesTable'

export const Roles = () => {
  const queryClient = useQueryClient()
  const handleDestroy = async role => {
    if (window.confirm(`¿Estás seguro que deseas eliminar el rol ${role.name}?`)) {
      try {
        const response = await request({ url: `/admin/roles/${role.id}`, method: 'DELETE' })
        if (response.response?.data.error) return alert(`Ocurrió un error: ${response.response.data.error}`)
        queryClient.invalidateQueries('admin/roles.json')
      } catch (error) {
        window.alert(error.response.data.message)
      }
    }
  }

  const { onFilter, initialFilters, results: roles } = useFilters('admin/roles.json', { sort: 'created_at desc' })
  return (
    <Fragment>
      <BoxTable
        title="Roles"
        initialFilters={initialFilters}
        onFilter={onFilter}
        buttons={[{ Text: 'Crear rol gestión', cardUrl: '/admin/roles/crear', isActiveButton: true }]}
        sortOptions={ORDER_OPTIONS}
        values={roles}
        searchPlaceholder="Buscar"
        displayTotal={true}
        searchFieldName="buscar"
      >
        <RolesTable roles={roles.data} onDestroy={handleDestroy} totalPages={roles.total_pages} />
      </BoxTable>
    </Fragment>
  )
}
