import React from 'react'

import PropTypes from 'prop-types'

import * as validations from '../../components/fields/Validations'
import TextField from '../fields/TextField'

const MessageAttachedFile = ({
  fileName,
  filePathName,
  labelFileName = 'Nombre del archivo',
  labelFilePathName = 'Ruta del archivo'
}) => {
  return (
    <>
      <TextField
        size="full"
        labelClassName="!font-bold mb-2"
        name={fileName}
        label={labelFileName}
        validate={validations.required()}
      />
      <TextField
        size="full"
        labelClassName="!font-bold mb-2"
        margin="none"
        name={filePathName}
        label={labelFilePathName}
        validate={validations.mix(validations.required(), validations.isURL())}
      />
    </>
  )
}

export default MessageAttachedFile

MessageAttachedFile.propTypes = {
  fileName: PropTypes.string,
  filePathName: PropTypes.string,
  labelFileName: PropTypes.string,
  labelFilePathName: PropTypes.string
}
