import React, { useState } from 'react'

import { IconButton, Popper } from '@material-ui/core'
import BookmarkBorderSharpIcon from '@material-ui/icons/BookmarkBorderSharp'
import BookmarkSharpIcon from '@material-ui/icons/BookmarkSharp'
import { makeStyles } from '@material-ui/styles'
import ToggleIcon from 'material-ui-toggle-icon'
import PropTypes from 'prop-types'

import { LabelButton } from './LabelButton'
import { LabelListPopper } from './LabelListPopper'
import { TooltipActions } from './TooltipActions'

const useStyles = makeStyles({
  iconButton: {
    color: '#26292E',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#F2F5F8'
    },
    '&:focus': {
      backgroundColor: '#FFFFFF'
    },
    '&:active': {
      backgroundColor: '#F2F5F8'
    }
  }
})

export const LabelMultiple = ({ handleMultipleTagged }) => {
  const [label, setLabel] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setLabel(!label)
  }

  const open = Boolean(anchorEl)

  const classes = useStyles()

  return (
    <>
      <TooltipActions text="Etiquetar" placement="bottom">
        <IconButton disableRipple classes={{ root: classes.iconButton }} onClick={handleClick}>
          <ToggleIcon on={label} onIcon={<BookmarkSharpIcon />} offIcon={<BookmarkBorderSharpIcon />} />
        </IconButton>
      </TooltipActions>
      <Popper open={open} anchorEl={anchorEl}>
        <div className="w-48 bg-white border shadow-label-window">
          <div className="mx-2 px-2 pt-4 border-b text-left">
            <LabelListPopper title="Etiquetar como:" listClass="pt-4" handleTagged={handleMultipleTagged} />
          </div>
          <div className="bg-gray-40">
            <LabelButton buttonClassName="p-4" />
          </div>
        </div>
      </Popper>
    </>
  )
}

LabelMultiple.propTypes = {
  handleMultipleTagged: PropTypes.func
}
