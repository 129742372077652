import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../api/apiCore'
import { Container } from '../../components/Container'
import { Button } from '../../components/fields/Button'
import SaveButton from '../../components/fields/SaveButton'
import SelectField from '../../components/fields/SelectField'
import TextField from '../../components/fields/TextField'
import * as validations from '../../components/fields/Validations'
import Breadcrumb from '../../components/ui/Breadcrumb'
import FormsHeader from '../../components/ui/FormsHeader'
import { MessageCreateSteps } from '../../configs/messages'
import { DOCUMENT_TYPES } from '../../constants/globals'
import {
  appName,
  convertDataToBreadcrumbRoute,
  getItemFromLocalStorage,
  getJsonItemFromLocalStorage,
  removeItemFromLocalStorage,
  setItemToLocalStorage
} from '../../helpers/helpers'

const pageData = {
  notification: {
    title: 'Enviar una notificación',
    breadcrumbText: 'Nueva notificación',
    key: 'notifications'
  },
  communication: {
    title: 'Enviar una comunicación',
    breadcrumbText: 'Nueva comunicación',
    key: 'communications'
  }
}

export const AddresseeDataPage = () => {
  const [citizenExits, setCitizenExits] = useState(false)
  const [initialData, setInitialData] = useState(getJsonItemFromLocalStorage('addresseData'))
  const navigate = useNavigate()

  const messageType = getItemFromLocalStorage('messageType')
  const { title, breadcrumbText, key } = pageData[messageType]
  const breadcrumbData = convertDataToBreadcrumbRoute(breadcrumbText)

  const handleContinueButton = () => navigate('/admin/informacion-mensaje')

  const onSubmit = async values => {
    try {
      const { citizen_id_code, citizen_id_number } = values

      const verifyUser = await request({
        url: 'admin/messages/verify-user.json',
        data: { citizen_id_code, citizen_id_number },
        method: 'POST'
      })

      if (verifyUser.response?.data.error) return { [FORM_ERROR]: `Ciudadano no está registrado en ${appName}` }

      const addresseData = {
        citizen_id_code,
        citizen_id_number,
        last_names: verifyUser.last_names,
        first_names: verifyUser.first_names
      }

      setInitialData(addresseData)
      setCitizenExits(true)
      setItemToLocalStorage('addresseData', JSON.stringify(addresseData))
    } catch (error) {
      return { [FORM_ERROR]: `Ocurrió un error: ${error}` }
    }
  }

  const handleReturn = () => {
    if (citizenExits) {
      setCitizenExits(false)
    } else {
      removeItemFromLocalStorage(['addresseData', 'messageInfoData', 'messageType'])
      navigate('/admin/notificaciones')
    }
  }

  return (
    <>
      <Breadcrumb routesKey={key} data={breadcrumbData} />
      <Container className="px-5 pt-8 pb-14 md:px-20 md:pt-14 md:pb-18">
        <FormsHeader title={title} steps={MessageCreateSteps} activeStep={MessageCreateSteps.SET_USER_INFO} />
        <div className="p-6 md:p-9 border border-blue-200 shadow-auth-register m-auto max-w-lg">
          <h2 className="md:text-center pb-6 md:pb-7 font-bold text-xl md:text-2xl">
            {MessageCreateSteps.SET_USER_INFO.label}
          </h2>
          <Form
            onSubmit={onSubmit}
            initialValues={initialData}
            subscription={{ submitting: true, submitError: true }}
            render={({ handleSubmit, submitError, submitting }) => (
              <form onSubmit={handleSubmit}>
                <SelectField
                  size="full"
                  name="citizen_id_code"
                  label="Tipo de documento"
                  options={DOCUMENT_TYPES}
                  validate={validations.required()}
                  disabled={citizenExits ? true : false}
                />
                <TextField
                  size="full"
                  name="citizen_id_number"
                  label="Número de documento"
                  validate={validations.mix(validations.required(), validations.isNumber())}
                  disabled={citizenExits ? true : false}
                />
                {submitError && <div className="text-red-500 font-bold text-center pb-6">{submitError}</div>}
                {citizenExits ? (
                  <>
                    <TextField
                      size="full"
                      name="last_names"
                      label="Apellidos"
                      disabled
                      validate={validations.required()}
                    />
                    <TextField
                      size="full"
                      name="first_names"
                      label="Nombres"
                      disabled
                      validate={validations.required()}
                    />
                    <Button size="full" className="!mb-4" onClick={handleContinueButton}>
                      Continuar
                    </Button>
                  </>
                ) : (
                  <SaveButton label="Validar" size="full" className="!mb-4" disabled={submitting} />
                )}
                <Button size="full" variant="text" onClick={handleReturn}>
                  Regresar
                </Button>
              </form>
            )}
          />
        </div>
      </Container>
    </>
  )
}
