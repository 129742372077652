import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ResourcesTab } from '../components/ui/ResourcesTab'
import { DocumentDetailPage } from '../pages/documents/DocumentDetailPage'
import { DocumentsSectionPage } from '../pages/documents/DocumentsSectionPage'
import { MessageDetailPage } from '../pages/notifications/MessageDetailPage'
import { ProfileEditPage } from '../pages/users/ProfileEditPage'
import { ProfilePage } from '../pages/users/ProfilePage'

const CitizenFolderRoutes = () => (
  <Routes>
    <Route path="/inicio" element={<ResourcesTab />} />
    <Route path="/notificaciones" element={<ResourcesTab />} />
    <Route path="/notificaciones/:noti_id" element={<MessageDetailPage />} />
    <Route path="/documentos" element={<ResourcesTab />} />
    <Route path="/documentos/:documentId" element={<DocumentDetailPage />} />
    <Route path="/documentos/instituciones/:institutionId" element={<DocumentsSectionPage />} />
    <Route path="/documentos/categorias/:categoryId" element={<DocumentsSectionPage />} />
    <Route path="/perfil" element={<ProfilePage />} />
    <Route path="/perfil/:userId/editar/:type" element={<ProfileEditPage />} />
    <Route path="*" element={<Navigate to="/inicio" replace />} />
  </Routes>
)

export default CitizenFolderRoutes
