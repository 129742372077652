import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { MAX_LENGTH_DOCUMENT_DESCRIPTION } from '../../constants/globals'
import { truncateText } from '../../helpers/helpers'
import Tag from '../ui/Tag'

const DocumentCard = ({ documentData, handleSelectedCard, parentClassName }) => (
  <div
    className={clsx(
      parentClassName,
      'p-6 border-b-0.75 border-gray-850 shadow-card-login cursor-pointer flex flex-col justify-between'
    )}
    onClick={handleSelectedCard}
  >
    <div>
      <div className="md:flex justify-between">
        <p className="font-bold text-xl leading-6 pb-3 md:pb-0 md:w-2/3 underline text-blue-700">{documentData.name}</p>
        <div className="flex md:items-start md:justify-end pt-0 md:pt-1 md:w-6.5">
          <p className="text-gray-500 text-sm pr-2.5">Emitido por</p>
          <Tag
            type="nobility"
            description={documentData.institution_abbreviation}
            className="!px-2.5 !text-xs !font-semibold !rounded"
          />
        </div>
      </div>
      <div className={clsx('pt-4 md:pt-3.5 line-clamp-3 md:line-clamp-2')}>
        <p>{truncateText(documentData.description, MAX_LENGTH_DOCUMENT_DESCRIPTION)}</p>
      </div>
    </div>
  </div>
)

DocumentCard.propTypes = {
  documentData: PropTypes.object,
  handleSelectedCard: PropTypes.func,
  parentClassName: PropTypes.string
}

export default DocumentCard
