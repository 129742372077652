import React from 'react'
import { Form } from 'react-final-form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../api/apiCore'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import { Button } from '../../components/fields/Button'
import PasswordField from '../../components/fields/PasswordField'
import TextField from '../../components/fields/TextField'
import * as validations from '../../components/fields/Validations'
import HeadingTag from '../../components/ui/HeadingTag'
import { toCapitalizeCase } from '../../helpers/helpers'

export const LoginPage = () => {
  const navigate = useNavigate()
  const { adminDispatch } = useAuth()
  const recaptchaAction = 'login_admin'
  const { executeRecaptcha } = useGoogleReCaptcha()

  if (!executeRecaptcha) console.log('Execute recaptcha not yet available')

  const onSubmit = async values => {
    try {
      const recaptcha = await executeRecaptcha(recaptchaAction)

      if (recaptcha) {
        const data = { ...values, recaptcha, recaptchaAction }
        const adminSession = await request({ url: '/admin/sessions.json', data, method: 'POST' })

        if (adminSession.response?.data.error) return { [FORM_ERROR]: adminSession.response?.data.error }

        adminDispatch({ type: toCapitalizeCase(recaptchaAction.split('_')[0]), payload: { ...adminSession } })

        navigate('/admin/inicio')
      }
    } catch (error) {
      return { [FORM_ERROR]: `Ocurrió un error: ${error}` }
    }
  }

  return (
    <Container className="px-5 md:px-20">
      <div className="pt-8 md:pt-14 pb-14 md:pb-18">
        <div className="md:text-center pb-7.5 md:pb-10">
          <HeadingTag title="Gestión de la Carpeta ciudadana" className="font-bold text-3xl md:text-4xl" />
          <p className="pt-4 md:pt-5">Plataforma para entidades para la gestión de la Carpeta ciudadana</p>
        </div>
        <div className="m-auto max-w-xl">
          <div className="p-6 md:p-9 border border-blue-200 shadow-auth-register">
            <h2 className="md:text-center pb-6 md:pb-7.5 font-bold text-xl md:text-2xl">
              Iniciar sesión con tu cuenta de Gob.pe
            </h2>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, submitError, submitting, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    name="email"
                    label="Correo electrónico"
                    size="full"
                    labelClassName="mb-2"
                    validate={validations.required()}
                  />
                  <PasswordField
                    name="password"
                    label="Ingresa tu contraseña"
                    size="full"
                    labelClassName="mb-2"
                    checkboxLabel="Mostrar contraseña"
                    checkboxName="show_password"
                    checkboxClassName="pb-7"
                    validate={validations.required()}
                  />
                  {submitError && (
                    <div className="text-red-750 font-normal leading-5 text-base text-center pb-4 -mt-1">
                      {submitError}
                    </div>
                  )}
                  <div className="flex justify-center mt-2">
                    <Button disabled={pristine || submitting} type="submit" className="m-auto" size="full">
                      Ingresar
                    </Button>
                  </div>
                  <hr className="mb-5" />
                  <div className="text-center mb-4">
                    <div className="border-b border-blue-200"></div>
                    <p className="text-base mt-6">¿Olvidaste tu contraseña?</p>
                  </div>
                  <div className="text-center">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-bold text-base text-blue-700"
                      href="https://www.gob.pe/admin/password/new.user"
                    >
                      <u>Recupérala con Gob.pe</u>
                    </a>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
    </Container>
  )
}
