import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import { Fade, Popper } from '@material-ui/core'
import ToggleIcon from 'material-ui-toggle-icon'
import PropTypes from 'prop-types'

import ArrowBackIcon from '../../assets/images/arrow_back_icon.svg'
import BookmarkBorderIcon from '../../assets/images/bookmark_border_icon.svg'
import BookmarkIcon from '../../assets/images/bookmark_icon.svg'
import StarBorderIcon from '../../assets/images/star_border_icon.svg'
import StarIcon from '../../assets/images/star_icon.svg'
import { usePatchMessage } from '../../hooks/useMessages'
import useToggle from '../../hooks/useToogle'
import { LabelAs } from './LabelAs'
import { MessageActionButton } from './MessageActionButton'

export const MessageDetailActions = ({ messageId, highlight, labelId }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openPopper, setOpenPopper] = useToggle(false)
  const { mutate: patchMessage } = usePatchMessage()

  const handleHighlight = () => {
    patchMessage({ id: messageId, highlight: !highlight })
  }

  const handleLabel = event => {
    if (labelId) {
      patchMessage({ id: messageId, label_id: null })
    } else {
      setAnchorEl(event.currentTarget)
      setOpenPopper(!openPopper)
    }
  }

  return (
    <>
      <Link to="/notificaciones">
        <img src={ArrowBackIcon} />
      </Link>
      <div className="flex ml-auto md:ml-0">
        <section className="md:pl-14">
          <MessageActionButton
            buttonClassName="md:py-2 md:px-2.5 rounded-sm hover:bg-gray-40 focus:ring-2 focus:ring-yellow-500 focus:rounded-0.75"
            textClassName="hidden md:block font-bold pl-2"
            onButtonClick={handleHighlight}
            text="Destacar"
          >
            <ToggleIcon
              on={highlight}
              onIcon={<ReactSVG src={StarIcon} />}
              offIcon={<ReactSVG src={StarBorderIcon} />}
            />
          </MessageActionButton>
        </section>
        <section className="pl-9 md:pl-7">
          <MessageActionButton
            buttonClassName="md:py-2 md:px-2.5 rounded-sm hover:bg-gray-40 focus:ring-2 focus:ring-yellow-500 focus:rounded-0.75"
            textClassName="hidden md:block font-bold pl-2"
            onButtonClick={handleLabel}
            text="Etiquetar"
          >
            <ToggleIcon
              on={labelId !== null}
              onIcon={<ReactSVG src={BookmarkIcon} />}
              offIcon={<ReactSVG src={BookmarkBorderIcon} />}
            />
          </MessageActionButton>
        </section>
        <Popper open={openPopper} anchorEl={anchorEl} transition>
          {() => (
            <Fade timeout={500}>
              <LabelAs messageId={messageId} setOpenPopper={setOpenPopper} />
            </Fade>
          )}
        </Popper>
      </div>
    </>
  )
}

MessageDetailActions.propTypes = {
  messageId: PropTypes.string,
  highlight: PropTypes.bool,
  labelId: PropTypes.number
}
