import React from 'react'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'

import checkIcon from '../../assets/images/check_icon.svg'
import CheckboxField from './CheckboxField'

const Checkboxes = ({ name, label, options, parentClassName, baseIcon = <ReactSVG src={checkIcon} /> }) => (
  <fieldset className={parentClassName}>
    {label && <legend className="text-base font-bold text-gray-800 mb-4">{label}</legend>}
    {options.map((option, index) => (
      <CheckboxField
        key={index}
        name={name}
        label={option.name}
        value={option.code}
        margin="dense"
        formControlClassName="!flex !items-center !gap-5"
        icon={baseIcon}
      />
    ))}
  </fieldset>
)

Checkboxes.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  parentClassName: PropTypes.string,
  baseIcon: PropTypes.element
}

export default Checkboxes
