import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useLocation } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'
import PropTypes from 'prop-types'

import { request } from '../../api/apiCore'
import ChevronDown from '../../assets/images/chevron_down_icon.svg'
import ChevronUp from '../../assets/images/chevron_up_icon.svg'
import { useAuth } from '../../auth/Auth'
import { appName } from '../../helpers/helpers'
import { Button } from '../fields/Button'
import CheckboxField from '../fields/CheckboxField'
import TextField from '../fields/TextField'
import * as validations from '../fields/Validations'
import { senderTypes } from './configurations'

const FeedbackForm = ({ onFeedbackSent, senderType }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { pathname } = useLocation()
  const { admin } = useAuth()

  const createFeedback = async values => {
    const url = admin.logged && pathname.includes('admin') ? '/admin/feedbacks' : '/feedbacks-user'
    try {
      const data = await request({
        url,
        data: { ...values, route: pathname },
        method: 'POST'
      })
      onFeedbackSent({ show: true, message: data })
    } catch (error) {
      return { [FORM_ERROR]: 'Ocurrio un error al registrar la feedback.' }
    }
  }

  return (
    <>
      <a
        className="bg-gray-40 flex justify-center gap-4 p-6 md:p-8 font-bold text-blue-700 rounded"
        role="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="pr-2 md:pr-0">{senderTypes[senderType].formTitle}</span>
        {isOpen ? <img src={ChevronUp} alt="Up" /> : <img src={ChevronDown} alt="Down" />}
      </a>
      <Form onSubmit={createFeedback} subscription={{ submitting: true }}>
        {({ handleSubmit, submitting }) => (
          <form
            onSubmit={handleSubmit}
            className="bg-gray-40 p-6 md:p-8 border-t border-white rounded"
            hidden={!isOpen}
          >
            <TextField
              name="description"
              label="Describe tu sugerencia y/o problema"
              hint="No respondemos, pero sí leemos todos los mensajes."
              element="textarea"
              size="full"
              rows={5}
              margin="dense"
              className="!h-full"
              validate={validations.mix(validations.required(), validations.minLength(3), validations.maxLength(256))}
            />
            <CheckboxField
              name="contact_the_sender"
              margin="none"
              label={`Quiero que me contacten para ayudar a mejorar la ${appName}`}
              className="!mb-6 md:!mb-7.5"
              formControlClassName="!flex !items-start !gap-5 md:!items-center"
            />
            <div className="text-center">
              <Button type="submit" disabled={submitting} className="!max-w-full md:!max-w-56">
                Enviar
              </Button>
            </div>
          </form>
        )}
      </Form>
    </>
  )
}

export default FeedbackForm

FeedbackForm.propTypes = {
  onFeedbackSent: PropTypes.func,
  senderType: PropTypes.string
}
