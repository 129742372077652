import React from 'react'

import PropTypes from 'prop-types'

import { LINK_INFORMATION_CUE } from '../../constants/globals'
import { appName } from '../../helpers/helpers'
import KnowMore from './KnowMore'
import LoginCard from './LoginCard'
import LoginHeader from './LoginHeader'

const MailBoxLogin = ({ handleLogin }) => (
  <section className="flex flex-col items-center pt-8 md:pt-14 pb-14 md:pb-18">
    <LoginHeader
      title={appName}
      subtitle="Revisa las notificaciones y comunicaciones enviadas por las entidades del Estado."
      className="pb-7.5 md:pb-9 max-w-md md:text-center"
    />
    <LoginCard className="max-w-131.75 mb-6 w-full" handleLoginButton={handleLogin} showInfo={false} />
    <KnowMore linkPath={LINK_INFORMATION_CUE} text="Conoce más de la Casilla Única Electrónica" />
  </section>
)

MailBoxLogin.propTypes = {
  handleLogin: PropTypes.func
}

export default MailBoxLogin
