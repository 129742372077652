import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ClickAwayListener, MenuList, Paper, Popper } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import PropTypes from 'prop-types'
import clsx from 'clsx'

export const ChildrenTabs = ({
  anchorEl,
  handleClose,
  container,
  showSubMenuMobile,
  selectedSubTabs,
  setShowMenuMobile
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const open = Boolean(anchorEl)
  let computedStyleAnchorEl = null
  if (anchorEl) {
    computedStyleAnchorEl = getComputedStyle(anchorEl)
  }

  const useStyles = makeStyles({
    paper: {
      top: '134px !important',
      'box-shadow': '2px 3px 23px #D3E0EC',
      border: '0px solid #DEE3EA',
      width: `calc(${computedStyleAnchorEl?.width})`,
      backgroundColor: '#F6F9FC',
      marginLeft: '2px'
    },
    list: {
      'padding-top': 0,
      'padding-bottom': 0
    },
    popper: {
      position: 'relative !important',
      transform: 'initial !important'
    },
    menuItem: {
      padding: 12,
      display: 'flex',
      height: '62px',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: '#E6F2F8'
      },
      '&:active': {
        fontWeight: '500'
      }
    }
  })

  const classes = useStyles()

  return (
    <Popper
      id="basic-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      container={container}
      className={clsx(showSubMenuMobile && classes.popper)}
    >
      {() => (
        <Paper classes={{ root: classes.paper }}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList aria-labelledby="basic-button" classes={{ root: classes.list }}>
              <button
                onClick={() => {
                  handleClose()
                  setShowMenuMobile(true)
                }}
                className="font-bold py-2 px-2 block md:hidden"
              >
                <ChevronLeft className="text-red-800" /> Volver
              </button>
              {selectedSubTabs.map((tab, index) => (
                <MenuItem
                  disableRipple
                  classes={{
                    root: clsx(classes.menuItem, {
                      '!bg-blue-60 !font-medium': location.pathname === tab.path
                    })
                  }}
                  onClick={() => {
                    navigate(tab.path)
                    handleClose()
                  }}
                  data-testid={tab.name}
                  key={`sub-${index}`}
                >
                  {tab.name}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      )}
    </Popper>
  )
}

ChildrenTabs.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  container: PropTypes.bool,
  showSubMenuMobile: PropTypes.bool,
  selectedSubTabs: PropTypes.array,
  setShowMenuMobile: PropTypes.func
}
