import React from 'react'
import { useFieldArray } from 'react-final-form-arrays'

import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'

import { Button } from '../fields/Button'
import DocumentSendItem from './DocumentSendItem'

const DocumentsSendSection = ({
  type,
  attrName,
  label,
  hasInitialComponent,
  btnAddText = 'Añadir adjunto',
  initialValue
}) => {
  const { fields } = useFieldArray(attrName, hasInitialComponent && { initialValue })

  return (
    <div id="adjuntos">
      {label && <p className="font-bold mb-2.5">{label}</p>}
      <section className="bg-gray-30 rounded p-6">
        {fields.map((name, index) => (
          <DocumentSendItem
            key={index}
            name={name}
            item={index}
            type={type}
            handleDeleteItem={() => fields.remove(index)}
          />
        ))}
        <Button
          className="!bg-white !max-w-72"
          startIcon={<AddIcon />}
          variant="secondary"
          onClick={() => fields.push()}
        >
          {btnAddText}
        </Button>
      </section>
    </div>
  )
}

DocumentsSendSection.propTypes = {
  type: PropTypes.string,
  attrName: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  hasInitialComponent: PropTypes.bool,
  btnAddText: PropTypes.string,
  initialValue: PropTypes.array
}

export default DocumentsSendSection
