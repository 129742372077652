import React from 'react'
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Pagination } from '../../ui/Pagination'

const showActions = (document, onDestroy) => (
  <>
    <IconButton color="primary" component={Link} to={`/admin/documentos/edit/${document.id}`}>
      <EditIcon />
    </IconButton>
    <IconButton color="primary" type="button" onClick={() => onDestroy(document)}>
      <DeleteIcon />
    </IconButton>
  </>
)

const DocumentTable = ({ data = [], onDestroy, totalPages = 0 }) => (
  <>
    <table className="table table-fixed w-full">
      <thead>
        <tr>
          <th className="w-1/5 text-left">Nombre</th>
          <th className="w-2/5 text-left">Entidad</th>
          <th className="w-1/5 text-left">Categorías</th>
          <th className="w-1/10 text-left">Tipo de documento</th>
          <th className="w-1/10 text-right">
            <span className="pr-7 md:pr-5">Acciones</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map(document => (
          <tr key={document.id}>
            <td className="w-1/5 text-left text-lg">{document.name}</td>
            <td className="w-2/5 text-left text-lg">{document.institution}</td>
            <td className="w-1/5 text-left text-lg">{document.categories}</td>
            <td className="w-1/10 text-left text-lg">{document.generate_by}</td>
            <td className="w-1/10 text-right text-lg">{showActions(document, onDestroy)}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <Pagination numPages={totalPages} />
  </>
)

export default DocumentTable

DocumentTable.propTypes = {
  data: PropTypes.array,
  onDestroy: PropTypes.func,
  totalPages: PropTypes.number
}
