import React from 'react'

import PropTypes from 'prop-types'

import { tagConfig } from '../../configs/messages'
import HeadingTag from '../ui/HeadingTag'
import Tag from '../ui/Tag'

export const MessageContentTitle = ({ title, type }) => (
  <section className="block md:flex md:justify-between md:items-start mb-6">
    <HeadingTag
      title={title}
      className="font-bold text-2xl md:text-3xl leading-7 md:leading-9 mb-5 md:mb-0 md:mr-7 break-all"
    />
    <Tag {...tagConfig[type]} />
  </section>
)

MessageContentTitle.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string
}
