import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

const alertStylesByType = {
  info: {
    iconBgColor: 'bg-yellow-100',
    borderColor: 'border-yellow-700',
    textTitleColor: 'text-yellow-700'
  },
  infoDocument: {
    iconBgColor: 'bg-blue-100',
    borderColor: 'border-blue-700',
    textTitleColor: 'text-blue-700'
  },
  alert: {
    iconBgColor: 'bg-red-200',
    borderColor: 'border-red-700',
    textTitleColor: 'text-red-700'
  }
}

const AlertCardTitle = ({ title, className }) => <span className={clsx(className, 'font-bold')}>{title} </span>

const AlertCard = ({ icon, title, content, type, className, titleHidden = true }) => {
  const { iconBgColor, borderColor, textTitleColor } = alertStylesByType[type]

  return (
    <section className={clsx(borderColor, 'border-2 block md:flex', className)}>
      <div className="flex">
        <div
          className={clsx(
            borderColor,
            iconBgColor,
            'flex items-center justify-center px-3.25 md:px-6 md:py-0 border-r-2'
          )}
        >
          {icon}
        </div>
        <div className="py-3.25 md:py-4 px-4 block md:hidden">
          <AlertCardTitle title={title} className={textTitleColor} />
        </div>
      </div>
      <div className={clsx(borderColor, 'px-6 py-6 md:border-none border-t-2')}>
        <AlertCardTitle title={title} className={clsx(textTitleColor, { 'hidden': titleHidden })} />
        {content}
      </div>
    </section>
  )
}

AlertCard.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  content: PropTypes.node,
  type: PropTypes.string,
  className: PropTypes.string,
  titleHidden: PropTypes.bool
}

AlertCardTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
}

export default AlertCard
