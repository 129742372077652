import React from 'react'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'

import EducationCategoryIcon from '../../assets/images/category_education_icon.svg'
import HealthCategoryIcon from '../../assets/images/category_health_icon.svg'
import JobCategoryIcon from '../../assets/images/category_job_icon.svg'
import SituationApplyUniversityIcon from '../../assets/images/situation_apply_university_icon.svg'
import CardInfo from '../ui/CardInfo'

const categoriesIcons = {
  'educacion': <ReactSVG src={EducationCategoryIcon} />,
  'elecciones': <ReactSVG src={SituationApplyUniversityIcon} />,
  'partidas-y-actas': <ReactSVG src={EducationCategoryIcon} />,
  'salud': <ReactSVG src={HealthCategoryIcon} />,
  'trabajo': <ReactSVG src={JobCategoryIcon} />
}

const DocumentsCategories = ({ categories }) => {
  return (
    <div className="md:grid grid-cols-3 gap-y-5 max-w-6xl">
      {categories.map((category, index) => (
        <CardInfo
          key={index}
          cardIcon={categoriesIcons[category.slug]}
          title={category.name}
          infoText={category.description}
          buttonText={`Ver todos mis documentos de ${category.name}`}
          cardUrl={`/documentos/categorias/${category.id}`}
          cardClassName="md:mr-5 mb-5 md:mb-0"
          contentClassName="text-center"
        />
      ))}
    </div>
  )
}

DocumentsCategories.propTypes = {
  categories: PropTypes.array
}

export default DocumentsCategories
