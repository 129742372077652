import React from 'react'

import PropTypes from 'prop-types'

import MessageReviewContent from './MessageReviewContent'

const MessageContentSection = ({ title, sectionData, className }) => {
  return (
    <section className="border-b border-blue-200 pb-6 mb-6 md:pb-7.5 md:mb-7.5">
      <h2 className="text-xl md:text-2xl pb-4 md:pb-5 font-bold">{title}</h2>
      <div className={className ? className : 'grid grid-cols-2 gap-4'}>
        {sectionData.map((data, index) => (
          <MessageReviewContent key={index} data={data} classNameRoot={index > 0 ? 'pt-2.5 md:pt-0' : ''} />
        ))}
      </div>
    </section>
  )
}

MessageContentSection.propTypes = {
  title: PropTypes.string,
  sectionData: PropTypes.array,
  className: PropTypes.string
}

export default MessageContentSection
