import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const Timer = ({ time = 60, timerConfig, setTimerConfig, timerMessage, substract = 1 }) => {
  const [count, setCount] = useState(time)
  const timer = () => setCount(count - substract)
  useEffect(() => {
    if (timerConfig.reset) {
      setTimerConfig({ show: true, reset: false })
      setCount(time)
    }
    if (count <= 0) {
      setTimerConfig({ ...timerConfig, show: false })
      return
    }
    const id = setInterval(timer, 1000)
    return () => clearInterval(id)
  }, [count])

  return (
    <span className="pl-1">
      {count} seg. {timerMessage}
    </span>
  )
}

export default Timer

Timer.propTypes = {
  time: PropTypes.number,
  substract: PropTypes.number,
  timerMessage: PropTypes.string,
  timerConfig: PropTypes.object,
  setTimerConfig: PropTypes.func
}
