import React, { useState } from 'react'

import PropTypes from 'prop-types'
import AlertNew from '~/components/ui/AlertNew'
import ProgressLoading from '~/components/ui/ProgressLoading'
import ScrollToTop from '~/components/ui/ScrollToTop'
import { AlertContext } from '~/utils/context'

const AlertMessage = ({ children }) => {
  const [alertMessage, setAlertMessage] = useState(null)
  const [alertMessageMobile, setAlertMessageMobile] = useState(null)
  return (
    <AlertContext.Provider value={{ setAlertMessage, setAlertMessageMobile }}>
      <>
        {children}
        {alertMessageMobile && (
          <>
            <ScrollToTop />
            <ProgressLoading className="absolute top-17.5 left-0 z-1500" />
            <AlertNew
              type="success"
              autoHideDuration={5000}
              className="fixed top-25 left-1/2 transform -translate-x-1/2 w-11/12 z-1500"
              onClose={() => setAlertMessageMobile(null)}
              hideCloseIcon
              innerHTML={alertMessageMobile}
            />
          </>
        )}

        {alertMessage && (
          <AlertNew
            type="success"
            autoHideDuration={5000}
            className="fixed bottom-6 left-1/2 transform -translate-x-1/2 max-w-xl w-full z-1500"
            onClose={() => setAlertMessage(null)}
            classNameText="max-w-98.5 truncate whitespace-nowrap"
            innerHTML={alertMessage}
          />
        )}
      </>
    </AlertContext.Provider>
  )
}
AlertMessage.propTypes = {
  children: PropTypes.node
}
export default AlertMessage
