import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

export const MessageContentButton = ({
  icon,
  text,
  textClassName,
  buttonClassName,
  handleButton,
  as: Component = 'button',
  ...rest
}) => {
  return (
    <Component
      className={clsx(
        'flex items-center justify-center border-2 border-blue-700 rounded text-blue-700',
        buttonClassName
      )}
      onClick={handleButton}
      {...rest}
    >
      {icon}
      <p className={textClassName}>{text}</p>
    </Component>
  )
}

MessageContentButton.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  handleButton: PropTypes.func,
  as: PropTypes.string
}
