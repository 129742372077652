import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import arrayMutators from 'final-form-arrays'
import pDebounce from 'p-debounce'
import PropTypes from 'prop-types'

import { request } from '../../../../api/apiCore'
import { dataToOptions, documentSendSectionProps, resultsToOptions } from '../../../../helpers/helpers'
import DocumentsSendSection from '../../../admin/DocumentsSendSection'
import { Button } from '../../../fields/Button'
import MultipleSelectField from '../../../fields/MultipleSelectField'
import RadioField from '../../../fields/RadioField'
import SaveButton from '../../../fields/SaveButton'
import SearchSelectField from '../../../fields/SearchSelectField'
import SelectField from '../../../fields/SelectField'
import TextField from '../../../fields/TextField'
import * as validations from '../../../fields/Validations'

const levelOptions = [
  { label: '1 PIDE', value: 1 },
  { label: '2 CLAVE SOL', value: 2 }
]

const generateByOptions = [
  { label: 'Entidad', value: 'institution' },
  { label: 'PIDE', value: 'pide' }
]

const needRucOptions = [
  { label: 'Sí', value: 'SI' },
  { label: 'No', value: 'NO' }
]

const hasExpirationDateOptions = [
  { label: 'Sin vencimiento', value: false },
  { label: 'Con vencimiento', value: true }
]

const requestTypeOptions = [
  { label: 'GET', value: 'GET' },
  { label: 'POST', value: 'POST' }
]

const loadOptions = pDebounce(async (search, loadedOptions, { page }) => {
  if (search.length >= 3) {
    const { data: institutions } = await axios.get(`/api/v1/admin/institutions.json?search=${search}&page=${page}`)
    const options = dataToOptions(institutions || [], ['id', 'name'])

    return {
      options: options,
      hasMore: institutions.data.length >= 1,
      additional: {
        page: page <= institutions.total_pages ? page + 1 : 2
      }
    }
  } else {
    return { options: [], hasMore: false }
  }
}, 500)

const loadOptionsMultitple = async search => {
  const { data: categories } = await request({ url: `/admin/categories.json?search=${search}` })
  return resultsToOptions(categories || [], ['id', 'name'])
}

export const DocumentForm = ({ initialValues = {}, onSubmit, create }) => {
  const [levelValue, setLevelValue] = useState(initialValues.level || '')
  const [showContent, setShowContent] = useState({
    validGenerateByValue: false,
    validExpirationDateValue: false
  })

  useEffect(() => {
    if (Object.keys(initialValues).length === 0) return
    const generateByInstitution = initialValues.generate_by === 'institution'
    setShowContent({
      ...showContent,
      validGenerateByValue: generateByInstitution,
      validExpirationDateValue: generateByInstitution && initialValues.has_expiration_date
    })
  }, [initialValues])

  const navigate = useNavigate()

  const onChangeLevelSelect = value => setLevelValue(value)

  const onChangeGenerateBySelect = value => {
    value === 'pide' ? setLevelValue(1) : setLevelValue('')
    setShowContent({ ...showContent, validGenerateByValue: value === 'institution' })
  }

  const onChangeHasExpirationDateSelect = value => {
    setShowContent({
      ...showContent,
      validExpirationDateValue: showContent.validGenerateByValue && value
    })
  }

  const additionalLinks = initialValues.additional_links?.length ? initialValues.additional_links : []
  const documentSectionProps = { ...documentSendSectionProps['document'], type: 'document' }

  return (
    <div className="p-6 md:p-9 border border-blue-200 shadow-auth-register m-auto max-w-2xl">
      <Form onSubmit={onSubmit} initialValues={initialValues} mutators={{ ...arrayMutators }} autoComplete="off">
        {({ handleSubmit, submitError, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="py-4 md:py-8">
              <TextField
                label="Nombre del documento"
                name="name"
                validate={validations.mix(validations.required(), validations.isLetter())}
                labelClassName="mb-4 font-bold leading-none"
                margin="mb-0"
                size="full"
              />
              <TextField
                label="Descripción del documento"
                name="description"
                className="h-32"
                validate={validations.required()}
                labelClassName="mt-4 mb-4 font-bold leading-none"
                margin="mb-0"
                size="full"
                element="textarea"
              />
              <SearchSelectField
                label="Entidad"
                name="institution_id"
                className="mt-4 mb-4"
                loadOptions={loadOptions}
                validate={validations.required()}
              />
              <MultipleSelectField
                name="category_ids"
                label="Categoría"
                className="mt-4 mb-4"
                loadOptions={loadOptionsMultitple}
                validate={validations.required()}
              />
              <RadioField
                label="Necesita RUC"
                name="need_ruc"
                options={needRucOptions}
                validate={validations.required()}
                containerClassName="mt-4"
              />
              <RadioField
                label="Tipo de documento"
                name="generate_by"
                options={generateByOptions}
                validate={validations.required()}
                onChange={onChangeGenerateBySelect}
                containerClassName="mt-4"
              />
              {showContent.validGenerateByValue && (
                <SelectField
                  containerClassName="mt-4"
                  name="has_expiration_date"
                  label="Vigencia del documento"
                  defaultOption="Selecciona una opción"
                  onChange={onChangeHasExpirationDateSelect}
                  options={hasExpirationDateOptions}
                  size="full"
                />
              )}
              {showContent.validExpirationDateValue && (
                <TextField
                  label="Período de vigencia (en días)"
                  name="expiration_date"
                  validate={validations.mix(validations.required(), validations.isNumber())}
                  labelClassName="mb-4 mt-2 font-bold leading-none"
                  margin="dense"
                  rootClassName="mt-2 p-5 md:p-7.5 bg-gray-80"
                />
              )}
              <SelectField
                containerClassName="mt-4 mb-8"
                name="level"
                hint="Selecciona el nivel que debe tener la cuenta para poder ver y descargar el documento"
                label="Nivel necesario para ver el documento"
                defaultOption="Selecciona una opción"
                options={levelOptions}
                initialValue={levelValue}
                onChange={onChangeLevelSelect}
                validate={validations.required()}
                size="full"
              />
              {showContent.validGenerateByValue && (
                <TextField
                  label="Enlace al servicio"
                  hint="Coloca el enlace a la página informativa del servicio o el enlace al servicio"
                  name="service_link"
                  validate={validations.mix(validations.required(), validations.isURL())}
                  labelClassName="mb-4 font-bold leading-none"
                  rootClassName="mb-8"
                  margin="mb-0"
                  size="full"
                />
              )}
              <DocumentsSendSection
                {...documentSectionProps}
                initialValue={additionalLinks}
                hasInitialComponent={create ? false : true}
              />
              {showContent.validGenerateByValue && (
                <>
                  <SelectField
                    containerClassName="mt-8"
                    name="request_type"
                    label="Método de petición"
                    defaultOption="Selecciona una opción"
                    options={requestTypeOptions}
                    validate={validations.required()}
                    size="full"
                  />
                  <TextField
                    rootClassName="mt-4"
                    label="URL base"
                    name="request_url"
                    validate={validations.mix(validations.required(), validations.isURL())}
                    labelClassName="mb-4 font-bold leading-none"
                    margin="mb-0"
                    size="full"
                  />
                  <TextField
                    rootClassName="mt-4"
                    label="Authorization"
                    name="authorization_header"
                    validate={validations.required()}
                    labelClassName="mb-4 font-bold leading-none"
                    margin="mb-0"
                    size="full"
                  />
                </>
              )}
            </div>
            <div className="pb-3.75">
              <SaveButton label={create ? 'Crear Documento' : 'Guardar cambios'} size="full" disabled={submitting} />
            </div>
            <Button size="full" variant="text" onClick={() => navigate('/admin/documentos-y-categorias')}>
              Cancelar
            </Button>
            {submitError && <div className="text-red-700 font-bold text-center">{submitError}</div>}
          </form>
        )}
      </Form>
    </div>
  )
}
DocumentForm.propTypes = {
  initialValues: PropTypes.object,
  create: PropTypes.bool,
  onSubmit: PropTypes.func
}
