import React from 'react'
import { useField } from 'react-final-form'

import PropTypes from 'prop-types'
import LabelList from '~/components/notifications/LabelList'

export const LabelFilterListContent = ({ name, onEdit, onDelete }) => {
  const { input } = useField(name)

  const handleSelect = label => {
    if (label.id.toString() === input.value.toString()) {
      input.onChange(null)
    } else {
      input.onChange(label.id)
    }
  }

  return (
    <LabelList
      labelId={input.value}
      onSelect={handleSelect}
      className="md:max-h-98 md:overflow-y-auto custom-scrollbar border-y border-blue-200 font-bold"
      onEdit={onEdit}
      onDelete={onDelete}
    />
  )
}

LabelFilterListContent.propTypes = {
  name: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
}
