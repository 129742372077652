import React from 'react'
import { ReactSVG } from 'react-svg'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Pagination } from '../ui/Pagination'
import SearchField from '../fields/SearchField'
import NextIcon from '../../assets/images/next_icon.svg'

const DocumentsEntities = ({ entities }) => (
  <>
    <h3 className="font-bold pb-2.5">Filtrar por nombre o siglas de la entidad</h3>
    <SearchField name="search" placeholder="Buscar" containerClassName="md:w-80" />
    {entities.total_count === 0 ? (
      <div className="font-bold flex justify-center">No se encontraron resultados</div>
    ) : (
      <>
        {entities.data.map((entity, index) => (
          <div key={index} className={clsx('pb-4 pt-4', index > 0 ? 'border-t md:pt-2.5' : 'md:pt-5')}>
            <div className="font-medium text-gray-850 pb-2.5">
              {entity.name} ({entity.abbreviation})
            </div>
            <div className="md:flex justify-between">
              <div className="pb-4 md:pb-0">
                {`Cuenta con ${entity.documents_count} ${
                  entity.documents_count > 1 ? 'documentos' : 'documento'
                } en Carpeta Ciudadana`}
              </div>
              <Link
                to={`/documentos/instituciones/${entity.id}`}
                className="text-blue-700 font-bold leading-6 flex items-center"
              >
                Ver todos <ReactSVG src={NextIcon} className="pl-3" />
              </Link>
            </div>
          </div>
        ))}
        <Pagination numPages={entities.total_pages} />
      </>
    )}
  </>
)

DocumentsEntities.propTypes = {
  entities: PropTypes.object
}

export default DocumentsEntities
