import React from 'react'

import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { makeStyles, withStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const StepperConnector = withStyles({
  alternativeLabel: {
    top: 28,
    left: 'calc(-50% + 1.75rem)',
    right: 'calc(50% + 1.75rem)'
  },
  active: {
    '& $line': {
      borderColor: '#0056AC'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#0056AC'
    }
  },
  line: {
    borderColor: '#DEE3EA',
    borderTopWidth: 2
  }
})(StepConnector)

const useStyles = makeStyles({
  root: {
    backgroundColor: '#DEE3EA',
    color: '#6F777B',
    borderRadius: '50%',
    padding: 0,
    fontSize: 24,
    width: 56,
    height: 56
  },
  active: {
    backgroundColor: '#0056AC',
    color: 'white'
  },
  completed: {
    backgroundColor: '#0056AC',
    color: 'white'
  },
  stepperRoot: {
    padding: 0,
    fontWeight: 700
  }
})

const CustomStepIcon = ({ icon, active, completed }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx('flex justify-center items-center', classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icon}
    </div>
  )
}

export const HorizontalLinearStepper = ({ steps, activeStep }) => {
  const classes = useStyles()

  return (
    <article className="w-full">
      <Stepper
        activeStep={activeStep}
        classes={{ root: classes.stepperRoot }}
        connector={<StepperConnector />}
        alternativeLabel
      >
        {steps.map((_, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={CustomStepIcon} />
          </Step>
        ))}
      </Stepper>
    </article>
  )
}

CustomStepIcon.propTypes = {
  icon: PropTypes.node,
  active: PropTypes.bool,
  completed: PropTypes.bool
}

HorizontalLinearStepper.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number
}
