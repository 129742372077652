import React from 'react'
import { Form } from 'react-final-form'

import PropTypes from 'prop-types'
import TextField from '~/components/fields/TextField'
import * as validations from '~/components/fields/Validations'
import Dialog from '~/components/ui/Dialog'
import DialogButtons from '~/components/ui/DialogButtons'
import DialogTitle from '~/components/ui/DialogTitle'

const LabelFormDialog = ({ title, open, onClose, onSubmit, labelButtonSave, initialValues = {} }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <>
        <DialogTitle title={title} />
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="pt-6">
              <TextField
                name="name"
                label="Nombre de la etiqueta"
                size="full"
                background="bg-blue-300"
                margin="lowdense"
                labelClassName="mb-1"
                validate={validations.required()}
              />
              <DialogButtons confirmLabel={labelButtonSave} onCancel={onClose} onConfirm={handleSubmit} />
            </form>
          )}
        />
      </>
    </Dialog>
  )
}
LabelFormDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  labelButtonSave: PropTypes.string,
  initialValues: PropTypes.object
}
export default LabelFormDialog
