import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'

import PropTypes from 'prop-types'

import { addHoursToTime, generateTimeSlots } from '../../../helpers/helpers'
import SelectField from '../../fields/SelectField'
import * as validations from '../../fields/Validations'

const textInit = 'Se calculará automáticamente al seleccionar la hora de inicio'

const LabourHourForm = ({ title, subtitle, handleSubmitForm, initialValues }) => {
  const [hourText, setHourText] = useState('')

  useEffect(() => {
    setHourText(initialValues.labour_start_time ? addHoursToTime(initialValues.labour_start_time, 9) : textInit)
  }, [initialValues])

  const handleHourChange = value => setHourText(value ? addHoursToTime(value, 9) : textInit)

  return (
    <Form
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
      subscription={{ submitError: true }}
      render={({ handleSubmit, submitError }) => (
        <form
          id="labourHourForm"
          onSubmit={handleSubmit}
          className="p-6 md:p-9 border border-blue-200 shadow-auth-register m-auto max-w-xl"
        >
          <h2 className="md:text-center pb-2 md:pb-3 font-bold text-xl md:text-2xl">{title}</h2>
          <p className="mb-6">{subtitle}</p>
          <SelectField
            label="Hora de inicio"
            name="labour_start_time"
            options={generateTimeSlots(7, 9, 30).map(hour => ({ value: hour, label: hour }))}
            onChange={handleHourChange}
            validate={validations.required()}
          />
          <section>
            <p className="font-bold mb-2">Hora de fin</p>
            <span>{hourText}</span>
          </section>
          {submitError && <div className="text-red-500 font-bold text-center pb-6">{submitError}</div>}
        </form>
      )}
    />
  )
}

LabourHourForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleSubmitForm: PropTypes.func,
  initialValues: PropTypes.object
}

export default LabourHourForm
