import React from 'react'
import { Link } from 'react-router-dom'

import { IconButton } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import PropTypes from 'prop-types'

import { Pagination } from '../../ui/Pagination'

const showActions = webService => (
  <IconButton color="primary" component={Link} to={`/admin/web-services/edit/${webService.id}`}>
    <VisibilityIcon />
  </IconButton>
)

const WebServiceTable = ({ data = [], totalPages = 0 }) => (
  <>
    <table className="table table-fixed w-full">
      <thead>
        <tr>
          <th className="w-2/5 text-left">Entidad</th>
          <th className="w-1/10 text-right">
            <span className="pr-7 md:pr-5">Acciones</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map(webService => (
          <tr key={webService.id}>
            <td className="w-1/10 text-left text-lg">{webService.institution}</td>
            <td className="w-1/10 text-right text-lg">{showActions(webService)}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <Pagination numPages={totalPages} />
  </>
)

export default WebServiceTable

WebServiceTable.propTypes = {
  data: PropTypes.array,
  totalPages: PropTypes.number
}
