import { useEffect, useState } from 'react'

const useTimeout = (deps = [], timeout = 5000) => {
  const [inTime, setInTime] = useState(false)
  useEffect(() => {
    setInTime(true)
    const timer = setTimeout(() => setInTime(false), timeout)
    return () => clearTimeout(timer)
  }, deps)
  return { inTime }
}
export default useTimeout
