import React from 'react'

import PropTypes from 'prop-types'

import DownloadIcon from '../../assets/images/download_icon.svg'
import PdfIcon from '../../assets/images/pdf_icon.svg'
import LoadButton from '../ui/LoadButton'

const MessageFile = ({ description, buttonText = 'Descargar', downloadUrl }) => (
  <section className="bg-white mt-5 md:mt-0 shadow-message-detail-card border border-blue-100 rounded">
    <div className="py-5 px-6 block md:flex md:justify-between max-w-full">
      <article className="flex items-start md:items-center pb-4 md:pb-0">
        <img src={PdfIcon} />
        <span className="font-bold ml-4 leading-4">{description}</span>
      </article>
      <LoadButton
        initialIcon={DownloadIcon}
        text={buttonText}
        buttonClassName="hover:bg-gray-80 focus:bg-blue-100 font-bold pl-2 pr-3.5 py-1 w-full md:w-auto"
        url={downloadUrl}
      />
    </div>
  </section>
)

MessageFile.propTypes = {
  description: PropTypes.string,
  buttonText: PropTypes.string,
  downloadUrl: PropTypes.string
}

export default MessageFile
