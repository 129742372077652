import React from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'

const TooltipStyles = makeStyles({
  tooltip: {
    backgroundColor: '#26292E',
    fontSize: '12px',
    padding: '4.5px 9px'
  }
})

export const TooltipActions = ({ text, children, placement }) => {
  const classes = TooltipStyles()

  return (
    <Tooltip classes={classes} title={text} placement={placement}>
      {children}
    </Tooltip>
  )
}

TooltipActions.propTypes = {
  text: PropTypes.string,
  children: PropTypes.object,
  placement: PropTypes.string
}
