import React from 'react'
import { TextField } from '@material-ui/core'

const DateFieldTextField = ({ ...props }) => (
  <div className="flex flex-wrap">
    <TextField {...props} className="relative z-10" />
    <div className="absolute h-full w-11.25 bg-blue-400 right-0" />
  </div>
)

export default DateFieldTextField
