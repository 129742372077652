import React from 'react'

import PropTypes from 'prop-types'

import Tag from '../ui/Tag'

const tagConfig = {
  current: {
    type: 'success',
    description: 'Vigente'
  },
  defeated: {
    type: 'danger',
    description: 'Vencido'
  }
}

const DocumentDetailTable = ({ tableTitle, documentData }) => {
  const documentType = documentData.has_expiration_date ? 'defeated' : 'current'

  return (
    <section className="mt-8 mb-6">
      <h2 className="font-bold text-2xl pb-6 md:pb-5">{tableTitle}</h2>
      <table className="table-auto text-left overflow-x-auto md:overflow-auto">
        <thead className="table-header-group">
          <tr>
            <th className="pl-6">Fecha de emisión</th>
            <th className="pl-6">Fecha de vencimiento</th>
            <th className="pl-6">Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-gray-30 table-row">
            <td className="p-6">{documentData.generation_date}</td>
            <td className="p-6">{documentData.expiration_date || ''}</td>
            <td className="p-6">{<Tag {...tagConfig[documentType]} />}</td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

DocumentDetailTable.propTypes = {
  tableTitle: PropTypes.string,
  documentData: PropTypes.object
}

export default DocumentDetailTable
