import { useMutation, useQuery, useQueryClient } from 'react-query'

import { request } from '../api/apiCore'

const fetchMessageById = (id, root) => request({ url: `${root}/${id}.json` })

export const useMessageData = (id, root) => useQuery(['message', id], () => fetchMessageById(id, root))

const patchMessage = data => request({ url: `/messages-user/${data.id}`, method: 'PATCH', data })

export const usePatchMessage = () => {
  const queryClient = useQueryClient()

  return useMutation(patchMessage, {
    onSuccess: data => {
      const messageId = data.id
      queryClient.invalidateQueries('messages')
      queryClient.invalidateQueries(['message', messageId])
      queryClient.invalidateQueries('messages-user.json')
      queryClient.invalidateQueries('labels')
    }
  })
}
