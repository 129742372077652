import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../../api/apiCore'
import { Container } from '../../Container'
import { CategoryForm } from '../../forms/admin/documents/CategoryForm'
import HeadingTag from '../../ui/HeadingTag'

export const CategoryNew = () => {
  const navigate = useNavigate()
  const onSubmit = async data => {
    try {
      await request({ url: '/admin/categories', data, method: 'POST' })
      navigate('/admin/documentos-y-categorias')
    } catch (error) {
      return { [FORM_ERROR]: 'Ocurrio un error al registrar la categoría.' }
    }
  }

  return (
    <Fragment>
      <Container className="py-16 pl-20 px-6">
        <HeadingTag title="Crear categoría" className="mb-4 font-bold text-3xl" />
        <CategoryForm onSubmit={onSubmit} create />
      </Container>
    </Fragment>
  )
}
