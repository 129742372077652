import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import { addresseMessageReview, routesByType } from '../../configs/messages'
import { formatLabelsObject } from '../../helpers/helpers'
import MessageReviewSection from './MessageReviewSection'

const MultipleReceiptSection = ({ receipts, route }) => (
  <section className="pb-6 mb-6 border-b">
    <article className="flex justify-between items-center pb-4 md:pb-4.5">
      <p className="font-bold text-lg md:text-xl">Datos de los destinatarios</p>
      <Link to={route} className="text-blue-700 font-medium underline">
        Editar
      </Link>
    </article>
    {receipts.map((receipt, index) => (
      <MessageReviewSection
        key={index}
        title={`Destinatario ${index + 1}`}
        sectionData={formatLabelsObject(addresseMessageReview, receipt)}
        parentClassName={index ? 'pt-5' : ''}
        headingClassName="pb-2.5"
        titleClassName="font-bold"
      />
    ))}
  </section>
)

const MessageReceiptsSection = ({ type, data }) => (
  <>
    {type === 'notification' ? (
      <MultipleReceiptSection receipts={data} route={routesByType[type]} />
    ) : (
      <MessageReviewSection
        title="Datos del destinatario"
        linkRoute={routesByType[type]}
        sectionData={formatLabelsObject(addresseMessageReview, data)}
        parentClassName="pb-6 mb-6 border-b"
        headingClassName="pb-4 md:pb-4.5"
        titleClassName="font-bold text-lg md:text-xl"
        showEditButton={Object.values(data).length > 0}
      />
    )}
  </>
)

MessageReceiptsSection.propTypes = {
  type: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

MultipleReceiptSection.propTypes = {
  receipts: PropTypes.array,
  route: PropTypes.string
}

export default MessageReceiptsSection
