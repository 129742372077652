import React from 'react'
import { Form } from 'react-final-form'

import PropTypes from 'prop-types'

import { PASSWORD_RULES } from '../../constants/globals'
import { Button } from '../fields/Button'
import PasswordField from '../fields/PasswordField'
import SaveButton from '../fields/SaveButton'
import * as validations from '../fields/Validations'
import HeadingTag from '../ui/HeadingTag'

const PasswordChangeForm = ({ title, handleSubmit, handleCancel }) => (
  <section className="max-w-3xl">
    <HeadingTag title={title} className="font-bold text-3xl pb-6 md:pb-8" />
    <Form
      onSubmit={handleSubmit}
      subscription={{ pristine: true, submitting: true, submitError: true }}
      render={({ handleSubmit, submitError, submitting, pristine }) => (
        <form onSubmit={handleSubmit} className="max-w-90">
          <PasswordField
            label="Ingresa tu contraseña actual"
            name="current_password"
            size="full"
            labelClassName="mb-2"
            checkboxLabel="Mostrar contraseña"
            checkboxName="show_password"
            checkboxClassName="pb-5 md:pb-5.5"
            validate={validations.required()}
          />
          <PasswordField
            label="Ingresa tu nueva contraseña"
            name="new_password"
            size="full"
            labelClassName="mb-2"
            checkboxLabel="Mostrar contraseña"
            checkboxName="show_password"
            checkboxClassName="pb-7"
            passwordRules={PASSWORD_RULES}
            validate={validations.mix(
              validations.required(),
              validations.minLength(8),
              validations.minOneLetter(),
              validations.minOneNumber()
            )}
          />
          {submitError && <div className="text-red-500 font-bold text-center pb-6">{submitError}</div>}
          <SaveButton label="Guardar cambios" size="full" className="!mb-4" disabled={pristine || submitting} />
          <Button size="full" variant="text" onClick={handleCancel}>
            Regresar
          </Button>
        </form>
      )}
    />
  </section>
)

PasswordChangeForm.propTypes = {
  title: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func
}

export default PasswordChangeForm
