import React from 'react'

import PropTypes from 'prop-types'

const HeadingTag = ({ title, as: Component = 'h1', ...props }) => <Component {...props}>{title}</Component>

HeadingTag.propTypes = {
  title: PropTypes.string,
  as: PropTypes.string
}

export default HeadingTag
