import React, { useState } from 'react'
import { useField } from 'react-final-form'

import PropTypes from 'prop-types'

import PasswordRules from '../ui/PasswordRules'
import CheckboxField from './CheckboxField'
import TextField from './TextField'

const PasswordField = ({
  name,
  label,
  validate,
  passwordRules,
  parentClassName,
  checkboxLabel,
  checkboxName,
  checkboxClassName,
  ...props
}) => {
  const { input } = useField(name, { validate, ...props })
  const [isVisible, setVisibility] = useState(false)

  return (
    <div className={parentClassName}>
      <TextField
        name={name}
        type={isVisible ? 'text' : 'password'}
        label={label}
        margin="dense"
        {...input}
        {...props}
      />
      <CheckboxField
        label={checkboxLabel}
        name={checkboxName}
        className={checkboxClassName}
        formControlClassName="!flex !items-center !gap-5"
        margin="none"
        checked={isVisible}
        onChange={() => setVisibility(!isVisible)}
      />
      {passwordRules && input.value && (
        <PasswordRules title="Tu contraseña debe tener:" value={input.value} rules={passwordRules} />
      )}
    </div>
  )
}

export default PasswordField

PasswordField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  passwordRules: PropTypes.array,
  validate: PropTypes.func,
  parentClassName: PropTypes.string,
  checkboxLabel: PropTypes.string,
  checkboxName: PropTypes.string,
  checkboxClassName: PropTypes.string
}
