import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const MessageReviewContent = ({ data, classNameRoot, send }) => {
  return (
    <div className={send ? '' : classNameRoot}>
      <span className="font-bold">{data.name}</span>
      {React.isValidElement(data.content) ? (
        <div>{data.content}</div>
      ) : (
        <div
          className={clsx('hint-html-injected ', { 'inline-block': !send })}
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      )}
    </div>
  )
}

MessageReviewContent.propTypes = {
  data: PropTypes.object,
  classNameRoot: PropTypes.string,
  send: PropTypes.bool
}

export default MessageReviewContent
