import React from 'react'
import { Link } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'

import { Pagination } from '../../../components/ui/Pagination'

const RolesTable = ({ roles = [], onDestroy, totalPages = 0 }) => {
  const showActions = role => (
    <td className="w-1/2 text-right">
      <IconButton color="primary" component={Link} to={`/admin/roles/edit/${role.id}`}>
        <EditIcon />
      </IconButton>
      <IconButton color="primary" type="button" onClick={() => onDestroy(role)}>
        <DeleteIcon />
      </IconButton>
    </td>
  )

  return (
    <div>
      <table className="table table-fixed w-full">
        <thead>
          <tr>
            <th className="w-3/12 text-left">Nombre</th>
            <th className="w-2/12 text-left">Módulos permitidos</th>
            <th className="w-2/12 text-right">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {roles.map(role => (
            <tr key={role.id}>
              <td className="text-lg">{role.name}</td>
              <td className="text-lg">{role.modules_allowed ? role.modules_allowed : '-'}</td>
              {showActions(role)}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination numPages={Math.ceil(totalPages)} paginationLabel="role_page" />
    </div>
  )
}

export default RolesTable

RolesTable.propTypes = {
  roles: PropTypes.array,
  onDestroy: PropTypes.func,
  totalPages: PropTypes.number
}
