import React, { useState } from 'react'

import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { textSizes } from '../../utils/sizes'

const copyToClipboard = textToCopy => {
  navigator.clipboard.writeText(textToCopy)
}

const classNames = [
  'inline-flex',
  'items-center',
  'justify-center',
  'border-2',
  'border-blue-700',
  'rounded',
  'text-blue-700',
  'bg-gray-40',
  'text-sm',
  'font-bold',
  'px-7',
  'py-2',
  'hover:bg-blue-200',
  'focus:bg-blue-100',
  'w-full',
  'md:w-fit'
]

const CopyText = ({ copy, ariaLabel, size = 'm', className, message }) => {
  const [messageVisibility, setMessageVisibility] = useState(false)
  const copyToText = text => {
    copyToClipboard(text)
    setMessageVisibility(true)
  }

  return (
    <>
      <button
        type="button"
        aria-label={ariaLabel}
        className={clsx(classNames.join(' '), textSizes[size], className)}
        onClick={() => copyToText(copy)}
      >
        <FileCopyIcon classes={{ root: 'mr-1 text-2xl' }} />
        Copiar
      </button>
      {messageVisibility && (
        <p className="inline-flex !font-bold text-slate-400">
          <CheckCircleOutline className="ml-3.5 mr-1 !text-2xl" />
          {message}
        </p>
      )}
    </>
  )
}
export default CopyText

CopyText.propTypes = {
  copy: PropTypes.string,
  ariaLabel: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string
}
