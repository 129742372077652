import React, { Fragment, useMemo, useState } from 'react'

import { FORM_ERROR } from 'final-form'

import { request } from '../../../api/apiCore'
import { Container } from '../../../components/Container'
import { WebServiceForm } from '../../../components/forms/admin/WebServiceForm'
import Breadcrumb from '../../../components/ui/Breadcrumb'
import HeadingTag from '../../../components/ui/HeadingTag'
import { convertDataToBreadcrumbRoute } from '../../../helpers/helpers'

export const WebServiceNew = () => {
  const breadcrumbData = convertDataToBreadcrumbRoute('Generar credenciales')
  const [verifyCredential, setVerifyCredential] = useState({ status: false, data: {} })

  const onSubmit = async ({ institution_gobpe_id, email }) => {
    try {
      const data = { email, institution_id: institution_gobpe_id.value }
      const response = await request({ url: '/admin/entity_users.json', data, method: 'POST' })
      if (!response.client_id) return { [FORM_ERROR]: response.response.data.error }
      setVerifyCredential({ status: true, data: response })
    } catch (error) {
      return { [FORM_ERROR]: error.response.data.error || 'Ocurrio un error al registrar las credenciales.' }
    }
  }
  const initialValues = useMemo(
    () => ({
      institution_gobpe_id: 0,
      email: ''
    }),
    []
  )

  return (
    <Fragment>
      <Breadcrumb routesKey="webService" data={breadcrumbData} />
      <Container className="py-16 pl-20 px-6">
        <HeadingTag title="Generar credenciales" className="mb-4 font-bold text-3xl" />
        <WebServiceForm initialValues={initialValues} onSubmit={onSubmit} verifyCredential={verifyCredential} />
      </Container>
    </Fragment>
  )
}
