import { appName } from '../../helpers/helpers'

const senderTypeCitizen = {
  name: 'Ciudadano',
  colorClasses: 'bg-green-500 text-green-800 border-green-700 border rounded-sm',
  formTitle: `¿Tienes una sugerencia para mejorar la ${appName}?`
}
const senderTypes = {
  citizen: senderTypeCitizen,
  official: { ...senderTypeCitizen, name: 'Funcionario' }
}

const senderTypeOptions = Object.keys(senderTypes).map(code => ({ code, name: senderTypes[code]?.name }))

export { senderTypeOptions, senderTypes }
