import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'

import { FormControlLabel, Radio } from '@material-ui/core'
import PropTypes from 'prop-types'

import CloseIconTooltip from '../../assets/images/close_icon_tooltip.svg'
import informationIcon from '../../assets/images/information_icon.svg'
import informationIconMobile from '../../assets/images/information_mobile_icon.svg'
import { TooltipField } from './TooltipField'

export const RadioOption = ({ option }) => {
  const [showInfoMobile, setShowInfoMobile] = useState(false)

  return (
    <>
      <div className="flex items-center justify-between md:justify-start pt-2.5">
        <FormControlLabel
          value={option.value.toString()}
          control={<Radio classes={{ root: '!py-0 !text-gray-850' }} />}
          classes={{ label: '!font-medium w-32' }}
          label={option.label}
        />
        {option.withIcon && (
          <>
            <div className="hidden md:inline-flex">
              <TooltipField text={option.info}>
                <ReactSVG src={informationIcon} />
              </TooltipField>
            </div>
            <div className="flex md:hidden">
              <button type="button" onClick={() => setShowInfoMobile(!showInfoMobile)}>
                <ReactSVG src={informationIconMobile} />
              </button>
            </div>
          </>
        )}
      </div>
      {showInfoMobile && (
        <div className="bg-neutral-900 text-white pl-4 pr-3 py-4 flex gap-2.75 items-start rounded font-normal mt-3 mb-2.5">
          {option.info}
          <button type="button" onClick={() => setShowInfoMobile(!showInfoMobile)}>
            <ReactSVG src={CloseIconTooltip} />
          </button>
        </div>
      )}
    </>
  )
}

RadioOption.propTypes = {
  option: PropTypes.object
}
