import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'

import { removeItemFromLocalStorage } from '../../helpers/helpers'
import SearchField from '../fields/SearchField'
import { Pagination } from '../ui/Pagination'
import DocumentCard from './DocumentCard'

const Documents = ({ documents }) => {
  const navigate = useNavigate()

  useEffect(() => {
    removeItemFromLocalStorage(['breadcrumbItem'])
  }, [])

  return (
    <>
      <SearchField
        name="search"
        placeholder="Buscar por nombre del documento"
        containerClassName="md:w-80"
        className="pb-10"
      />
      {documents.total_count === 0 ? (
        <div className="font-bold flex justify-center">No se encontraron resultados</div>
      ) : (
        <>
          <div className="md:grid grid-cols-2 gap-5">
            {documents.data?.map((document, index) => (
              <DocumentCard
                key={index}
                documentData={document}
                handleSelectedCard={() => navigate(`/documentos/${document.id}`)}
                parentClassName="mt-5 md:mt-0"
              />
            ))}
          </div>
          <Pagination numPages={documents.total_pages} />
        </>
      )}
    </>
  )
}

Documents.propTypes = {
  documents: PropTypes.object
}

export default Documents
