import React, { useReducer, useEffect } from 'react'
import { useField } from 'react-final-form'
import { MenuItem, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Error from './Error'
import { SelectInput } from './SelectField'
import * as validations from './Validations'
import { request } from '../../api/apiCore'

const reducer = (state, action) => {
  switch (action.type) {
    case 'GOT_REGIONS':
      return {
        ...state,
        regions: action.payload
      }
    case 'GOT_CITIES':
      return {
        ...state,
        cities: action.payload
      }
    case 'GOT_DISTRICTS':
      return {
        ...state,
        districts: action.payload
      }
    case 'CHANGE_REGION':
      return {
        ...state,
        cities: [],
        districts: [],
        selectedCity: '',
        selectedDistrict: '',
        selectedRegion: action.payload
      }
    case 'CHANGE_CITY':
      return {
        ...state,
        districts: [],
        selectedCity: action.payload
      }
    case 'SET_UBIGEO':
      return {
        ...state,
        selectedRegion: action.districtCode.replace(/\d{4}$/, '0000'),
        selectedCity: action.districtCode.replace(/\d{2}$/, '00'),
        selectedDistrict: action.districtCode
      }
    default:
      break
  }
}

const initialState = {
  regions: [],
  cities: [],
  districts: [],
  selectedRegion: '',
  selectedCity: '',
  selectedDistrict: ''
}

const CustomizedTypography = ({ text }) => (
  <Typography className="text-base whitespace-normal leading-none pl-4 pr-6 py-2">{text}</Typography>
)

const UbigeoCitizenField = ({
  index,
  payload,
  name,
  required,
  disabled = false,
  fieldsSize = 'full',
  parentClassName = 'mb-7'
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { input } = useField(name, { validate: required && validations.required() })
  const label = index ? `${index}. ${payload?.label}` : payload?.label

  useEffect(() => {
    if (input.value) dispatch({ type: 'SET_UBIGEO', districtCode: input.value.toString() })

    const getRegions = async () => {
      const res = await request({ url: '/ubigeos.json?country=true' })
      dispatch({ type: 'GOT_REGIONS', payload: res })
    }

    getRegions()
  }, [])

  useEffect(() => {
    let didCancel = false

    const getCities = async () => {
      if (!state.selectedRegion) return
      const res = await request({ url: `/ubigeos.json?region_code=${state.selectedRegion}` })
      if (!didCancel) dispatch({ type: 'GOT_CITIES', payload: res })
    }

    getCities()

    return () => {
      didCancel = true
    }
  }, [state.selectedRegion])

  useEffect(() => {
    let didCancel = false

    const getDistricts = async () => {
      if (!state.selectedCity) return
      const res = await request({ url: `/ubigeos.json?province_code=${state.selectedCity}` })
      if (!didCancel) dispatch({ type: 'GOT_DISTRICTS', payload: res })
    }

    getDistricts()

    return () => {
      didCancel = true
    }
  }, [state.selectedCity])

  const handleRegionChange = evt => {
    input.onChange('')
    dispatch({ type: 'CHANGE_REGION', payload: evt.target.value })
  }

  const handleCityChange = evt => {
    input.onChange('')
    dispatch({ type: 'CHANGE_CITY', payload: evt.target.value })
  }

  return (
    <fieldset className={clsx('relative js-field-container', parentClassName)}>
      <legend className="font-bold text-base mb-4">{label}</legend>
      {payload?.hint && (
        <p id={`${name}-hint`} className="break-words mb-2">
          {payload?.hint}
        </p>
      )}
      <SelectInput
        label="Departamento"
        onChange={handleRegionChange}
        value={state.selectedRegion}
        disabled={disabled}
        size={fieldsSize}
        parentClassName="pb-5"
        displayEmpty
      >
        <MenuItem value="">
          <CustomizedTypography text="Selecciona" />
        </MenuItem>
        {state.regions.map(region => (
          <MenuItem value={region.id} key={region.id}>
            <CustomizedTypography text={region.name} />
          </MenuItem>
        ))}
      </SelectInput>
      <SelectInput
        label="Provincia"
        onChange={handleCityChange}
        value={state.selectedCity}
        disabled={disabled}
        size={fieldsSize}
        parentClassName="pb-5"
        displayEmpty
      >
        <MenuItem value="">
          <CustomizedTypography text="Selecciona" />
        </MenuItem>
        {state.cities.map(city => (
          <MenuItem value={city.id} key={city.id}>
            <CustomizedTypography text={city.name} />
          </MenuItem>
        ))}
      </SelectInput>
      <SelectInput label="Distrito" {...input} disabled={disabled} size={fieldsSize} displayEmpty>
        <MenuItem value="">
          <CustomizedTypography text="Selecciona" />
        </MenuItem>
        {state.districts.map(district => (
          <MenuItem value={district.id} key={district.id}>
            <CustomizedTypography text={district.name} />
          </MenuItem>
        ))}
      </SelectInput>
      <Error name={name} className="absolute -bottom-6" />
    </fieldset>
  )
}

CustomizedTypography.propTypes = {
  text: PropTypes.string
}

UbigeoCitizenField.propTypes = {
  index: PropTypes.number,
  payload: PropTypes.object,
  name: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldsSize: PropTypes.string,
  parentClassName: PropTypes.string
}

export default UbigeoCitizenField
