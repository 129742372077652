import React from 'react'

import PropTypes from 'prop-types'

import InformationMessageIcon from '../../assets/images/information_message_icon.svg'
import { Button } from '../fields/Button'

const LoginCard = ({ className, handleLoginButton, showInfo = true }) => (
  <section className={`p-6 md:p-9 border border-blue-200 rounded shadow-auth-register ${className}`}>
    <h2 className="font-bold text-center text-xl md:text-2xl pb-4 md:pb-6">Crea tu cuenta o inicia sesión</h2>
    {showInfo && (
      <article className="pb-5 md:pb-6 text-center md:text-justify">
        <div className="flex items-start">
          <img src={InformationMessageIcon} alt="info-icon" className="pr-3 hidden md:inline-flex" />
          <p>
            <span className="font-medium">Importante: </span>
            Para ingresar a la Casilla Única Electrónica debes tener Clave Única con nivel intermedio (validar tu
            identidad con Clave SOL).
          </p>
        </div>
      </article>
    )}
    <Button size="full" onClick={handleLoginButton}>
      Continuar con Clave Única
    </Button>
  </section>
)

LoginCard.propTypes = {
  className: PropTypes.string,
  handleLoginButton: PropTypes.func,
  showInfo: PropTypes.bool
}

export default LoginCard
