import React from 'react'

import PropTypes from 'prop-types'
import { Button } from '~/components/fields/Button'

const DialogButtons = ({ onCancel, onConfirm, confirmLabel }) => {
  return (
    <div className="flex items-center justify-end gap-6 mt-7.5">
      <Button onClick={onCancel} color="primary" variant="secondary" size="s">
        Cancelar
      </Button>
      <Button size="s" variant="primary" type="button" className="!font-bold" onClick={onConfirm}>
        {confirmLabel}
      </Button>
    </div>
  )
}
DialogButtons.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmLabel: PropTypes.string.isRequired
}
export default DialogButtons
