export const marginSizes = {
  high: 'mb-8',
  normal: 'mb-6',
  dense: 'mb-4',
  lowdense: 'mb-2',
  minimun: 'mb-1',
  none: 'mb-0'
}

export const fieldWidthSizes = {
  full: 'w-full',
  '5xl': 'sm:max-w-xl',
  '4xl': 'sm:max-w-lg',
  '3xl': 'sm:max-w-md',
  '2xl': 'sm:max-w-sm',
  xl: 'sm:max-w-xs',
  lg: 'sm:max-w-64',
  md: 'sm:max-w-48'
}

export const textSizes = {
  s: 'text-base',
  m: 'md:text-lg'
}

export const tooltipSizes = {
  sm: 'py-1 px-2 text-sm',
  md: 'py-2 px-3 font-bold'
}
