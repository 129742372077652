import { parseHtml2EditorState } from '../../helpers/helpers'

const regexType = {
  'onlyNumber': /[^a-zA-Z]/,
  'onlyNumberLetterSlash': /^([a-zA-Z0-9/-])+$/
}

export const validateOnKeyPress = validateType => e => !regexType[validateType].test(e.key) && e.preventDefault()

export const mix =
  (...args) =>
  (value = null) =>
    args.reduce((a, b) => (b && b(value)) || a, undefined)

export const maxLength =
  (length, message = `Ingresar máximo ${length} caracteres`) =>
  value =>
    value ? ((value || '').length > length ? message : undefined) : undefined

export const minLength =
  (length, message = `Ingresar mínimo ${length} caracteres`) =>
  value =>
    value ? ((value || '').length < length ? message : undefined) : undefined

export const length =
  (length, message = `Ingresar ${length} caracteres.`) =>
  value =>
    value ? ((value || '').length !== length ? message : undefined) : undefined

export const required =
  (message = 'Campo requerido') =>
  value =>
    typeof value === 'boolean' && value ? undefined : value === 0 ? undefined : value ? undefined : message

const EMAIL_REGEX = /^[\w-.]+@([a-zA-Z0-9-]+\.)+[\w]{2,4}$/

const PHONE_REGEX = /^\+?[0-9]{1,3}-?[0-9]{5,12}$/
const PERUVIAN_PHONE_REGEX = /^\+?[0-9]{9}$/

const NUMBER_REGEX = /^\d+$/
const LETTER = /^([a-zA-ZÀ-ÿ\u00f1\u00d1 ])+$/

const MIN_ONE_LETTER_REGEX = /[a-zA-ZÀ-ÿ]/
const MIN_ONE_NUMBER_REGEX = /[0-9]/

export const isEmail =
  (message = 'No es un email válido') =>
  value =>
    value ? (EMAIL_REGEX.test(value) ? undefined : message) : undefined

export const isPhone =
  (message = 'No es un teléfono válido') =>
  value =>
    value ? (PHONE_REGEX.test(value) ? undefined : message) : undefined

export const isPeruvianCellphone =
  (message = 'No es un teléfono válido') =>
  value =>
    value ? (PERUVIAN_PHONE_REGEX.test(value) ? undefined : message) : undefined

export const isNumber =
  (message = 'Solo se permiten números') =>
  value =>
    value ? (NUMBER_REGEX.test(value) ? undefined : message) : undefined

export const requiredArray =
  (message = 'Campo requerido', maxLength = 2) =>
  value =>
    value && value.filter(v => !v?._destroy).length >= maxLength ? undefined : message

export const isDate =
  (message = 'Ingresa una fecha válida') =>
  value =>
    value === null ? undefined : value instanceof Date && value?.getTime() !== value?.getTime() ? message : undefined

export const editPassword =
  (message = 'Si cambias tu contraseña, ingresar mínimo 8 caracteres') =>
  value =>
    !value ? undefined : value.length >= 8 ? undefined : message

export const isRichTextFieldEmpty =
  (message = 'Campo requerido') =>
  html =>
    html && parseHtml2EditorState(html).getCurrentContent().hasText() ? undefined : message

const URL_REGEX = new RegExp(
  '^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|localhost|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+=]*)*' + // port and path
    '((\\?|\\#)[;&a-z\\d%\\[\\]_.~+=-]*)?' + // query string
    '(:[0-9]{1,5})?(/.*)?$',
  'i'
)

export const isURL =
  (message = 'No es una URL válida') =>
  value =>
    value ? (URL_REGEX.test(value) ? undefined : message) : undefined

export const isLetter =
  (message = 'Solo se permite letras') =>
  value =>
    value ? (LETTER.test(value) ? undefined : message) : undefined

export const minOneLetter =
  (message = 'Ingresar mínimo una letra') =>
  value =>
    value ? (MIN_ONE_LETTER_REGEX.test(value) ? undefined : message) : undefined

export const minOneNumber =
  (message = 'Ingresar mínimo un número') =>
  value =>
    value ? (MIN_ONE_NUMBER_REGEX.test(value) ? undefined : message) : undefined
