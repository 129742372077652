import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { request } from '../../../api/apiCore'
import { useAuth } from '../../../auth/Auth'
import { INSTITUTION_GOBPE_ID, ORDER_OPTIONS } from '../../../constants/globals'
import { urlSearchParamsData } from '../../../helpers/helpers'
import useFilters from '../../../hooks/useFilters'
import { useInstitutionGobpeIdData } from '../../../hooks/useInstitutions'
import BoxTable from '../../ui/BoxTable'
import InternalAreasTable from './InternalAreasTable'

const setInitialPageFilter = (admin, urlSearchParams, institution) => {
  if (admin.is_admin_carpeta && !urlSearchParams.institution_gobpe_id) {
    return {
      institution_gobpe_id: { value: admin.institution_gobpe_id.value, label: admin.institution_gobpe_id.label }
    }
  }
  if (urlSearchParams.institution_gobpe_id) {
    return {
      institution_gobpe_id: institution ? { value: institution.gobpe_id, label: institution.name } : '',
      search: urlSearchParams?.search
    }
  }
}

export const InternalAreas = () => {
  const urlSearchParams = urlSearchParamsData()
  const urlFetch = 'admin/areas.json'
  const queryClient = useQueryClient()
  const { admin } = useAuth()
  const url = '/admin/institutions'
  const { data: institution } = useInstitutionGobpeIdData(
    urlSearchParams.institution_gobpe_id || INSTITUTION_GOBPE_ID,
    url
  )
  const initialPageFilter = setInitialPageFilter(admin, urlSearchParams, institution)
  const { onFilter, initialFilters, results: areas } = useFilters(urlFetch, { sort: 'created_at desc' })

  const handleDestroy = async area => {
    if (window.confirm(`¿Estás seguro que deseas eliminar el área ${area.name}?`)) {
      try {
        await request({ url: `/admin/areas/${area.id}`, method: 'DELETE' })
        queryClient.invalidateQueries(urlFetch)
      } catch (error) {
        window.alert(error.response.data.message)
      }
    }
  }

  useEffect(() => {
    if (admin.is_admin_carpeta) {
      onFilter({ institution_gobpe_id: urlSearchParams.institution_gobpe_id || admin.institution_gobpe_id?.value })
    }
  }, [])

  const customOnFilter = values => {
    const data = {
      ...values,
      institution_gobpe_id: values.institution_gobpe_id?.value || ''
    }
    onFilter(data)
  }

  const urlArea =
    admin.is_admin_carpeta && admin.institution_gobpe_id?.value
      ? '/admin/areas/crear?institution_gobpe_id=' + urlSearchParams.institution_gobpe_id
      : '/admin/areas/crear'

  return (
    <BoxTable
      title="Áreas"
      initialFilters={{ ...initialFilters, ...initialPageFilter }}
      onFilter={customOnFilter}
      buttons={[{ Text: 'Crear una nueva área', cardUrl: urlArea, isActiveButton: true, className: 'bg-white' }]}
      sortOptions={ORDER_OPTIONS}
      values={areas}
      searchPlaceholder="Buscar por nombre"
      displayTotal={true}
      searchFieldName="search"
      showInstitution={true}
      className="bg-gray-40 rounded mb-6"
    >
      <InternalAreasTable
        internalAreas={areas.data}
        onDestroy={handleDestroy}
        urlFetch={urlFetch}
        totalPages={areas.total_pages}
      />
    </BoxTable>
  )
}
