import React from 'react'
import PropTypes from 'prop-types'
import VerticalLinearStepper from './VerticalLinearStepper'

const StatusTrack = ({ steps, activeStep, stepContent }) => {
  const [stateStep] = activeStep
  const activeStepIndex = Object.values(steps).findIndex(step => step.state === stateStep)
  const _steps = Object.values(steps).map(step => step.label)

  return <VerticalLinearStepper steps={_steps} activeStep={activeStepIndex} stepContent={stepContent} />
}

StatusTrack.propTypes = {
  steps: PropTypes.object,
  activeStep: PropTypes.array,
  stepContent: PropTypes.node
}

export default StatusTrack
