import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import CardButton from './CardButton'

const CardInfo = ({
  cardIcon,
  title,
  infoText,
  buttonText,
  isActiveButton = true,
  cardUrl,
  cardClassName,
  contentClassName
}) => {
  return (
    <div
      className={clsx(
        'border-b-0.75 border-gray-850 p-8 max-w-90 shadow-card-login grid grid-cols-1 content-between',
        cardClassName
      )}
    >
      <div className={contentClassName}>
        {cardIcon && <div className="pb-7.5 flex justify-center">{cardIcon}</div>}
        <div className="font-bold text-xl md:text-2xl pb-4 ">{title}</div>
        <div className="pb-6">{infoText}</div>
      </div>
      <CardButton text={buttonText} url={cardUrl} active={isActiveButton} />
    </div>
  )
}

CardInfo.propTypes = {
  cardIcon: PropTypes.element,
  title: PropTypes.string,
  infoText: PropTypes.string,
  buttonText: PropTypes.string,
  isActiveButton: PropTypes.bool,
  cardUrl: PropTypes.string,
  cardClassName: PropTypes.string,
  contentClassName: PropTypes.string
}

export default CardInfo
