import React from 'react'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'

import ExternalLinkIcon from '../../assets/images/external_link_icon.svg'
import InformationIcon from '../../assets/images/information_empty_icon.svg'
import AlertCard from '../ui/AlertCard'
import HeadingTag from '../ui/HeadingTag'
import TagButton from '../ui/TagButton'

const DocumentUngenerated = ({ documentData }) => (
  <>
    <div className="border-b pb-8 mb-8 md:pb-10 md:mb-10">
      <HeadingTag title={documentData.name} className="font-bold text-3xl md:text-4xl md:pr-10" />
      <p className="pt-4 md:pt-5">{documentData.description}</p>
      <p className="pt-3 font-bold">
        Emitido por {documentData.institution_name} {documentData.institution_abbreviation}
      </p>
      <div className="mt-6">
        <AlertCard
          icon={<ReactSVG src={InformationIcon} />}
          title="Informativo"
          content="La entidad emisora no ha registrado información tuya relacionada con este documento.  "
          type="infoDocument"
          className="md:max-w-2xl"
          titleHidden={false}
        />
      </div>
      <p className="pt-8 pb-5">
        El trámite para solicitar este documento se realiza directamente en la web de la entidad.
      </p>
      <TagButton
        label="Ir al servicio"
        icon={<ReactSVG src={ExternalLinkIcon} className="pl-2" />}
        urlPath={documentData.service_link}
        className="!px-3 justify-between md:justify-normal"
      />
    </div>
  </>
)

DocumentUngenerated.propTypes = {
  documentData: PropTypes.object
}

export default DocumentUngenerated
