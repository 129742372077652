import React from 'react'

import { FormControl } from '@material-ui/core'

import { dataToOptions } from '../../helpers/helpers'
import { useUserInstitutionsData } from '../../hooks/useInstitutions'
import { DateRange } from '../fields/DateRange'
import Filter from '../fields/Filter'
import SearchField from '../fields/SearchField'
import SelectField from '../fields/SelectField'
import HighlightFilter from './HighlightFilter'
import { LabelFilterList } from './LabelFilterList'
import RadioFieldInfo from './RadioFieldInfo'

const messageOptions = [
  {
    id: 1,
    label: 'Todas',
    value: 'all',
    withIcon: false
  },
  {
    id: 2,
    label: 'Notificaciones',
    value: 'notification',
    withIcon: true,
    info: 'Es la acción que realiza la entidad pública para avisar al ciudadano el resultado de su trámite, respetando su derecho al debido procedimiento.'
  },
  {
    id: 3,
    label: 'Comunicaciones',
    value: 'communication',
    withIcon: true,
    info: 'Es la acción que realiza la entidad pública para informar al ciudadano sobre un tema o hecho relevante que es de su interés.'
  }
]

export const MessagesFilters = () => {
  const { data: institutions, isLoading } = useUserInstitutionsData()

  return (
    <Filter>
      <FormControl component="fieldset" className="w-full">
        <SearchField name="search" placeholder="Buscar por palabra clave" />
        <SelectField
          name="institution_id"
          label="Entidad que envía"
          defaultOption="Selecciona entidad"
          size="full"
          disabled={isLoading}
          options={dataToOptions(institutions || [], ['id', 'name'])}
        />
        <DateRange initText="Desde" finalText="Hasta" />
        <RadioFieldInfo
          containerClassName="mt-8 pb-8 border-b border-blue-200"
          name="message_type"
          label="Tipo de mensaje"
          options={messageOptions}
        />
        <HighlightFilter name="highlight" />
        <LabelFilterList childsName="label_id" />
      </FormControl>
    </Filter>
  )
}
