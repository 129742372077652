import React from 'react'
import { Form } from 'react-final-form'

import PropTypes from 'prop-types'

import { DOCUMENT_TYPES } from '../../../constants/globals'
import SaveButton from '../../fields/SaveButton'
import SelectField from '../../fields/SelectField'
import TextField from '../../fields/TextField'
import * as validations from '../../fields/Validations'

const ReceiptForm = ({ handleSubmit }) => (
  <Form
    onSubmit={handleSubmit}
    subscription={{ submitting: true, submitError: true, pristine: true }}
    render={({ handleSubmit, submitError, submitting, pristine }) => (
      <form onSubmit={handleSubmit} className="bg-gray-30 rounded p-8">
        <section className="flex gap-10">
          <div className="flex items-start gap-6 w-full">
            <SelectField
              size="full"
              name="citizen_id_code"
              label="Tipo de documento de identidad"
              defaultOption="Selecciona el tipo de documento"
              containerClassName="w-1/2"
              errorClassName="absolute"
              options={DOCUMENT_TYPES}
              validate={validations.required()}
            />
            <TextField
              size="full"
              name="citizen_id_number"
              label="Número de documento"
              margin="none"
              rootClassName="w-1/2"
              errorClassName="absolute"
              validate={validations.mix(validations.required(), validations.isNumber())}
            />
          </div>
          <SaveButton
            label="Añadir destinatario"
            size="full"
            className="max-w-47 !mt-auto"
            disabled={submitting || pristine}
          />
        </section>
        {submitError && <p className="text-red-300 text-base pt-2">{submitError}</p>}
      </form>
    )}
  />
)

ReceiptForm.propTypes = {
  handleSubmit: PropTypes.func
}

export default ReceiptForm
