import React from 'react'
import { ReactSVG } from 'react-svg'

import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ToggleIcon from 'material-ui-toggle-icon'
import PropTypes from 'prop-types'

import BookmarkBorderIcon from '../../assets/images/bookmark_border_icon.svg'
import BookmarkIcon from '../../assets/images/bookmark_icon.svg'
import { TooltipActions } from './TooltipActions'

const useStyles = makeStyles({
  iconButton: {
    color: '#26292E',
    padding: '8px'
  }
})
export const LabelAction = ({ selectedLabelId, onLabelClick }) => {
  const classes = useStyles()

  return (
    <TooltipActions text="Etiquetar" placement="bottom">
      <IconButton disableRipple classes={{ root: classes.iconButton }} onClick={onLabelClick}>
        <ToggleIcon
          on={selectedLabelId !== null}
          onIcon={<ReactSVG src={BookmarkIcon} />}
          offIcon={<ReactSVG src={BookmarkBorderIcon} />}
        />
      </IconButton>
    </TooltipActions>
  )
}

LabelAction.propTypes = {
  selectedLabelId: PropTypes.number,
  onLabelClick: PropTypes.func
}
