import React from 'react'
import { Categories } from '../../components/admin/documents/Categories'
import { Container } from '../../components/Container'
import PageHeading from '../../components/ui/PageHeading'
import { Documents } from '../../components/admin/documents/Documents'

export const DocumentsAndCategoriesPage = () => {
  return (
    <Container className="md:h-auto">
      <div className="px-5 pt-14 pb-16 md:pb-0">
        <PageHeading title="Admin Mis documentos" pageDescription="Crea y edita documentos y sus categorías." />
        <div className="mb-8">
          <Documents />
        </div>
        <div className="mb-8">
          <Categories />
        </div>
      </div>
    </Container>
  )
}
