import React, { useEffect, useState } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

const ProgressLoading = ({ className }) => {
  const [progress, setProgress] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress < 100) {
          return prevProgress + 1
        }
        clearInterval(interval)
        return prevProgress
      })
    }, 50)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className={clsx('w-full h-1', className)}>
      <div className="h-full transition-width ease-in-out bg-yellow-500" style={{ width: `${progress}%` }}></div>
    </div>
  )
}

ProgressLoading.propTypes = {
  className: PropTypes.string
}

export default ProgressLoading
