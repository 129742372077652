import React from 'react'
import { Form } from 'react-final-form'

import PropTypes from 'prop-types'

import { PASSWORD_RULES } from '../../constants/globals'
import { Button } from '../fields/Button'
import PasswordField from '../fields/PasswordField'
import SaveButton from '../fields/SaveButton'
import * as validations from '../fields/Validations'
import HeadingTag from '../ui/HeadingTag'

const UserPasswordForm = ({ title, passwordFieldLabel, submitButtonLabel, handleSubmitForm, handleCancelForm }) => {
  return (
    <div className="mx-auto max-w-lg border border-blue-200 p-6 md:p-9 shadow-auth-register">
      <HeadingTag title={title} className="font-bold text-xl md:text-2xl md:text-center" />
      <Form
        onSubmit={handleSubmitForm}
        render={({ handleSubmit, submitError, submitting, pristine }) => (
          <form onSubmit={handleSubmit} className="pt-6 md:pt-7.5">
            <PasswordField
              label={passwordFieldLabel}
              name="password"
              size="full"
              labelClassName="mb-2"
              checkboxLabel="Mostrar contraseña"
              checkboxName="show_password"
              checkboxClassName="pb-7"
              passwordRules={PASSWORD_RULES}
              validate={validations.mix(
                validations.required(),
                validations.minLength(8),
                validations.minOneLetter(),
                validations.minOneNumber()
              )}
            />
            {submitError && <div className="text-red-500 font-bold text-center pb-6">{submitError}</div>}
            <div className="pb-3.75">
              <SaveButton label={submitButtonLabel} size="full" disabled={pristine || submitting} />
            </div>
            <Button size="full" variant="text" onClick={handleCancelForm}>
              Cancelar
            </Button>
          </form>
        )}
      />
    </div>
  )
}

UserPasswordForm.propTypes = {
  title: PropTypes.string,
  passwordFieldLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  handleSubmitForm: PropTypes.func,
  handleCancelForm: PropTypes.func
}

export default UserPasswordForm
