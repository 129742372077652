import React from 'react'
import { useField } from 'react-final-form'
import { ReactSVG } from 'react-svg'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import HighlightActiveIcon from '~/assets/images/highlight_active_icon.svg'
import HighlightIcon from '~/assets/images/highlight_icon.svg'

import { FilterRadioButtonField } from './FilterRadioButtonField'

const HighlightFilter = ({ name }) => {
  const { input } = useField(name)

  return (
    <FilterRadioButtonField
      className={clsx('px-4 py-6 border-b border-blue-200 md:hover:bg-gray-40', input.value === 'true' && 'bg-blue-40')}
      name={name}
      label="Destacado"
      value={true}
      margin="none"
      icon={<ReactSVG src={HighlightIcon} />}
      checkIcon={<ReactSVG src={HighlightActiveIcon} />}
    />
  )
}

HighlightFilter.propTypes = {
  name: PropTypes.string
}

export default HighlightFilter
