import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

export const MessageContentSubtitle = ({ icon, subtitle, description, contentClassName, subtitleClassName }) => {
  return (
    <div className={clsx('flex md:items-center items-start', contentClassName)}>
      {icon}
      <p className={clsx('font-medium', subtitleClassName)}>
        {subtitle}
        <span className="font-normal ml-1">{description}</span>
      </p>
    </div>
  )
}

MessageContentSubtitle.propTypes = {
  icon: PropTypes.element,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  contentClassName: PropTypes.string,
  subtitleClassName: PropTypes.string
}
