import React from 'react'

import { Container } from '../../components/Container'
import { Roles } from '../../components/admin/roles/Roles'
import PageHeading from '../../components/ui/PageHeading'

export const RolesAndCitizensPage = () => {
  return (
    <Container className="md:h-auto">
      <div className="px-5 pt-14 pb-16 md:pb-0">
        <PageHeading title="Roles" pageDescription="Crea y edita roles" />
        <div className="mb-8">
          <Roles />
        </div>
      </div>
    </Container>
  )
}
