import React from 'react'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'
import SearchIcon from '~/assets/images/bottomless_search_icon.svg'

const SearchMini = ({ value, onChange }) => {
  return (
    <div className="flex items-center gap-2 rounded-lg bg-blue-300 p-2">
      <ReactSVG beforeInjection={svg => svg.setAttribute('style', 'width: 14px')} src={SearchIcon} />
      <input
        placeholder="Buscar"
        value={value}
        onChange={onChange}
        className="w-full text-sm placeholder-gray-525 bg-inherit focus:outline-none"
      />
    </div>
  )
}
SearchMini.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}
export default SearchMini
