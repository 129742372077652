import React from 'react'

import { Container } from '../../components/Container'
import { WebServices } from '../../components/admin/webServices/WebServices'
import PageHeading from '../../components/ui/PageHeading'

export const WebServicesPage = () => {
  return (
    <Container className="md:h-auto">
      <div className="px-5 pt-14 pb-16 md:pb-0">
        <PageHeading title="Web Services" pageDescription="Crea credenciales para una entidad." />
        <WebServices />
      </div>
    </Container>
  )
}
