import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useField } from 'react-final-form'

import clsx from 'clsx'
import { EditorState, Modifier, SelectionState } from 'draft-js'
import PropTypes from 'prop-types'

import { parseEditorState2Html, parseHtml2EditorState } from '../../helpers/helpers'
import { marginSizes } from '../../utils/sizes'
import Error from './Error'
import { LinkToolBar } from './LinkToolBar'

const getContentLength = editorState => editorState.getCurrentContent().getPlainText().length
const forbidNewWords = (words, state, maxContentLength) => getContentLength(state) + words.length > maxContentLength

const setSelectionAtTheBeginning = startKey =>
  new SelectionState({
    anchorKey: startKey,
    anchorOffset: 0,
    focusKey: startKey,
    focusOffset: 0
  })

const getSelectionAttr = selection => ({
  endKey: selection.getEndKey(),
  startOffset: selection.getStartOffset(),
  endOffset: selection.getEndOffset(),
  startKey: selection.getStartKey()
})

const getEditorAttr = state => ({
  selection: state.getSelection(),
  content: state.getCurrentContent()
})

const deletionAllowedTypes = ['unordered-list-item', 'ordered-list-item']
const nonCharactersBlocksTypes = ['unordered-list-item', 'ordered-list-item']
const isEmptySelection = (startOff, endOff, startKey, endKey) => startOff === endOff && startKey === endKey

const setPlaceholder = (state, placeholder) => {
  const { content } = getEditorAttr(state)
  if (content.getBlockMap().size === 1 && nonCharactersBlocksTypes.includes(content.getFirstBlock().getType())) {
    return ''
  }
  return placeholder
}

export const HtmlField = ({
  name,
  label,
  hint,
  maxContentLength = 500,
  placeholder = '',
  initialValue = '<p></p>',
  validate,
  margin = 'normal',
  ...props
}) => {
  const { input } = useField(name, { validate, initialValue })
  const [editorState, setEditorState] = useState(() => parseHtml2EditorState(input.value))

  const handleBeforeInput = char => forbidNewWords(char, editorState, maxContentLength)
  const handlePastedText = text => forbidNewWords(text, editorState, maxContentLength)
  const handleReturn = () => getContentLength(editorState) > maxContentLength - 1

  const handleEditorStateChange = value => {
    let parsed = parseEditorState2Html(value).trim()
    parsed = parsed.replace(/target="_self"/g, 'target="_blank"')
    setEditorState(value)
    input.onChange(parsed)
  }

  const handleKeyCommand = (cmd, state) => {
    const { selection, content } = getEditorAttr(state)
    const { startKey, endKey, startOffset, endOffset } = getSelectionAttr(selection)
    const contentBlock = content.getBlockForKey(startKey)
    const contentBlockType = contentBlock.getType()
    const contentBlockEmpty = !contentBlock.getLength()
    const contentBlockTypeDeletable = deletionAllowedTypes.includes(contentBlockType)
    const atTheBeginningOfStartBlock = startOffset === 0
    const emptySelection = isEmptySelection(startOffset, endOffset, startKey, endKey)

    if (cmd === 'backspace') {
      if (contentBlockTypeDeletable && (contentBlockEmpty || atTheBeginningOfStartBlock) && emptySelection) {
        const newContent = Modifier.setBlockType(content, setSelectionAtTheBeginning(startKey), 'unstyled')
        let newEditorState = EditorState.push(state, newContent, 'change-block-type')
        newEditorState = EditorState.forceSelection(newEditorState, setSelectionAtTheBeginning(startKey))
        handleEditorStateChange(newEditorState)
        return true
      }
      return false
    }
    return false
  }

  return (
    <div className={clsx('js-field-container', marginSizes[margin])}>
      {label && (
        <label
          className="block font-bold mb-3"
          id={`${name}-label`}
          htmlFor={`${name}-input`}
          aria-describedby={`${name}-hint`}
        >
          {label}
        </label>
      )}
      {hint && (
        <p id={`${name}-hint`} className="mb-4">
          {hint}
        </p>
      )}
      <div className="relative">
        <Editor
          placeholder={setPlaceholder(editorState, placeholder)}
          wrapperClassName="wrapper-class"
          toolbarClassName="toolbar-class"
          editorClassName="editor-class"
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
          handleBeforeInput={handleBeforeInput}
          handleReturn={handleReturn}
          handleKeyCommand={handleKeyCommand}
          onFocus={() => input.onFocus()}
          onBlur={() => input.onBlur()}
          handlePastedText={handlePastedText}
          ariaLabel={props['aria-label'] || label}
          toolbar={{
            options: ['inline', 'list', 'link'],
            inline: { inDropdown: false, options: ['bold', 'italic'] },
            list: { inDropdown: false, options: ['unordered', 'ordered'] },
            link: {
              inDropdown: false,
              options: ['link'],
              component: LinkToolBar,
              showOpenOptionOnHover: false,
              defaultTargetOption: '_blank'
            }
          }}
        />
        <Error name={name} className="absolute" />
        <p className="font-normal text-sm mt-1 text-gray-700 text-right">
          {getContentLength(editorState)}/{maxContentLength} caracteres
        </p>
      </div>
    </div>
  )
}

HtmlField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  maxContentLength: PropTypes.number,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  'aria-label': PropTypes.string,
  validate: PropTypes.func,
  margin: PropTypes.string
}
