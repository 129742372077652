import React from 'react'
import { Form } from 'react-final-form'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Button } from '../fields/Button'
import SaveButton from '../fields/SaveButton'
import TextField from '../fields/TextField'
import * as validations from '../fields/Validations'
import HeadingTag from '../ui/HeadingTag'

const RucNumberForm = ({
  initialValues = {},
  handleSubmit,
  handleCancel,
  showModalOptions = false,
  title,
  subtitle,
  subtitleClassName = '',
  labelClassName = ''
}) => {
  return (
    <>
      {title && <HeadingTag title={title} className="font-bold text-3xl" />}
      <p className={subtitleClassName}>{subtitle}</p>
      <Form
        onSubmit={handleSubmit}
        subscription={{ pristine: true, submitting: true, submitError: true }}
        initialValues={initialValues}
        render={({ handleSubmit, submitError, submitting, pristine }) => (
          <form onSubmit={handleSubmit} className="max-w-90">
            <p className={clsx('font-bold mb-2', labelClassName)}>Ingresa tu número de RUC</p>
            <div className="flex items-center mb-6 md:mb-7.5">
              <TextField
                name="person_type"
                margin="none"
                rootClassName="w-17.5"
                labelClassName="mb-2"
                className="text-center"
                maxLength={2}
                onKeyPress={validations.validateOnKeyPress('onlyNumber')}
                validate={validations.mix(validations.required(), validations.isNumber(), validations.length(2))}
              />
              <div className="mx-1.3 md:mx-2.5 w-2.5 border border-gray-850"></div>
              <TextField
                name="document_number"
                margin="none"
                rootClassName="w-40"
                className="!bg-gray-30 text-gray-90 text-center font-medium border-gray-100"
                labelClassName="mb-2"
                disabled
              />
              <div className="mx-1.3 md:mx-2.5 w-2.5 border border-gray-850"></div>
              <TextField
                name="check_digit"
                margin="none"
                rootClassName="w-17.5"
                labelClassName="mb-2"
                className="text-center"
                maxLength={1}
                size="full"
                onKeyPress={validations.validateOnKeyPress('onlyNumber')}
                validate={validations.mix(validations.required(), validations.isNumber(), validations.length(1))}
              />
            </div>
            {submitError && <div className="text-red-500 font-bold text-center pb-6">{submitError}</div>}
            {!showModalOptions ? (
              <>
                <div className="pb-3.75">
                  <SaveButton label="Guardar cambios" size="full" disabled={pristine || submitting} />
                </div>
                <Button size="full" variant="text" onClick={handleCancel}>
                  Regresar
                </Button>
              </>
            ) : (
              <section className="flex flex-col items-center">
                <SaveButton
                  label="Guardar RUC"
                  className="!max-w-full md:!max-w-max md:!px-10"
                  color="primary"
                  disabled={pristine || submitting}
                />
                <Button size="full" className="!mt-4" variant="text" onClick={handleCancel}>
                  Cancelar
                </Button>
              </section>
            )}
          </form>
        )}
      />
    </>
  )
}

RucNumberForm.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  showModalOptions: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleClassName: PropTypes.string,
  labelClassName: PropTypes.string
}

export default RucNumberForm
