import React, { Fragment, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../../api/apiCore'
import { Container } from '../../../components/Container'
import Breadcrumb from '../../../components/ui/Breadcrumb'
import { convertDataToBreadcrumbRoute } from '../../../helpers/helpers'
import { RolesForm } from '../../forms/admin/RolesForm'
import HeadingTag from '../../ui/HeadingTag'

export const RoleNew = () => {
  const breadcrumbData = convertDataToBreadcrumbRoute('Crear rol gestion')
  const navigate = useNavigate()
  const onSubmit = async ({ name, modules, roles_allowed_create }) => {
    try {
      const role_modules_attributes = []
      modules && modules.map(m => role_modules_attributes.push({ app_module_id: m }))
      const data = {
        name,
        role_modules_attributes,
        roles_allowed_create: roles_allowed_create?.map(el => el.value) || []
      }
      const response = await request({ url: '/admin/roles', data, method: 'POST' })
      if (response.response?.data.error) return { [FORM_ERROR]: `Ocurrió un error: ${response.response.data.error}` }
      navigate('/admin/roles-y-ciudadanos')
    } catch (error) {
      return {
        [FORM_ERROR]: 'Ocurrio un error al registrar el rol gestión.'
      }
    }
  }
  const initialValues = useMemo(
    () => ({
      name: '',
      role: '',
      modules: ''
    }),
    []
  )

  return (
    <Fragment>
      <Breadcrumb routesKey="roles" data={breadcrumbData} />
      <Container className="py-16 pl-20 px-6">
        <HeadingTag title="Crear rol gestión" className="mb-4 font-bold text-3xl" />
        <RolesForm initialValues={initialValues} onSubmit={onSubmit} create />
      </Container>
    </Fragment>
  )
}
