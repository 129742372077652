import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../../api/apiCore'
import { Container } from '../../../components/Container'
import { UsersForm } from '../../../components/forms/admin/UsersForm'
import Breadcrumb from '../../../components/ui/Breadcrumb'
import HeadingTag from '../../../components/ui/HeadingTag'
import { allowedModules } from '../../../configs/users'
import { convertDataToBreadcrumbRoute, urlSearchParamsData } from '../../../helpers/helpers'

const formValues = {
  email: '',
  name: '',
  document_type_code: '',
  document_number: '',
  password: '',
  area_id: '',
  role: ''
}

export const UserNew = () => {
  const [initialValues, setInitialValues] = useState(formValues)
  const { institution_gobpe_id } = urlSearchParamsData()
  const usersFormRef = useRef()
  const navigate = useNavigate()
  const breadcrumbData = convertDataToBreadcrumbRoute('Crear usuario')

  const onSubmit = async values => {
    const { gobUser, setGobUser } = usersFormRef.current

    try {
      if (!gobUser.verifiedEmail) {
        const gobUserVerification = await request({
          url: '/admin/gob_users/verify',
          data: { email: values.email },
          method: 'POST'
        })

        const responseData = gobUserVerification.response?.data

        if (responseData?.is_management_user) return { [FORM_ERROR]: responseData.error }

        if (responseData?.error) {
          setGobUser({ ...gobUser, verifiedEmail: true })
          return
        }

        const { id: gob_user_id, email, name, document_type_code, document_number } = gobUserVerification
        setInitialValues({ ...initialValues, gob_user_id, email, name, document_type_code, document_number })
        setGobUser({ verifiedEmail: true, exists: true })
      } else {
        const data = {
          ...values,
          allowed_modules: values.allowed_modules ? allowedModules : null,
          ...(institution_gobpe_id && { institution_gobpe_id })
        }

        const responseUser = await request({ url: '/admin/gob_users', data, method: 'POST' })

        if (responseUser.response?.data)
          return { [FORM_ERROR]: responseUser.response?.data.email || responseUser.response?.data.error }

        navigate(
          institution_gobpe_id
            ? `/admin/areas-y-usuarios?institution_gobpe_id=${institution_gobpe_id}`
            : '/admin/areas-y-usuarios'
        )
      }
    } catch (error) {
      return { [FORM_ERROR]: 'Ocurrió un error al registrar el usuario.' }
    }
  }

  return (
    <>
      <Breadcrumb routesKey="users" data={breadcrumbData} />
      <Container className="px-20 pt-14 pb-18">
        <HeadingTag title="Crear usuario" className="mb-7.5 font-bold text-4xl" />
        <UsersForm initialValues={initialValues} onSubmit={onSubmit} create ref={usersFormRef} />
      </Container>
    </>
  )
}
