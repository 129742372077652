import React from 'react'

import PropTypes from 'prop-types'

import LabelList from './LabelList'

export const LabelListPopper = ({ title, titleClass, listClass, handleTagged }) => {
  return (
    <>
      <h3 className={titleClass}>{title}</h3>
      <LabelList className={listClass} onSelect={label => handleTagged(label.id)} withSearcher />
    </>
  )
}

LabelListPopper.propTypes = {
  title: PropTypes.string,
  titleClass: PropTypes.string,
  listClass: PropTypes.string,
  handleTagged: PropTypes.func
}
