import React from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'

import NextIcon from '../../assets/images/next_icon.svg'

const classNamesDisabled = [
  'py-2.75',
  'md:py-3.5',
  'px-5',
  'rounded',
  'font-bold',
  'bg-blue-200',
  'text-gray-500',
  'text-center'
]

const classNames = [
  'flex',
  'items-center',
  'justify-between',
  'leading-5',
  'py-2.75',
  'md:py-3.5',
  'px-5',
  'rounded',
  'font-bold',
  'border-2',
  'border-blue-700',
  'text-blue-700',
  'hover:bg-gray-40',
  'focus:bg-blue-100',
  'max-h-17'
]

const CardButton = ({ text, url, active, icon = true, className }) => {
  if (!active) return <div className={classNamesDisabled.join(' ')}>{text}</div>

  return (
    <Link className={`${classNames.join(' ')} ${className}`} role="button" to={url}>
      <span className="pr-1">{text}</span>
      {icon && <ReactSVG src={NextIcon} />}
    </Link>
  )
}

CardButton.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  active: PropTypes.bool,
  icon: PropTypes.bool,
  className: PropTypes.string
}

export default CardButton
