import React from 'react'
import { Link } from 'react-router-dom'

import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'

import { Pagination } from '../../ui/Pagination'

const CategoryTable = ({ data = [], onDestroy, totalPages = 0 }) => {
  const showActions = category => {
    return (
      <td className="w-1/2 text-right">
        <IconButton color="primary" component={Link} to={`/admin/categorias/edit/${category.id}`}>
          <EditIcon />
        </IconButton>
        <IconButton color="primary" type="button" onClick={() => onDestroy(category)}>
          <DeleteIcon />
        </IconButton>
      </td>
    )
  }

  return (
    <div>
      <table className="table table-fixed w-full">
        <thead>
          <tr>
            <th className="w-1/4 text-left">Nombre</th>
            <th className="w-1/2 text-left">Descripcion</th>
            <th className="w-1/4 text-right">
              <span className="pr-7 md:pr-5">Acciones</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map(category => (
            <tr key={category.id}>
              <td className="w-1/4 text-left text-lg">{category.name}</td>
              <td className="w-1/2 text-left text-lg">{category.description}</td>
              {showActions(category)}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination numPages={totalPages} paginationLabel="category_page" />
    </div>
  )
}

export default CategoryTable

CategoryTable.propTypes = {
  data: PropTypes.array,
  onDestroy: PropTypes.func,
  totalPages: PropTypes.number
}
