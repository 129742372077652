import React from 'react'

import PropTypes from 'prop-types'

const TagButton = ({ label, icon, urlPath, className }) => {
  return (
    <a
      className={`bg-blue-100 text-blue-700 font-bold px-4.5 py-2.5 flex items-center md:justify-center md:w-fit ${className}`}
      href={urlPath}
      target="_blank"
      rel="noreferrer"
    >
      {label} {icon}
    </a>
  )
}

TagButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  urlPath: PropTypes.string,
  className: PropTypes.string
}

export default TagButton
