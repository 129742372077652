import React from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import arrayMutators from 'final-form-arrays'

import { Container } from '../../components/Container'
import DocumentsSendSection from '../../components/admin/DocumentsSendSection'
import { Button } from '../../components/fields/Button'
import { HtmlField } from '../../components/fields/HtmlField'
import SaveButton from '../../components/fields/SaveButton'
import SelectField from '../../components/fields/SelectField'
import TextField from '../../components/fields/TextField'
import * as validations from '../../components/fields/Validations'
import Breadcrumb from '../../components/ui/Breadcrumb'
import FormsHeader from '../../components/ui/FormsHeader'
import HeadingTag from '../../components/ui/HeadingTag'
import { MessageCreateSteps, messageInfoPageConfig, routesByType } from '../../configs/messages'
import { MAX_LENGTH_MESSAGE_SUBJECT_INPUT } from '../../constants/globals'
import {
  convertDataToBreadcrumbRoute,
  dataToOptions,
  documentSendSectionProps,
  findElement,
  getItemFromLocalStorage,
  getJsonItemFromLocalStorage,
  removeItemFromLocalStorage,
  resultsToOptions,
  setItemToLocalStorage
} from '../../helpers/helpers'
import { useAreasData } from '../../hooks/useAreas'
import { useProceduresData } from '../../hooks/useProcedures'
import { useTrackCodeTypesData } from '../../hooks/useTrackCodeTypes'

export const MessageInfoPage = () => {
  const navigate = useNavigate()
  const { data: areas, isLoading: isAreasLoading } = useAreasData()
  const { data: trackCodeTypes, isLoading: isTrackCodeTypesLoading } = useTrackCodeTypesData()
  const { data: procedures, isLoading: isProceduresLoading } = useProceduresData()

  const initialData = getJsonItemFromLocalStorage('messageInfoData')
  const type = getItemFromLocalStorage('messageType')
  const { breadcrumbText, breadcrumbKey, headerTitle, trackCodeLabel, trackCodeSelectValidate, trackCodeTextValidate } =
    messageInfoPageConfig[type]
  const breadcrumbData = convertDataToBreadcrumbRoute(breadcrumbText)

  const onSubmit = values => {
    const { name: area_name } = findElement(areas.data, 'id', values.areas_id)
    const messageInfo = { ...values, area_name }

    if (values.track_code_type_id) {
      const { name: track_code_name } = findElement(trackCodeTypes.data, 'id', values.track_code_type_id)
      messageInfo.track_code_type_name = `${track_code_name} ${values.track_code || ''}`
    }

    if (values.procedure_id) {
      const { name } = findElement(procedures, 'id', values.procedure_id)
      messageInfo.procedure_name = name
    }

    setItemToLocalStorage('messageInfoData', JSON.stringify(messageInfo))
    navigate('/admin/revision-mensaje')
  }

  const handleReturnButton = () => {
    removeItemFromLocalStorage(['messageInfoData'])
    navigate(routesByType[type])
  }

  const initialDataDynamicForm = { ...documentSendSectionProps[type], type }

  return (
    <>
      <Breadcrumb routesKey={breadcrumbKey} data={breadcrumbData} />
      <Container className="px-5 pt-8 pb-14 md:px-20 md:pt-14 md:pb-18">
        <FormsHeader title={headerTitle} steps={MessageCreateSteps} activeStep={MessageCreateSteps.SET_MESSAGE_INFO} />
        <Form
          onSubmit={onSubmit}
          initialValues={initialData}
          mutators={{ ...arrayMutators }}
          subscription={{ submitting: true }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="m-auto max-w-130">
              <section className="p-6 md:p-8 border border-blue-200 shadow-auth-register rounded">
                <HeadingTag
                  title={MessageCreateSteps.SET_MESSAGE_INFO.label}
                  as="h2"
                  className="md:text-center pb-6 md:pb-7 font-bold text-xl md:text-2xl"
                />
                <SelectField
                  size="full"
                  name="areas_id"
                  label="Área competente del pronunciamiento"
                  defaultOption="Selecciona el área"
                  options={dataToOptions(areas || [], ['id', 'name'])}
                  disabled={isAreasLoading}
                  validate={validations.required()}
                />
                <section className="mb-6">
                  <label htmlFor="track_code_type_id-input" className="font-bold">
                    {trackCodeLabel}
                  </label>
                  <p id="track_code_type_id-hint" className="mb-2 leading-5">
                    Indica el tipo y número de documento con el que se da seguimiento al trámite.
                  </p>
                  <article className="md:flex items-start grid grid-cols-1 md:grid-cols-2 gap-6">
                    <SelectField
                      containerClassName="md:w-1/2"
                      size="full"
                      name="track_code_type_id"
                      options={dataToOptions(trackCodeTypes || [], ['id', 'name'])}
                      defaultOption="Selecciona el tipo"
                      disabled={isTrackCodeTypesLoading}
                      validate={trackCodeSelectValidate}
                    />
                    <TextField
                      rootClassName="md:w-1/2"
                      size="full"
                      margin="none"
                      name="track_code"
                      placeholder="Número de seguimiento"
                      onKeyPress={validations.validateOnKeyPress('onlyNumberLetterSlash')}
                      maxLength={20}
                      validate={trackCodeTextValidate}
                    />
                  </article>
                </section>
                {type === 'notification' && (
                  <SelectField
                    label="Nombre del trámite"
                    size="full"
                    name="procedure_id"
                    options={resultsToOptions(procedures || [], ['id', 'name'])}
                    defaultOption="Selecciona"
                    disabled={isProceduresLoading}
                    validate={validations.required()}
                  />
                )}
                <TextField
                  size="full"
                  name="subject"
                  label="Asunto"
                  element="textarea"
                  rows={3}
                  className="!h-full"
                  showLengthDescription
                  maxLength={MAX_LENGTH_MESSAGE_SUBJECT_INPUT}
                  validate={validations.required()}
                />
                <HtmlField
                  name="content"
                  label="Contenido del mensaje"
                  initialValue={initialData?.content}
                  validate={validations.isRichTextFieldEmpty()}
                  maxContentLength={2000}
                />
                <DocumentsSendSection
                  {...initialDataDynamicForm}
                  initialValue={initialData?.file_infos_attributes || [{ name: '', route: '' }]}
                />
              </section>
              <section className="flex justify-end gap-6 mt-10">
                <Button size="full" className="max-w-45" variant="secondary" onClick={handleReturnButton}>
                  Regresar
                </Button>
                <SaveButton label="Continuar" size="full" className="max-w-45" disabled={submitting} />
              </section>
            </form>
          )}
        />
      </Container>
    </>
  )
}
