import React from 'react'

import { Container } from '../../components/Container'
import { InternalAreas } from '../../components/admin/areas/InternalAreas'
import { Users } from '../../components/admin/users/Users'
import PageHeading from '../../components/ui/PageHeading'

export const AreasAndUsersPage = () => (
  <Container className="md:h-auto px-5 md:px-20 pt-8 md:pt-14 pb-16 md:pb-18">
    <PageHeading
      title="Áreas y usuarios"
      pageDescription="En esta sección podrás enviar notificaciones y comunicaciones a los ciudadanos que tengan trámites o procesos abiertos en tu entidad."
    />
    <InternalAreas />
    <Users />
  </Container>
)
