import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { ChevronRight } from '@material-ui/icons'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'

import { useAuth } from '../../auth/Auth'
import {
  flatTabsTreeAndFilterVisible,
  getSubTabsSelectedAndVisible,
  getTabsTree,
  hasChildTabSelected,
  isLastParentTab,
  isParentTab
} from '../../configs/tabs'
import { isAdminRoute } from '../../helpers/helpers'
import { Container } from '../Container'
import { ChildrenTabs } from './ChildrenTabs'

export const ResourcesTab = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const containerMenu = useRef(null)
  const [showMenuMobile, setShowMenuMobile] = useState(false)
  const [showSubMenuMobile, setShowSubMenuMobile] = useState(false)
  const [selectedParentTab, setSelectedParentTab] = useState()
  const [tabIndex, setTabIndex] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const { user, admin } = useAuth()

  const tabsTree = getTabsTree(isAdminRoute && admin.logged, !isAdminRoute && user.logged, admin)
  const flattedTabs = flatTabsTreeAndFilterVisible(tabsTree)
  const selectedSubTabs = getSubTabsSelectedAndVisible(flattedTabs, selectedParentTab)

  const onClickTabHandler = (event, tab) => {
    setShowMenuMobile(false)
    if (isParentTab(tab)) {
      const anchor = showMenuMobile ? containerMenu.current : event.currentTarget
      setAnchorEl(anchor)
      setSelectedParentTab(tab.name)
    }
    if (showMenuMobile) setShowSubMenuMobile(true)
  }

  useEffect(() => {
    setTabIndex(flattedTabs.findIndex(r => r.path === location.pathname))
  }, [location.pathname])

  const handleTabsChange = index => {
    setTabIndex(index)
    navigate(flattedTabs[index].path)
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <Tabs selectedIndex={tabIndex} onSelect={handleTabsChange}>
      <div className="md:flex md:h-17 md:bg-white shadow-menu-dashboard relative z-10">
        <Container className="px-0 md:px-20">
          <div className="flex md:hidden items-center justify-between px-6 h-14">
            <div className="font-medium">Menú</div>
            <button onClick={() => setShowMenuMobile(!showMenuMobile)}>
              <MenuIcon />
            </button>
          </div>
          <TabList
            className={clsx('md:flex flex-col md:flex-row bg-gray-30 absolute md:static w-full', {
              hidden: !showMenuMobile
            })}
          >
            {flattedTabs.map((tab, index) => (
              <Tab
                className={clsx(
                  'tab-item',
                  { 'tab-item__last': isLastParentTab(index, flattedTabs) },
                  { 'hidden': tab.isChildTab },
                  { 'react-tabs__tab--selected': hasChildTabSelected(tab, location.pathname) }
                )}
                onClick={event => onClickTabHandler(event, tab)}
                key={index}
                disabled={isParentTab(tab)}
              >
                {tab.name}{' '}
                {isParentTab(tab) && (showMenuMobile ? <ChevronRight /> : <KeyboardArrowDown className="ml-2" />)}
              </Tab>
            ))}
            {selectedSubTabs?.length && (
              <ChildrenTabs
                anchorEl={anchorEl}
                handleClose={handleClose}
                container={containerMenu.current}
                selectedSubTabs={selectedSubTabs}
                setShowMenuMobile={setShowMenuMobile}
                showSubMenuMobile={showSubMenuMobile}
              />
            )}
          </TabList>
        </Container>
      </div>
      {flattedTabs.map((r, index) => (
        <Container key={index}>
          <TabPanel>{r.component}</TabPanel>
        </Container>
      ))}
    </Tabs>
  )
}
