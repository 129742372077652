import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

const feedbackDetails = {
  description: { label: 'Descripción: ' },
  route: { label: 'Ruta: ' },
  user_institution: { label: 'Entidad: ' },
  contact_label: { label: 'Contactar: ' },
  user_name: { label: 'Nombres y apellidos: ' },
  user_email: { label: 'Correo: ' },
  user_phone_number: { label: 'Celular: ' },
  user_role: { label: 'Rol: ' }
}

const FeedbackCard = ({ feedback }) => {
  return (
    <div className="w-full p-6 mb-6 border-2 border-neutral-400 bg-white rounded-lg">
      <div className="flex justify-between border-b border-neutral-400 pb-4 mb-4">
        <div className="md:w-2/3 flex items-center pb-3 md:pb-0 w-1/2">
          <span className="font-bold text-xl">ID: {feedback.id}</span>
        </div>
        <div className="text-right font-bold pb-3 md:pb-0 md:w-auto w-1/2">
          <div
            className={clsx(
              'text-base py-2 px-4 text-center mb-2 md:mb-4',
              !feedback.is_feedback_user
                ? 'bg-blue-300 text-blue-700 border-blue-600 border rounded-sm'
                : 'bg-green-200 text-green-800 border-green-700 border rounded-sm'
            )}
          >
            {feedback.is_feedback_user ? 'CIUDADANO' : 'ENTIDAD PÚBLICA'}
          </div>
          <div className="px-2">{feedback.created_at}</div>
        </div>
      </div>
      <div className="md:w-2/3 pb-3 md:pb-0">
        {Object.entries(feedbackDetails).map(
          ([key, value], index) =>
            feedback[key] && (
              <div key={index}>
                <span className="font-extrabold">{value.label}</span>
                <span>{feedback[key]}</span>
              </div>
            )
        )}
      </div>
    </div>
  )
}

FeedbackCard.propTypes = {
  feedback: PropTypes.object
}

export default FeedbackCard
