import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { useQueryClient } from 'react-query'

import { Modal } from '@material-ui/core'
import PropTypes from 'prop-types'
import BackButton from '~/components/ui/BackButton'
import Footer from '~/components/ui/Footer'
import { UNPROCESSABLE_ENTITY } from '~/constants/globals'
import { getAxiosErrorMessages } from '~/helpers/helpers'
import { addLabel } from '~/hooks/useLabels'
import { AlertContext } from '~/utils/context'

import SaveButton from '../fields/SaveButton'
import TextField from '../fields/TextField'
import { Header } from '../ui/Header'

const ModalMobileLabelForm = ({ toggle, setToggle, modalTitle }) => {
  const { setAlertMessageMobile } = useContext(AlertContext)
  const queryClient = useQueryClient()
  const onSubmit = async values => {
    const response = await addLabel(values)
    if (response.response?.status === UNPROCESSABLE_ENTITY) {
      return getAxiosErrorMessages(response)
    }
    const newLabel = { ...response, new: true }
    queryClient.setQueryData('labels', oldQueryData => {
      return {
        data: [newLabel, ...oldQueryData.data]
      }
    })
    setToggle()
    setAlertMessageMobile(`Se generó la etiqueta <span class="font-bold">${newLabel.name}.</span>`)
  }

  return (
    <>
      <Modal open={toggle} onClose={() => setToggle()}>
        <div className="bg-white h-full flex flex-col">
          <Header />
          <BackButton onClick={() => setToggle()} />
          <div className="px-5 flex-1">
            <h3 className="text-xl pb-5 font-bold">{modalTitle}</h3>
            <div>
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, pristine, submitting }) => (
                  <form onSubmit={handleSubmit} className="bg-white">
                    <TextField
                      name="name"
                      label="Nombre de la etiqueta"
                      labelClassName="pb-2"
                      background="bg-blue-300"
                      margin="none"
                    />
                    <SaveButton
                      label="Crear etiqueta"
                      disabled={submitting || pristine}
                      className="!text-base !mt-7.5"
                    />
                  </form>
                )}
              />
            </div>
          </div>
          <Footer />
        </div>
      </Modal>
    </>
  )
}

ModalMobileLabelForm.propTypes = {
  toggle: PropTypes.bool,
  setToggle: PropTypes.func,
  modalTitle: PropTypes.string
}
export default ModalMobileLabelForm
