import React from 'react'

import PropTypes from 'prop-types'

import HeadingTag from './HeadingTag'
import { HorizontalLinearStepper } from './HorizontalLinearStepper'

const FormsHeader = ({ title, steps = {}, activeStep = null }) => {
  const activeStepIndex = Object.values(steps).findIndex(step => step === activeStep)
  const _steps = Object.values(steps).map(step => step.label)
  return (
    <div className="md:text-center">
      <HeadingTag title={title} className="text-3xl md:text-4xl font-bold pb-7.5 md:pb-9" />
      {steps && activeStep && (
        <>
          <div className="mx-auto max-w-md pb-10">
            <HorizontalLinearStepper steps={_steps} activeStep={activeStepIndex} />
          </div>
        </>
      )}
    </div>
  )
}

FormsHeader.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.object,
  activeStep: PropTypes.object
}

export default FormsHeader
