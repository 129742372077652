import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../../api/apiCore'
import { useAuth } from '../../../auth/Auth'
import { Container } from '../../../components/Container'
import { Button } from '../../../components/fields/Button'
import LabourHourForm from '../../../components/forms/admin/LabourHourForm'
import Breadcrumb from '../../../components/ui/Breadcrumb'
import FormsHeader from '../../../components/ui/FormsHeader'
import { moduleManageSteps } from '../../../configs/modules'
import {
  convertDataToBreadcrumbRoute,
  getItemFromLocalStorage,
  getJsonItemFromLocalStorage,
  removeItemFromLocalStorage
} from '../../../helpers/helpers'
import { useInstitutionData } from '../../../hooks/useInstitutions'

export const LabourHoursPage = () => {
  const { module_id } = useParams()
  const {
    admin: {
      institution_id: { value: institutionId }
    }
  } = useAuth()
  const { data: institution } = useInstitutionData(institutionId)
  const navigate = useNavigate()

  const isCreateMode = getItemFromLocalStorage('configType') === 'create'
  const serviceData = getJsonItemFromLocalStorage('serviceData')
  const configId = serviceData?.service_configuration_id
  const titlePage = `${isCreateMode ? 'Activar' : 'Editar'} módulo Notificaciones`
  const breadcrumbData = convertDataToBreadcrumbRoute(titlePage)

  const handleSubmit = async ({ labour_start_time }) => {
    try {
      const configResponse = await request({
        url: configId ? `/admin/service_configurations/${configId}.json` : '/admin/service_configurations',
        data: serviceData,
        method: configId ? 'PATCH' : 'POST'
      })

      if (!configResponse.id)
        return {
          [FORM_ERROR]: `Ocurrió un error al ${isCreateMode ? 'crear' : 'editar'} la configuración del servicio`
        }

      const institutionResponse = await request({
        url: `/admin/institutions/${institutionId}`,
        data: { labour_start_time },
        method: 'PATCH'
      })

      if (!institutionResponse.id)
        return {
          [FORM_ERROR]: 'Ocurrió un error al establecer el horario de la institución'
        }

      if (isCreateMode) {
        const modulePatch = await request({
          url: `/admin/institution_modules/${module_id}.json`,
          data: { active: true },
          method: 'PATCH'
        })

        if (!modulePatch.id) return { [FORM_ERROR]: 'Ocurrió un error al activar el módulo' }
      }

      removeItemFromLocalStorage(['serviceData', 'configType'])
      navigate('/admin/modulos')
    } catch (error) {
      return { [FORM_ERROR]: `Ocurrió un error: ${error}` }
    }
  }

  return (
    <>
      <Breadcrumb routesKey="modulesManagement" data={breadcrumbData} />
      <Container className="px-5 pt-8 pb-14 md:px-20 md:pt-14 md:pb-18">
        <FormsHeader title={titlePage} steps={moduleManageSteps} activeStep={moduleManageSteps.SET_HOURS_INFO} />
        <LabourHourForm
          title={moduleManageSteps.SET_HOURS_INFO.label}
          subtitle={moduleManageSteps.SET_HOURS_INFO.hint}
          handleSubmitForm={handleSubmit}
          initialValues={institution || { labour_start_time: '' }}
        />
        <section className="flex gap-5 mx-auto max-w-xl mt-10">
          <Button size="full" variant="secondary" onClick={() => navigate(`/admin/modulos/${module_id}/servicio`)}>
            Regresar
          </Button>
          <Button type="submit" form="labourHourForm" size="full">
            Guardar
          </Button>
        </section>
      </Container>
    </>
  )
}
