export const moduleManageSteps = {
  SET_WS_INFO: {
    label: 'Información sobre el web service',
    hint: 'Ingresa las credenciales para la conexión con el servició web de tu entidad. Esta información es necesaria para permitir la descarga de adjuntos de las notificaciones'
  },
  SET_HOURS_INFO: {
    label: 'Horario de atención',
    hint: 'Al seleccionar el horario de inicio se marcará automáticamente la hora de salida, añadiendo 9 horas a la hora de inicio. Este calculo toma en cuenta las 8 horas de trabajo más una hora adicional de almuerzo'
  }
}
