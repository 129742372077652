import React from 'react'

import PropTypes from 'prop-types'

const LoginHeader = ({ title, subtitle, className }) => (
  <section className={className}>
    <h1 className="text-3xl md:text-4xl font-bold">{title}</h1>
    <p className="pt-4 md:pt-5">{subtitle}</p>
  </section>
)

LoginHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string
}

export default LoginHeader
