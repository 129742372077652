import React, { useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'

import { Fade, Popper } from '@material-ui/core'
import PropTypes from 'prop-types'
import ArrowPopper from '~/assets/images/arrow_popper.svg'

import { usePatchMessage } from '../../hooks/useMessages'
import { HighlightAction } from './HighlightAction'
import { LabelAction } from './LabelAction'
import { LabelAs } from './LabelAs'
import { MessagesActionsMobile } from './MessagesActionsMobile'

export const MessagesActions = ({ messageId, className, highlight, labelId }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const arrowRef = useRef(null)
  const [openPopper, setOpenPopper] = useState(false)
  const { mutate: patchMessage } = usePatchMessage()

  const handleHighlight = () => {
    patchMessage({ id: messageId, highlight: !highlight })
  }

  const handleLabel = event => {
    if (labelId) {
      patchMessage({ id: messageId, label_id: null })
    } else {
      setAnchorEl(event.currentTarget)
      setOpenPopper(!openPopper)
    }
  }

  return (
    <>
      <div className={className}>
        <HighlightAction onHighlight={highlight} onHighlightClick={handleHighlight} />
        <LabelAction selectedLabelId={labelId} onLabelClick={handleLabel} />
        <Popper
          open={openPopper}
          anchorEl={anchorEl}
          transition
          placement="bottom-end"
          modifiers={{
            arrow: { enabled: true, element: arrowRef.current },
            offset: { enabled: true, offset: '12px, 0' }
          }}
        >
          <ReactSVG src={ArrowPopper} ref={arrowRef} className="relative flex justify-end right-5" />
          <Fade timeout={350}>
            <LabelAs messageId={messageId} setOpenPopper={setOpenPopper} />
          </Fade>
        </Popper>
      </div>
      <MessagesActionsMobile
        className="flex justify-between gap-2 md:hidden mt-5 md:mt-0"
        highlight={highlight}
        messageId={messageId}
        labelId={labelId}
      />
    </>
  )
}

MessagesActions.propTypes = {
  messageId: PropTypes.string,
  className: PropTypes.string,
  highlight: PropTypes.bool,
  labelId: PropTypes.number
}
