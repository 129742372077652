import React from 'react'
import { ReactSVG } from 'react-svg'

import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ToggleIcon from 'material-ui-toggle-icon'
import PropTypes from 'prop-types'

import StarBorderIcon from '../../assets/images/star_border_icon.svg'
import StarIcon from '../../assets/images/star_icon.svg'
import { TooltipActions } from './TooltipActions'

const useStyles = makeStyles({
  iconButton: {
    color: '#26292E',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#F2F5F8'
    },
    '&:focus': {
      backgroundColor: '#FFFFFF'
    }
  }
})
export const HighlightAction = ({ onHighlight, onHighlightClick }) => {
  const classes = useStyles()

  return (
    <TooltipActions text="Destacar" placement="bottom">
      <IconButton disableRipple classes={{ root: classes.iconButton }} onClick={onHighlightClick}>
        <ToggleIcon on={onHighlight} onIcon={<ReactSVG src={StarIcon} />} offIcon={<ReactSVG src={StarBorderIcon} />} />
      </IconButton>
    </TooltipActions>
  )
}

HighlightAction.propTypes = {
  onHighlight: PropTypes.bool,
  onHighlightClick: PropTypes.func
}
