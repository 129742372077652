import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { LABEL_TYPES } from '../../constants/globals'

const Tag = ({ type, description, className }) => (
  <div
    className={clsx(
      'px-1.5 py-0.75 rounded inline-block font-medium text-3.5 leading-4 text-white',
      LABEL_TYPES[type],
      className
    )}
  >
    {description}
  </div>
)

Tag.propTypes = {
  type: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string
}

export default Tag
