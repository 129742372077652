import React from 'react'
import { ReactSVG } from 'react-svg'

import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import esLocale from 'date-fns/locale/es'
import PropTypes from 'prop-types'

import CalendarIcon from '../../assets/images/calendar_icon.svg'
import { useCustomField } from '../../hooks/useCustomField'
import { fieldWidthSizes, marginSizes } from '../../utils/sizes'
import DateFieldTextField from './DateFieldTextField'
import DateFieldToolbar from './DateFieldToolbar'
import Error from './Error'

const useStyles = makeStyles({
  keyboardButtonRoot: {
    padding: 0
  },
  datePickerInput: {
    '& input::-webkit-input-placeholder': {
      opacity: 1,
      color: '#26292E'
    },
    '& input::-moz-placeholder': {
      opacity: 1,
      color: '#26292E'
    },
    '& input:-ms-input-placeholder': {
      opacity: 1,
      color: '#26292E'
    },
    '& input::placeholder': {
      opacity: 1,
      color: '#26292E'
    }
  }
})

const DateField = ({
  label,
  name,
  placeholder,
  validate,
  containerClassName = '',
  margin = 'normal',
  size = 'xl',
  hint,
  ...props
}) => {
  const { input, gotError } = useCustomField(name, { validate })
  const classes = useStyles()

  return (
    <>
      {label && <span className="block font-bold">{label}</span>}
      {hint && (
        <p id={`${name}-hint`} className="break-words mb-2">
          {hint}
        </p>
      )}
      <div
        className={clsx(
          'bg-white relative js-field-container border-gray-800 border-2',
          fieldWidthSizes[size],
          containerClassName,
          marginSizes[margin],
          gotError ? 'focus-within:border-red-500 border-red-500' : 'focus-within:border-yellow-500'
        )}
      >
        <label>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              {...input}
              autoOk
              fullWidth
              placeholder={placeholder}
              variant="inline"
              format="dd/MM/yyyy"
              value={input.value || null}
              InputProps={{
                disableUnderline: true,
                className: 'pr-4 pl-5 py-1.5',
                classes: { root: classes.datePickerInput }
              }}
              KeyboardButtonProps={{ classes: { root: classes.keyboardButtonRoot } }}
              keyboardIcon={<ReactSVG src={CalendarIcon} />}
              ToolbarComponent={DateFieldToolbar}
              TextFieldComponent={DateFieldTextField}
              onChange={input.onChange}
              invalidDateMessage=""
              maxDateMessage=""
              minDateMessage=""
              {...props}
            />
          </MuiPickersUtilsProvider>
        </label>
        <Error name={name} className="absolute -bottom-6" />
      </div>
    </>
  )
}

DateField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  containerClassName: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.string,
  hint: PropTypes.string
}

export default DateField
