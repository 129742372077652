import React, { useContext, useState } from 'react'

import { Modal } from '@material-ui/core'
import PropTypes from 'prop-types'
import { LabelButtonMobile } from '~/components/notifications/LabelButtonMobile'
import LabelList from '~/components/notifications/LabelList'
import BackButton from '~/components/ui/BackButton'
import DialogTitle from '~/components/ui/DialogTitle'
import { AlertContext } from '~/utils/context'

import { usePatchMessage } from '../../hooks/useMessages'
import { Button } from '../fields/Button'
import { Header } from '../ui/Header'

export const ModalMobileLabel = ({ messageId, toggle, setToggle, modalTitle }) => {
  const [label, setLabel] = useState(null)
  const { setAlertMessageMobile } = useContext(AlertContext)

  const { mutate: patchMessage } = usePatchMessage()

  const handleSave = () => {
    patchMessage({ id: messageId, label_id: label.id })
    setToggle()
    setAlertMessageMobile(`Se asignó la etiqueta <span class="font-bold">${label.name}.</span>`)
  }

  return (
    <Modal open={toggle} onClose={() => setToggle()}>
      <div className="bg-white flex flex-col h-full">
        <Header />
        <BackButton onClick={() => setToggle()} />
        <div className="py-1 px-5 flex-1 overflow-y-auto">
          <DialogTitle title={modalTitle} />
          <div className="pt-6">
            <div className="flex items-center justify-between mb-3">
              <h3 className="font-bold">Etiquetas</h3>
              <LabelButtonMobile />
            </div>
            <div className="mb-4">Selecciona una etiqueta:</div>
            <LabelList onSelect={label => setLabel(label)} />
          </div>
        </div>
        <div className="p-5 shadow-button-mobile w-full">
          <Button onClick={() => handleSave()} size="full">
            Guardar
          </Button>
        </div>
      </div>
    </Modal>
  )
}

ModalMobileLabel.propTypes = {
  messageId: PropTypes.string,
  toggle: PropTypes.bool,
  setToggle: PropTypes.func,
  modalTitle: PropTypes.string
}
