import React from 'react'

import PropTypes from 'prop-types'

export const MessageActionButton = ({ buttonClassName, textClassName, onButtonClick, children, text }) => {
  return (
    <button type="button" className={`flex justify-center items-center ${buttonClassName}`} onClick={onButtonClick}>
      {children}
      <p className={textClassName}>{text}</p>
    </button>
  )
}

MessageActionButton.propTypes = {
  buttonClassName: PropTypes.string,
  textClassName: PropTypes.string,
  onButtonClick: PropTypes.func,
  children: PropTypes.element,
  text: PropTypes.string
}
