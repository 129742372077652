import React, { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import BookmarkBorderIcon from '../../assets/images/bookmark_border_icon.svg'
import BookmarkIcon from '../../assets/images/bookmark_icon.svg'
import IconButton from '../../components/ui/IconButton'
import SearchMini from '../../components/ui/SearchMini'
import { useLabelsData } from '../../hooks/useLabels'
import useTimeout from '../../hooks/useTimeout'

const LabelList = ({ labelId, className, onSelect, withSearcher = false, onEdit, onDelete }) => {
  const [value, setValue] = useState(labelId)
  const [query, setQuery] = useState(null)
  const [results, setResults] = useState([])
  const { data: labels, status } = useLabelsData()
  const { inTime } = useTimeout([labels])

  useEffect(() => {
    setValue({ id: labelId })
  }, [labelId])

  const handleClick = label => {
    setValue(label)
    onSelect(label)
  }

  useEffect(() => {
    const results = labels?.data.filter(label => !query || label.name.toLowerCase().includes(query.toLowerCase()))
    setResults(results)
  }, [query, labels])

  if (status !== 'success') return null

  return (
    <>
      {withSearcher && (
        <div className="p-2">
          <SearchMini onChange={e => setQuery(e.target.value)} />
        </div>
      )}
      <ul className={className}>
        {results?.map((label, index) => (
          <li
            key={label.id}
            className={clsx(
              'group flex gap-2.5 items-center cursor-pointer px-4 active:bg-blue-40',
              onEdit || onDelete ? 'py-1.5' : 'py-3',
              label.new && inTime && index === 0 && 'border border-green-600 rounded',
              value?.id?.toString() === label.id.toString() ? 'bg-blue-40' : 'hover:bg-gray-30'
            )}
            onClick={() => handleClick(label)}
          >
            <ReactSVG src={value?.id?.toString() === label.id.toString() ? BookmarkIcon : BookmarkBorderIcon} />
            <div className="flex-1 truncate">{label.name}</div>
            {onEdit && (
              <IconButton
                text="Editar"
                className="invisible md:group-hover:visible relative left-1"
                onClick={e => {
                  e.stopPropagation()
                  onEdit(label)
                }}
              >
                <EditIcon />
              </IconButton>
            )}
            {onDelete && (
              <IconButton
                text="Eliminar"
                className="invisible md:group-hover:visible"
                onClick={e => {
                  e.stopPropagation()
                  onDelete(label)
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </li>
        ))}
      </ul>
    </>
  )
}

LabelList.propTypes = {
  labelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  onSelect: PropTypes.func,
  withSearcher: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
}

export default LabelList
