import React from 'react'
import { Form } from 'react-final-form'
import TextField from '../../../fields/TextField'
import { Button } from '../../../fields/Button'
import * as validations from '../../../fields/Validations'
import PropTypes from 'prop-types'

export const CategoryForm = ({ initialValues = {}, onSubmit, create }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      autoComplete="off"
      subscription={{ submitting: true, submitError: true }}
    >
      {({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="py-4 md:py-8">
            <TextField
              label="Nombre de la categoría"
              name="name"
              validate={validations.mix(validations.required(), validations.isLetter())}
              labelClassName="mb-4 font-bold leading-none"
              margin="mb-0"
            />
            <TextField
              label="Descripción de la categoría"
              name="description"
              validate={validations.mix(validations.required())}
              labelClassName="mt-4 mb-4 font-bold leading-none"
              margin="mb-0"
            />
          </div>
          <div>
            <Button
              disabled={submitting}
              size="full"
              type="submit"
              className="block mt-12 md:mt-6 text-lg py-3 md:max-w-56"
            >
              {create ? 'Crear Categoría' : 'Guardar cambios'}
            </Button>
          </div>
          {submitError && <div className="text-red-700 font-bold text-center">{submitError}</div>}
        </form>
      )}
    </Form>
  )
}
CategoryForm.propTypes = {
  initialValues: PropTypes.object,
  create: PropTypes.bool,
  onSubmit: PropTypes.func
}
