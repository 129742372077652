import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Button } from '../fields/Button'
import Tag from './Tag'

const tagConfig = {
  active: {
    type: 'success',
    description: 'Activo'
  },
  inactive: {
    type: 'disabled',
    description: 'Inactivo'
  }
}

const CardConfig = ({ cardClassName, title, labelType, infoText, buttonText, handleConfigButton }) => (
  <div className={clsx('border-b-0.75 border-gray-850 p-8 max-w-90 shadow-card-login', cardClassName)}>
    <div className="flex justify-between items-center">
      <div className="font-bold text-xl md:text-2xl">{title}</div>
      <Tag {...tagConfig[labelType]} />
    </div>
    <div className="pt-4 pb-7.5">{infoText}</div>
    <div className="text-right">
      <Button variant="secondary" size="full" onClick={handleConfigButton}>
        {buttonText}
      </Button>
    </div>
  </div>
)

CardConfig.propTypes = {
  cardClassName: PropTypes.string,
  title: PropTypes.string,
  labelType: PropTypes.string,
  infoText: PropTypes.string,
  buttonText: PropTypes.string,
  handleConfigButton: PropTypes.func
}

export default CardConfig
