import React from 'react'

import PropTypes from 'prop-types'

import LoaderIcon from '../../assets/images/loader_icon.svg'

const Loader = ({ message }) => (
  <section className="flex justify-center	items-center h-screen">
    <article className="text-center max-w-xl">
      <p className="font-bold text-3xl md:text-4xl">{message}</p>
      <img className="mx-auto animate-spin mt-1 max-w-32 md:max-w-48" src={LoaderIcon} />
    </article>
  </section>
)

Loader.propTypes = {
  message: PropTypes.string
}

export default Loader
