import { useMutation, useQuery, useQueryClient } from 'react-query'

import { request } from '../api/apiCore'

const fetchLabels = () => request({ url: '/labels.json' })

export const useLabelsData = () => useQuery('labels', fetchLabels)

export const addLabel = label => request({ url: '/labels', method: 'POST', data: label })

export const useAddLabel = () => {
  const queryClient = useQueryClient()

  return useMutation(addLabel, {
    onSuccess: data => {
      queryClient.setQueryData('labels', oldQueryData => {
        return {
          data: [...oldQueryData.data, data]
        }
      })
    }
  })
}

export const destroyLabel = async id => await request({ url: `/labels/${id}`, method: 'DELETE' })

export const updateLabel = async (id, data) => await request({ url: `/labels/${id}`, data, method: 'PUT' })
