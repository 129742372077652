import React, { useContext } from 'react'
import { useQueryClient } from 'react-query'

import PropTypes from 'prop-types'

import LabelFormDialog from '../../components/notifications/LabelFormDialog'
import { UNPROCESSABLE_ENTITY } from '../../constants/globals'
import { getAxiosErrorMessages } from '../../helpers/helpers'
import { updateLabel } from '../../hooks/useLabels'
import { AlertContext } from '../../utils/context'

const LabelEditDialog = ({ label, onClose }) => {
  const { setAlertMessage } = useContext(AlertContext)
  const queryClient = useQueryClient()

  const handleSubmit = async values => {
    const response = await updateLabel(label.id, values)

    if (response.response?.status === UNPROCESSABLE_ENTITY) return getAxiosErrorMessages(response)

    const newLabel = { ...response, new: true }
    queryClient.setQueryData('labels', oldQueryData => {
      const data = oldQueryData.data.filter(row => row.id !== label.id)
      return {
        data: [newLabel, ...data]
      }
    })

    setAlertMessage(`
        Se cambió el nombre de la etiqueta <span class="font-bold">${label.name}</span> 
        por <span class="font-bold">${values.name}</span>
    `)

    onClose()
  }
  return (
    <LabelFormDialog
      title="Editar etiqueta"
      open={true}
      onClose={onClose}
      onSubmit={handleSubmit}
      initialValues={label}
      labelButtonSave="Guardar"
    />
  )
}

LabelEditDialog.propTypes = {
  label: PropTypes.object,
  onClose: PropTypes.func
}

export default LabelEditDialog
