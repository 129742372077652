import React from 'react'
import { useParams } from 'react-router-dom'

import { Container } from '../../components/Container'
import MessageContent from '../../components/admin/MessageContent'
import Breadcrumb from '../../components/ui/Breadcrumb'
import { convertDataToBreadcrumbRoute } from '../../helpers/helpers'
import { useMessageData } from '../../hooks/useMessages'

export const MessageDetailPage = () => {
  let { noti_id: notificationId } = useParams()
  const { isLoading, data: message } = useMessageData(notificationId, 'admin/messages')
  const breadcrumbData = convertDataToBreadcrumbRoute(message?.subject)

  return (
    <>
      <Breadcrumb routesKey="notifications" data={breadcrumbData} />
      <Container>
        {isLoading && <div className="flex justify-center font-bold m-8 md:m-10">Cargando...</div>}
        {!isLoading && <MessageContent message={message} />}
      </Container>
    </>
  )
}
