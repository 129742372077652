import React from 'react'

import PropTypes from 'prop-types'

export const MessageContentBody = ({ messageDescription }) => (
  <article
    className="py-8 md:py-9 my-8 md:my-9 border-y-0.4 border-white hint-html-injected"
    dangerouslySetInnerHTML={{ __html: messageDescription }}
  />
)

MessageContentBody.propTypes = {
  messageDescription: PropTypes.string
}
