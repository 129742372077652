import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { ReactSVG } from 'react-svg'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import LabelFormDialog from '~/components/notifications/LabelFormDialog'
import { UNPROCESSABLE_ENTITY } from '~/constants/globals'
import { getAxiosErrorMessages } from '~/helpers/helpers'

import AddIcon from '../../assets/images/add_icon.svg'
import { addLabel } from '../../hooks/useLabels'

export const LabelButton = ({ parentClassName, buttonClassName, onCreated }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const queryClient = useQueryClient()
  const onSubmitDialog = async values => {
    const response = await addLabel(values)
    if (response.response?.status === UNPROCESSABLE_ENTITY) {
      return getAxiosErrorMessages(response)
    }
    const newLabel = { ...response, new: true }
    queryClient.setQueryData('labels', oldQueryData => {
      return {
        data: [newLabel, ...oldQueryData.data]
      }
    })
    setOpenDialog(false)
    onCreated && onCreated(newLabel)
  }

  return (
    <div className={parentClassName}>
      <button type="button" className={clsx('flex', buttonClassName)} onClick={() => setOpenDialog(true)}>
        <ReactSVG src={AddIcon} />
        <div className="ml-2.5 font-medium">Nueva etiqueta</div>
      </button>
      <LabelFormDialog
        title="Nueva etiqueta"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        labelButtonSave="Crear etiqueta"
        onSubmit={onSubmitDialog}
      />
    </div>
  )
}

LabelButton.propTypes = {
  parentClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  onCreated: PropTypes.func
}
