import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import MessageReviewContent from './MessageReviewContent'

const MessageReviewSection = ({
  title,
  linkRoute,
  sectionData,
  parentClassName,
  headingClassName,
  titleClassName,
  contentClassName,
  showEditButton = false
}) => (
  <section className={parentClassName}>
    <article className={`flex justify-between items-center ${headingClassName}`}>
      <p className={titleClassName}>{title}</p>
      {showEditButton && (
        <Link to={linkRoute} className="text-blue-700 font-medium underline">
          Editar
        </Link>
      )}
    </article>
    {sectionData.length ? (
      sectionData.map((data, index) => (
        <MessageReviewContent
          key={index}
          data={data}
          classNameRoot={`${contentClassName} ${index > 0 ? 'pt-2.5' : ''}`}
        />
      ))
    ) : (
      <span className="font-medium">No se encontró información</span>
    )}
  </section>
)

MessageReviewSection.propTypes = {
  title: PropTypes.string,
  linkRoute: PropTypes.string,
  sectionData: PropTypes.array,
  parentClassName: PropTypes.string,
  headingClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  showEditButton: PropTypes.bool
}

export default MessageReviewSection
