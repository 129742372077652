import React, { Fragment, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'

import { request } from '../../../api/apiCore'
import { AREAS_USERS } from '../../../constants/globals'
import { resultsToOptions } from '../../../helpers/helpers'
import { useAppModulesData } from '../../../hooks/useAppModules'
import { Button } from '../../fields/Button'
import CheckboxField from '../../fields/CheckboxField'
import MultipleSelectField from '../../fields/MultipleSelectField'
import TextField from '../../fields/TextField'
import * as validations from '../../fields/Validations'
import Value from '../../fields/Value'
import WhenFieldChanges from '../../fields/WhenFieldChanges'

const ItemCheckboxField = (module, checkedState, changeCheckboxStatus) => (
  <CheckboxField
    key={module.id}
    className="bg-grgroup_namey-40 p-2"
    formControlClassName="w-full"
    name="modules"
    label={module.name}
    value={module.id}
    margin="none"
    checked={checkedState?.includes(module.id)}
    onClick={() => changeCheckboxStatus(module.id)}
  />
)

export const RolesForm = ({ initialValues = {}, onSubmit, create }) => {
  const { data: appModules } = initialValues ? useAppModulesData() : []
  const [checkedState, setCheckedState] = useState(initialValues?.modules_allowed_id || [])
  const navigate = useNavigate()
  const hisRole = create ? [{ id: 'his_role', name: 'Su mismo rol' }] : []
  const loadOptionsMultiple = async () => {
    const { data: roles } = await request({ url: '/admin/roles.json' })
    return resultsToOptions(hisRole.concat(roles) || [], ['id', 'name'])
  }
  const handleReturn = () => navigate('/admin/roles-y-ciudadanos')
  const idAreasUsers = appModules?.data.find(module => module.name_value === AREAS_USERS)?.id

  useEffect(() => {
    if (create || !initialValues) return
    setCheckedState(initialValues?.modules_allowed_id)
  }, [initialValues])

  const changeCheckboxStatus = module_id => {
    setCheckedState(
      checkedState?.includes(module_id)
        ? checkedState?.filter(item => item !== module_id)
        : checkedState.concat(module_id)
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{ submitting: true, submitError: true }}
      render={({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit} className="flex flex-col h-full">
          <div className="flex-1 md:overflow-y-auto">
            <div className="py-6 md:py-8 px-6 md:pl-8 bg-neutral-100">
              <TextField
                label="Nombre del rol"
                name="name"
                validate={validations.mix(validations.required(), validations.isLetter())}
              />
              <div className="font-semibold pb-3.5">Módulos a los que tiene acceso</div>
              <div className="font-semibold">Módulos activos</div>
              <div className="p-2">
                {appModules?.data.map(
                  module =>
                    module.group_name === 'active_modules' &&
                    ItemCheckboxField(module, checkedState, changeCheckboxStatus)
                )}
              </div>
              <div className="font-semibold">Configuración</div>
              <div className="p-2">
                {appModules?.data.map(
                  module =>
                    module.group_name === 'configuration_carpeta' &&
                    ItemCheckboxField(module, checkedState, changeCheckboxStatus)
                )}
              </div>
            </div>
            <div className="py-6 md:py-8 px-6 md:pl-8 bg-neutral-200">
              <Fragment>
                <WhenFieldChanges
                  field="modules"
                  set="roles_allowed_create"
                  to={null}
                  multipleChoiceValue={idAreasUsers}
                />
                <Value name="modules">
                  {({ value }) =>
                    value.includes(idAreasUsers) && (
                      <Fragment>
                        <MultipleSelectField
                          label="¿Que roles puedes crear?"
                          loadOptions={loadOptionsMultiple}
                          name="roles_allowed_create"
                          size="full"
                        />
                      </Fragment>
                    )
                  }
                </Value>
              </Fragment>
            </div>
            {submitError && <div className="text-red-700 font-bold pt-4">{submitError}</div>}
            <div className="pt-8">
              <Button
                disabled={submitting}
                size="full"
                type="submit"
                className="block mt-12 md:mt-6 text-lg py-3 md:max-w-56"
              >
                {create ? 'Crear Rol' : 'Guardar cambios'}
              </Button>
            </div>
            <Button variant="text" onClick={handleReturn} className="!pt-4">
              Cancelar
            </Button>
          </div>
        </form>
      )}
    />
  )
}
RolesForm.propTypes = {
  initialValues: PropTypes.object,
  create: PropTypes.bool,
  onSubmit: PropTypes.func
}
