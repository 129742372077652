import React from 'react'
import { ReactSVG } from 'react-svg'

import clsx from 'clsx'
import ToggleIcon from 'material-ui-toggle-icon'
import PropTypes from 'prop-types'

import BookmarkBorderIcon from '../../assets/images/bookmark_border_mobile_icon.svg'
import BookmarkIcon from '../../assets/images/bookmark_mobile_icon.svg'
import StarBorderIcon from '../../assets/images/star_border_mobile_icon.svg'
import StarIcon from '../../assets/images/star_mobile_icon.svg'
import { usePatchMessage } from '../../hooks/useMessages'
import useToggle from '../../hooks/useToogle'
import { MessageActionButton } from './MessageActionButton'
import { ModalMobileLabel } from './ModalMobileLabel'

export const MessagesActionsMobile = ({ messageId, className, highlight, labelId }) => {
  const [toggleFilter, setToggleFilter] = useToggle(false)
  const buttonClassName =
    'w-1/2 p-1.5 pl-1.5 pr-3.25 rounded border-2 border-blue-700 text-blue-700 bg-gray-30 active:bg-blue-400'

  const { mutate: patchMessage } = usePatchMessage()

  const handleHighlight = () => {
    patchMessage({ id: messageId, highlight: !highlight })
  }

  const handleLabel = () => {
    if (labelId) {
      patchMessage({ id: messageId, label_id: null })
    } else {
      setToggleFilter()
    }
  }

  return (
    <div className={className}>
      <MessageActionButton
        buttonClassName={clsx(buttonClassName, { '!bg-white': highlight })}
        textClassName="font-bold"
        onButtonClick={handleHighlight}
        text="Destacar"
      >
        <ToggleIcon on={highlight} onIcon={<ReactSVG src={StarIcon} />} offIcon={<ReactSVG src={StarBorderIcon} />} />
      </MessageActionButton>
      <MessageActionButton
        buttonClassName={clsx(buttonClassName, { '!bg-white': labelId })}
        textClassName="font-bold"
        onButtonClick={handleLabel}
        text="Etiquetar"
      >
        <ToggleIcon
          on={labelId !== null}
          onIcon={<ReactSVG src={BookmarkIcon} />}
          offIcon={<ReactSVG src={BookmarkBorderIcon} />}
        />
      </MessageActionButton>
      <ModalMobileLabel
        messageId={messageId}
        toggle={toggleFilter}
        setToggle={setToggleFilter}
        modalTitle="Etiquetar como:"
      />
    </div>
  )
}

MessagesActionsMobile.propTypes = {
  messageId: PropTypes.string,
  className: PropTypes.string,
  highlight: PropTypes.bool,
  labelId: PropTypes.number
}
