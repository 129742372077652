import React from 'react'
import { Form } from 'react-final-form'

import PropTypes from 'prop-types'

import { CONTENT_TYPES, OAUTH_GRANT_TYPES } from '../../../constants/globals'
import { Button } from '../../fields/Button'
import RadioField from '../../fields/RadioField'
import SaveButton from '../../fields/SaveButton'
import TextField from '../../fields/TextField'
import * as validations from '../../fields/Validations'

const contentTypesOptions = [
  { label: CONTENT_TYPES[0], value: CONTENT_TYPES[0] },
  { label: CONTENT_TYPES[1], value: CONTENT_TYPES[1] }
]

const grantTypesOptions = [
  { label: OAUTH_GRANT_TYPES[0], value: OAUTH_GRANT_TYPES[0] },
  { label: OAUTH_GRANT_TYPES[1], value: OAUTH_GRANT_TYPES[1] }
]

const CurlPreview = ({
  auth_url = '',
  client_id = '',
  client_secret = '',
  content_type = '',
  grant_type = '',
  username = '',
  password = '',
  authorization = ''
}) => {
  const request = `--request POST '${auth_url}'`
  const contentTypeHeader = `--header 'Content-Type: ${content_type}'`
  const contentTypeAuth = authorization ? `--header 'Authorization: ${authorization}'` : ''
  const grantType =
    content_type === CONTENT_TYPES[1] ? `--data-urlencode 'grant_type=${grant_type}'` : `"grant_type"="${grant_type}"`
  const params =
    content_type === CONTENT_TYPES[1]
      ? grant_type === OAUTH_GRANT_TYPES[0]
        ? `
  --data-urlencode 'client_id=${client_id}' \
  --data-urlencode 'client_secret=${client_secret}' `
        : `
  --data-urlencode 'username=${username}' \
  --data-urlencode 'password=${password}' `
      : grant_type === OAUTH_GRANT_TYPES[0]
      ? `"client_id": "${client_id}", "client_secret": "${client_secret}"`
      : `"username": "${username}", "password": "${password}"`

  const data = content_type === CONTENT_TYPES[1] ? `${grantType} ${params}` : `--data-raw '{${grantType} ${params}}'`

  const content = `
    curl --location ${request} \
    ${contentTypeHeader}
    ${contentTypeAuth}
    ${data}
  `
  return (
    <section className="mb-6">
      <p>Lo siguiente es un ejemplo con cURL de la petición:</p>
      <div className="p-4 my-2 bg-gray-850  rounded-md text-green-400 break-words">{content}</div>
    </section>
  )
}

const OAuthForm = ({
  title,
  subtitle,
  isVerifiedService,
  setIsVerifiedService,
  firstInputOptions,
  secondInputOptions,
  initialValues,
  handleFormSubmit,
  handleGrantType
}) => (
  <Form
    onSubmit={handleFormSubmit}
    initialValues={initialValues}
    subscription={{ submitError: true, submitSucceeded: true, submitting: true, values: true }}
    render={({ handleSubmit, submitError, submitSucceeded, submitting, form, values }) => (
      <form
        id="serviceForm"
        onSubmit={handleSubmit}
        className="p-6 md:p-9 border border-blue-200 shadow-auth-register m-auto max-w-xl"
      >
        <h2 className="md:text-center pb-2 md:pb-3 font-bold text-xl md:text-2xl">{title}</h2>
        <p className="mb-6">{subtitle}</p>
        <TextField
          label="Ruta para autenticar"
          name="auth_url"
          size="full"
          rows={3}
          element="textarea"
          className="!h-full"
          disabled={isVerifiedService}
          validate={validations.mix(validations.isURL(), validations.required())}
        />
        <TextField
          label="Authorization"
          name="authorization"
          size="full"
          rows={3}
          element="textarea"
          className="!h-full"
          disabled={isVerifiedService}
        />
        <RadioField
          label="Content-type"
          name="content_type"
          validate={validations.required()}
          options={contentTypesOptions}
        />
        <RadioField
          label="Grant type"
          name="grant_type"
          validate={validations.required()}
          options={grantTypesOptions}
          onChange={handleGrantType}
        />
        <TextField
          label={firstInputOptions.label}
          name={firstInputOptions.name}
          size="full"
          rows={3}
          element="textarea"
          className="!h-full"
          disabled={isVerifiedService}
          validate={validations.required()}
        />
        <TextField
          label={secondInputOptions.label}
          name={secondInputOptions.name}
          size="full"
          rows={3}
          element="textarea"
          className="!h-full"
          disabled={isVerifiedService}
          validate={validations.required()}
        />
        <CurlPreview {...values} />
        {submitError && <div className="text-red-500 font-bold text-center pb-6">{submitError}</div>}
        {isVerifiedService && submitSucceeded && (
          <div className="text-green-500 font-bold text-center pb-6">Servicio correcto</div>
        )}
        {isVerifiedService ? (
          <Button
            size="full"
            variant="secondary"
            onClick={() => {
              form.restart()
              setIsVerifiedService(!isVerifiedService)
            }}
          >
            Limpiar campos
          </Button>
        ) : (
          <SaveButton label="Verificar" size="full" disabled={submitting} />
        )}
      </form>
    )}
  />
)

CurlPreview.propTypes = {
  auth_url: PropTypes.string,
  client_id: PropTypes.string,
  client_secret: PropTypes.string,
  content_type: PropTypes.string,
  grant_type: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  authorization: PropTypes.string
}

OAuthForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isVerifiedService: PropTypes.bool,
  setIsVerifiedService: PropTypes.func,
  firstInputOptions: PropTypes.object,
  secondInputOptions: PropTypes.object,
  initialValues: PropTypes.object,
  handleFormSubmit: PropTypes.func,
  handleGrantType: PropTypes.func
}

export default OAuthForm
