import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import TrashIcon from '../../assets/images/trash_icon.svg'
import { receiptsTableHeaderCells } from '../../configs/messages'

const ReceiptsTable = ({ data = [], onDeleteReceipt }) => (
  <>
    <table className="receipts-table mt-12">
      <thead>
        <tr>
          {receiptsTableHeaderCells.map((item, index) => (
            <th key={index}>{item}</th>
          ))}
        </tr>
      </thead>
      {data.length > 0 && (
        <tbody>
          {data.map(({ id, citizen_id_code, citizen_id_number, last_names, first_names }, index) => (
            <Fragment key={id}>
              <tr>
                <td>{index + 1}</td>
                <td>{citizen_id_code}</td>
                <td>{citizen_id_number}</td>
                <td>{last_names}</td>
                <td>{first_names}</td>
                <td>
                  <button onClick={() => onDeleteReceipt(id)} className="flex items-center">
                    <img src={TrashIcon} alt="trash-icon" className="pr-2" />
                    <span className="text-blue-700 leading-5 font-medium underline">Eliminar</span>
                  </button>
                </td>
              </tr>
              <tr className="h-2 !bg-white !p-0"></tr>
            </Fragment>
          ))}
        </tbody>
      )}
    </table>
    {!data.length && (
      <p className="bg-gray-30 rounded text-center py-4 mt-4">Todavía no ingresaste ningún destinatario.</p>
    )}
  </>
)

ReceiptsTable.propTypes = {
  data: PropTypes.array,
  onDeleteReceipt: PropTypes.func
}

export default ReceiptsTable
