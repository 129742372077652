import React from 'react'

import PropTypes from 'prop-types'

import MessageAttachedFile from './MessageAttachedFile'

const titleByIndex = ['Documento principal', 'Documentos adicionales']

const DeleteSendItem = ({ handleDelete }) => (
  <button type="button" className="text-blue-700 font-medium absolute right-5 md:right-8 z-10" onClick={handleDelete}>
    Eliminar
  </button>
)

const DocumentSendItem = ({ name, item, type, handleDeleteItem }) => (
  <div className="mb-7.5 relative">
    {type === 'notification' && (
      <>
        {titleByIndex[item] && <h3 className="mb-6 font-bold">{titleByIndex[item]}</h3>}
        {item > 0 && <DeleteSendItem handleDelete={handleDeleteItem} />}
        <MessageAttachedFile fileName={`${name}.name`} filePathName={`${name}.route`} />
      </>
    )}
    {type === 'communication' && (
      <>
        <div className="flex justify-between mb-4">
          <span className="font-bold">Adjunto {item + 1}</span>
          <DeleteSendItem handleDelete={handleDeleteItem} />
        </div>
        <MessageAttachedFile fileName={`${name}.name`} filePathName={`${name}.route`} />
      </>
    )}
    {type === 'document' && (
      <>
        <h3 className="mb-2 font-bold">Enlace {item + 1}</h3>
        <DeleteSendItem handleDelete={handleDeleteItem} />
        <MessageAttachedFile
          labelFileName="Nombre"
          fileName={`${name}.name`}
          filePathName={`${name}.route`}
          labelFilePathName="Enlace"
        />
      </>
    )}
  </div>
)

DeleteSendItem.propTypes = {
  handleDelete: PropTypes.func
}

DocumentSendItem.propTypes = {
  name: PropTypes.string,
  item: PropTypes.number,
  type: PropTypes.string,
  handleDeleteItem: PropTypes.func
}

export default DocumentSendItem
