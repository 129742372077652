import React from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import clsx from 'clsx'
import pDebounce from 'p-debounce'
import PropTypes from 'prop-types'

import { dataToOptions } from '../../../helpers/helpers'
import { Button } from '../../fields/Button'
import SearchSelectField from '../../fields/SearchSelectField'
import TextField from '../../fields/TextField'
import * as validations from '../../fields/Validations'
import CopyText from '../../ui/CopyText'

const loadOptions = pDebounce(async (search, loadedOptions, { page }) => {
  if (search.length >= 3) {
    try {
      const { data: institutions } = await axios.get(`/api/v1/admin/institutions.json?search=${search}&page=${page}`)
      const options = dataToOptions(institutions || [], ['gobpe_id', 'name'])
      return {
        options: options,
        hasMore: institutions.data.length >= 1,
        additional: {
          page: page <= institutions.total_pages ? page + 1 : 2
        }
      }
    } catch (error) {
      console.log(error)
    }
  } else {
    return { options: [], hasMore: false }
  }
}, 500)

export const WebServiceForm = ({ initialValues = {}, onSubmit, verifyCredential }) => {
  const navigate = useNavigate()

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} autoComplete="off">
      {({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="py-2 sm:max-w-xs">
            <SearchSelectField
              label="Entidad"
              name="institution_gobpe_id"
              loadOptions={loadOptions}
              placeholder="Seleccione Entidad"
              className="!w-100"
              isDisabled={verifyCredential.status}
            />
          </div>

          <div className="py-4 md:py-8">
            <TextField
              label="Correo electrónico"
              name="email"
              validate={validations.mix(validations.isEmail(), validations.required())}
              labelClassName="mb-4 font-bold leading-none"
              margin="mb-0"
              disabled={verifyCredential.status}
            />
          </div>
          {submitError && <div className="text-red-700 font-bold mb-4">{submitError}</div>}
          {!verifyCredential.status && (
            <div>
              <Button
                disabled={submitting}
                size="full"
                type="submit"
                className="block mt-12 md:mt-6 text-lg py-3 md:max-w-56"
              >
                Generar Credenciales
              </Button>
            </div>
          )}

          {verifyCredential.status && (
            <div className="pt-8 w-2/4">
              <hr className="mb-5" />
              <p className="pt-4 md:pt-5 font-bold">Credenciales</p>
              <p className="pt-4 md:pt-5">Se generaron las siguientes credenciales para la entidad:</p>
              <CopyText
                className="mt-3 text-lg md:max-w-32"
                copy={
                  'client_id: ' +
                  verifyCredential.data.client_id +
                  ' client_secret: ' +
                  verifyCredential.data.client_secret
                }
                size="s"
                message="Credenciales copiadas"
              />
              <p className="py-4 my-4 border-2">
                client_id: {verifyCredential.data.client_id}
                <br></br>
                client_secret: {verifyCredential.data.client_secret}
              </p>
            </div>
          )}
          <Button
            variant="text"
            onClick={() => navigate('/admin/web-services')}
            className={clsx('block mt-12 text-lg py-3 md:max-w-32', !verifyCredential.status && '!mt-6')}
          >
            Regresar
          </Button>
        </form>
      )}
    </Form>
  )
}
WebServiceForm.propTypes = {
  initialValues: PropTypes.object,
  verifyCredential: PropTypes.object,
  onSubmit: PropTypes.func
}
