import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'

import EditIcon from '../../../assets/images/edit_icon.svg'
import TrashIcon from '../../../assets/images/trash_icon.svg'
import { urlSearchParamsData } from '../../../helpers/helpers'
import { Pagination } from '../../ui/Pagination'

const TableActions = ({ area, handleDelete }) => {
  const urlSearchParams = urlSearchParamsData()

  return (
    <>
      <IconButton
        component={Link}
        to={
          urlSearchParams.institution_gobpe_id
            ? `/admin/areas/edit/${area.id}?institution_gobpe_id=` + urlSearchParams.institution_gobpe_id
            : `/admin/areas/edit/${area.id}`
        }
        className="!p-0 hover:!bg-transparent"
        disableRipple
      >
        <img src={EditIcon} alt="edit-icon" />
      </IconButton>
      <IconButton
        type="button"
        className="!p-0 !ml-5 hover:!bg-transparent"
        disableRipple
        onClick={() => handleDelete(area)}
      >
        <img src={TrashIcon} alt="trash-icon" />
      </IconButton>
    </>
  )
}

const InternalAreasTable = ({ internalAreas = [], onDestroy, totalPages = 0 }) => (
  <>
    <table className="admin-table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th className="text-right">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {internalAreas.map(area => (
          <Fragment key={area.id}>
            <tr>
              <td>{area.name}</td>
              <td className="text-right">
                <TableActions area={area} handleDelete={onDestroy} />
              </td>
            </tr>
            <tr className="h-2"></tr>
          </Fragment>
        ))}
      </tbody>
    </table>
    <Pagination numPages={Math.ceil(totalPages)} className="mt-4" />
  </>
)

InternalAreasTable.propTypes = {
  internalAreas: PropTypes.array,
  onDestroy: PropTypes.func,
  totalPages: PropTypes.number
}

TableActions.propTypes = {
  area: PropTypes.object,
  handleDelete: PropTypes.func
}

export default InternalAreasTable
