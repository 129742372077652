import React, { Fragment } from 'react'

import { ORDER_OPTIONS } from '../../../constants/globals'
import useFilters from '../../../hooks/useFilters'
import BoxTable from '../../ui/BoxTable'
import WebServiceTable from './WebServiceTable'

export const WebServices = () => {
  const {
    onFilter,
    initialFilters,
    results: webServices
  } = useFilters('admin/entity_users.json', { sort: 'created_at desc' })

  return (
    <Fragment>
      <BoxTable
        title="Credenciales"
        initialFilters={initialFilters}
        onFilter={onFilter}
        buttons={[{ Text: 'Generar credenciales', cardUrl: '/admin/web-services/crear', isActiveButton: true }]}
        sortOptions={ORDER_OPTIONS}
        values={webServices}
        searchPlaceholder="Buscar por entidad"
        displayTotal={true}
        searchFieldName="search"
        className="mb-8"
      >
        <WebServiceTable data={webServices.data} totalPages={webServices.total_pages} />
      </BoxTable>
    </Fragment>
  )
}
