import React, { useContext, useState } from 'react'

import PropTypes from 'prop-types'
import LabelDeleteDialog from '~/components/notifications/LabelDeleteDialog'
import LabelEditDialog from '~/components/notifications/LabelEditDialog'
import { AlertContext } from '~/utils/context'

import { LabelButton } from './LabelButton'
import { LabelButtonMobile } from './LabelButtonMobile'
import { LabelFilterListContent } from './LabelFilterListContent'

export const LabelFilterList = ({ childsName }) => {
  const [editLabel, setEditLabel] = useState(null)
  const [deleteLabel, setDeleteLabel] = useState(null)
  const { setAlertMessage } = useContext(AlertContext)
  return (
    <>
      <div>
        <div className="flex items-center justify-between pr-3.25 pt-8 pb-5">
          <h3 className="font-bold">Etiquetas</h3>
          <LabelButton
            parentClassName="hidden md:block"
            buttonClassName="text-blue-700"
            onCreated={newLabel =>
              setAlertMessage(`Se generó la etiqueta <span class="font-bold">${newLabel.name}.</span>`)
            }
          />
          <LabelButtonMobile />
        </div>
        <LabelFilterListContent name={childsName} onEdit={label => setEditLabel(label)} onDelete={setDeleteLabel} />
      </div>
      {editLabel && <LabelEditDialog label={editLabel} onClose={() => setEditLabel(null)} />}
      {deleteLabel && <LabelDeleteDialog label={deleteLabel} onClose={() => setDeleteLabel(null)} />}
    </>
  )
}

LabelFilterList.propTypes = {
  childsName: PropTypes.string
}
