import React from 'react'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PropTypes from 'prop-types'
import { MessageDetailPaginationOption } from './MessageDetailPaginationOption'

export const MessageDetailPagination = ({ message }) => {
  return (
    <div className="hidden ml-auto md:block">
      <div className="flex items-center">
        <div className="pr-8">
          {message.current_index} de {message.total_count}
        </div>
        {
          <MessageDetailPaginationOption
            value={message.previous}
            to={`/notificaciones/${message.previous}`}
            icon={<NavigateBeforeIcon />}
          />
        }
        {
          <MessageDetailPaginationOption
            value={message.next}
            to={`/notificaciones/${message.next}`}
            icon={<NavigateNextIcon />}
          />
        }
      </div>
    </div>
  )
}

MessageDetailPagination.propTypes = {
  message: PropTypes.object
}
