import React from 'react'
import { ReactSVG } from 'react-svg'

import DocumentsIcon from '../assets/images/documents_icon.svg'
import FormalitiesIconDisabled from '../assets/images/formalities_icon_disabled.svg'
import NotificationsIcon from '../assets/images/notifications_icon.svg'
import { Container } from '../components/Container'
import CardInfo from '../components/ui/CardInfo'
import HeadingTag from '../components/ui/HeadingTag'

export const HomePage = () => {
  return (
    <Container className="md:h-auto">
      <div className="px-5 md:px-20 pt-8 md:pt-14 pb-14 md:pb-18">
        <HeadingTag title="Bienvenido a tu carpeta ciudadana" className="font-bold text-3xl md:text-4xl" />
        <p className="pt-4 md:pt-5">
          Revisa tus documentos, el avance de tus trámites y las notificaciones que te envían las entidades públicas.
        </p>
        <div className="block md:flex">
          <CardInfo
            cardIcon={<ReactSVG src={DocumentsIcon} />}
            title="Mis documentos"
            infoText="Encuentra todos tus documentos generados con información personal"
            buttonText="Ver todos mis documentos"
            cardUrl="/documentos"
            cardClassName="mt-6 md:mt-9 md:mr-5"
            contentClassName="text-center"
          />
          <CardInfo
            cardIcon={<ReactSVG src={NotificationsIcon} />}
            title="Mis notificaciones"
            infoText="Revisa notificaciones sobre tus trámites y solicitudes"
            buttonText="Ver todas mis notificaciones"
            cardUrl="/notificaciones"
            cardClassName="mt-6 md:mt-9"
            contentClassName="text-center"
          />
        </div>
        <div className="border-t mt-8 pt-8 md:mt-10 md:pt-10 md:pb-72px">
          <h2 className="font-bold text-2xl md:text-3xl pb-6 md:pb-8">Próximamente en tu carpeta ciudadana</h2>
          <CardInfo
            cardIcon={<ReactSVG src={FormalitiesIconDisabled} />}
            title="Seguimiento de trámites"
            infoText="Sigue el avance de tus trámites iniciados en diferentes entidades"
            buttonText="Próximamente"
            contentClassName="text-center"
            isActiveButton={false}
          />
        </div>
      </div>
    </Container>
  )
}
