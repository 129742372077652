import React from 'react'

import PropTypes from 'prop-types'

import { Container } from '../Container'
import { MessageDetailActions } from './MessageDetailActions'
import { MessageDetailPagination } from './MessageDetailPagination'

export const MessageNavigation = ({ message }) => {
  if (!message) return null

  return (
    <nav className="border-b-0.325 border-gray-80">
      <Container>
        <div className="flex items-center px-5 py-6 md:px-20 md:py-4">
          <MessageDetailActions messageId={message.id} highlight={message.highlight} labelId={message.label_id} />
          <MessageDetailPagination message={message} />
        </div>
      </Container>
    </nav>
  )
}

MessageNavigation.propTypes = {
  message: PropTypes.object
}
