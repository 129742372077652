import React from 'react'

import PropTypes from 'prop-types'

import HeadingTag from './HeadingTag'

const PageHeading = ({ title, pageDescription }) => {
  return (
    <section className="mb-10" aria-labelledby="messages-label">
      <HeadingTag title={title} className="font-bold text-4xl" />
      <p className="mt-6">{pageDescription}</p>
    </section>
  )
}

export default PageHeading

PageHeading.propTypes = {
  title: PropTypes.string,
  pageDescription: PropTypes.string
}
