import React from 'react'

import PropTypes from 'prop-types'

const TermLabel = ({ prefix, term, path }) => (
  <span className="font-normal text-base leading-5.5">
    {prefix}
    <a className="text-blue-700 font-medium underline" href={path} target="_blank" rel="noreferrer">
      {term}
    </a>
  </span>
)

TermLabel.propTypes = {
  prefix: PropTypes.string,
  term: PropTypes.string,
  path: PropTypes.string
}

export const termsOptions = [
  {
    name: <TermLabel prefix="Acepto los " term="Términos y condiciones de uso" path="/terminos_condiciones_cue.pdf" />,
    code: 1
  },
  {
    name: (
      <TermLabel
        prefix="Acepto la "
        term="Declaración de veracidad de los datos proporcionados"
        path="/declaracion_veracidad_cue.pdf"
      />
    ),
    code: 2
  }
]

export default TermLabel
