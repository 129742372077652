import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { tagConfig } from '../../configs/messages'
import { MAX_LENGTH_MESSAGE_SUBJECT_TABLE } from '../../constants/globals'
import { truncateText } from '../../helpers/helpers'
import { usePatchMessage } from '../../hooks/useMessages'
import CheckboxField from '../fields/CheckboxField'
import Tag from '../ui/Tag'
import { MessagesActions } from './MessagesActions'
import { MessagesOptionsTop } from './MessagesOptionsTop'

export const MessagesTable = ({ messages = [], setMessages }) => {
  const [checkedHeader, setCheckedHeader] = useState(false)
  const [selectMultiple, setSelectMultiple] = useState(false)
  const { mutate: patchMessage } = usePatchMessage()
  const navigate = useNavigate()

  const handleSelectedRow = id => navigate(`/notificaciones/${id}`)

  const handleAllChecked = () => {
    const msgs = messages?.map(msg => {
      return { ...msg, isChecked: !checkedHeader }
    })
    handleStates(!checkedHeader, !checkedHeader, msgs)
  }

  const handleCheckChildElement = id => {
    const msgs = messages?.map(msg => {
      if (msg.id === id) return { ...msg, isChecked: !msg.isChecked }
      else return { ...msg }
    })
    setSelectMultiple(true)
    setMessages(msgs)
  }

  const handleMultipleHighlight = () => {
    const msgs = messages?.map(msg => {
      if (msg.isChecked) {
        patchMessage({ id: msg.id, highlight: true, label_id: msg.label_id })
        return { ...msg, highlight: true, isChecked: false }
      } else return { ...msg }
    })
    handleStates(false, false, msgs)
  }

  const handleMultipleTagged = labelId => {
    const msgs = messages?.map(msg => {
      if (msg.isChecked) {
        patchMessage({ id: msg.id, highlight: msg.highlight, label_id: labelId })
        return { ...msg, label_id: labelId, isChecked: false }
      } else return { ...msg }
    })
    handleStates(false, false, msgs)
  }

  const handleStates = (stateHeader, stateMultiple, newMessages) => {
    setCheckedHeader(stateHeader)
    setSelectMultiple(stateMultiple)
    setMessages(newMessages)
  }

  useEffect(() => {
    const checkedMessages = messages.filter(msg => {
      return msg.isChecked
    })
    if (!checkedMessages.length) {
      setCheckedHeader(false)
      setSelectMultiple(false)
    }
  }, [messages])

  return (
    <Fragment>
      <MessagesOptionsTop
        messagesLength={messages.length}
        multipleOption={selectMultiple}
        checkedTop={checkedHeader}
        handleCheckTop={handleAllChecked}
        handleHighlightTop={handleMultipleHighlight}
        handleLabelTop={handleMultipleTagged}
      />
      <table className="messages-table table-auto w-full md:text-left leading-tight">
        <thead className="hidden md:table-header-group">
          <tr>
            <th></th>
            <th>Entidad</th>
            <th>Tipo</th>
            <th>Asunto</th>
            <th>Fecha</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {messages?.map(message => (
            <Fragment key={message.id}>
              <tr className={clsx(message.is_read ? '!bg-gray-80 hover:!bg-blue-400' : 'font-medium')}>
                <td className="hidden md:table-cell">
                  <CheckboxField
                    name={`checkChild-${message.id}`}
                    margin="none"
                    checked={message.isChecked}
                    onChange={() => handleCheckChildElement(message.id)}
                  />
                </td>
                <td onClick={() => handleSelectedRow(message.id)}>
                  <span className="font-bold md:hidden">Entidad: </span>
                  {message.institution}
                </td>
                <td className="mt-3.5 md:mt-0" onClick={() => handleSelectedRow(message.id)}>
                  <span className="font-bold md:hidden pr-2">Tipo:</span>
                  <Tag {...tagConfig[message.message_type]} />
                </td>
                <td className="mt-3.5 md:mt-0" onClick={() => handleSelectedRow(message.id)}>
                  <span className="font-bold md:hidden">Asunto: </span>
                  {truncateText(message.subject, MAX_LENGTH_MESSAGE_SUBJECT_TABLE)}
                </td>
                <td className="mt-3.5 md:mt-0" onClick={() => handleSelectedRow(message.id)}>
                  <span className="font-bold md:hidden">Fecha: </span>
                  {message.sent_in}
                </td>
                <td>
                  <MessagesActions
                    className="hidden md:flex md:items-center"
                    highlight={message.highlight}
                    messageId={message.id}
                    labelId={message.label_id}
                  />
                </td>
              </tr>
              <tr className="h-2 !bg-white !p-0"></tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </Fragment>
  )
}

MessagesTable.propTypes = {
  messages: PropTypes.array,
  setMessages: PropTypes.func
}
