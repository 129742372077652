import React from 'react'
import { ReactSVG } from 'react-svg'

import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'

import BackButton from '@/components/ui/BackButton'

import FilterListIcon from '../../assets/images/filter_list_icon.svg'
import FilterListMobileIcon from '../../assets/images/filter_list_mobile_icon.svg'
import useToggle from '../../hooks/useToogle'
import { Header } from '../ui/Header'
import { Button } from './Button'

const FilterMobile = ({ children }) => {
  const [toggleFilter, settoggleFilter] = useToggle(false)

  return (
    <div className="md:hidden">
      <button onClick={() => settoggleFilter()} className="mb-7.5 text-blue-700 font-bold flex items-center">
        <ReactSVG src={FilterListMobileIcon} />
        <div className="ml-2">Opciones de búsqueda</div>
      </button>
      <Modal open={toggleFilter} onClose={() => settoggleFilter()} className="md:hidden">
        <div className="bg-white font-bold flex flex-col max-h-full">
          <Header />
          <BackButton onClick={() => settoggleFilter()} />
          <div className="py-1 px-5 flex-1 overflow-y-auto">
            <h3 className="text-xl pb-5">Opciones de búsqueda</h3>
            <div>{children}</div>
          </div>
          <div className="p-5 shadow-button-mobile w-full">
            <Button onClick={() => settoggleFilter()} size="full">
              Aplicar filtros
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const Filter = ({ children }) => {
  return (
    <aside className="flex-auto max-w-84 mr-8">
      <div className="hidden md:block">
        <div className="flex mb-2">
          <ReactSVG src={FilterListIcon} />
          <h3 className="font-bold items-center border-gray-850 ml-3">Opciones de búsqueda</h3>
        </div>
        {children}
      </div>
      <FilterMobile>{children}</FilterMobile>
    </aside>
  )
}

export default Filter

Filter.propTypes = {
  children: PropTypes.object
}

FilterMobile.propTypes = {
  children: PropTypes.object
}
