import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Container } from '../../../components/Container'
import CardConfig from '../../../components/ui/CardConfig'
import HeadingTag from '../../../components/ui/HeadingTag'
import { setItemToLocalStorage } from '../../../helpers/helpers'
import { useModulesData } from '../../../hooks/useModules'

export const InstitutionModules = () => {
  const { data: modules, status } = useModulesData()
  const navigate = useNavigate()

  const handleButton = module => {
    setItemToLocalStorage('configType', module.active ? 'update' : 'create')
    navigate(`${module.id}/servicio`)
  }

  if (status !== 'success') return null

  return (
    <Container className="md:h-auto">
      <div className="px-5 md:px-20 pt-8 md:pt-14 pb-14 md:pb-18">
        <HeadingTag title="Configuración de módulos" className="font-bold text-3xl md:text-4xl" />
        <p className="pt-4 md:pt-5">Activa o edita los módulos disponibles para tu entidad.</p>
        <div className="md:mt-9 md:pb-72px">
          <div className="block md:flex grid-cols-3 gap-5">
            {modules.data?.map((module, index) => (
              <CardConfig
                key={index}
                title={module.name}
                labelType={module.active ? 'active' : 'inactive'}
                infoText={module.description}
                buttonText={module.active ? 'Editar' : 'Activar'}
                handleConfigButton={() => handleButton(module)}
              />
            ))}
          </div>
        </div>
      </div>
    </Container>
  )
}
