import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import StepConnector from '@material-ui/core/StepConnector'
import { makeStyles, withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const StepperConnector = withStyles({
  root: {
    marginLeft: 20,
    padding: '0px'
  },
  line: {
    borderColor: '#076725',
    borderLeftWidth: '2px',
    minHeight: '32px !important'
  }
})(StepConnector)

const useStyles = makeStyles({
  root: {
    backgroundColor: '#CF000B',
    color: 'white',
    borderRadius: '50%',
    padding: 0,
    fontSize: 14,
    width: 40,
    height: 40
  },
  active: {
    backgroundColor: '#076725',
    color: 'white'
  },
  completed: {
    backgroundColor: '#076725',
    color: 'white'
  },
  stepperRoot: {
    padding: 0,
    fontWeight: 700
  },
  contentRoot: {
    marginLeft: 20,
    paddingLeft: 35,
    borderColor: '#076725',
    borderLeftWidth: '2px',
    marginTop: '-14px'
  },
  label: {
    textColor: '#076725',
    color: 'white'
  },
  iconContainer: {
    paddingRight: 17
  },
  customLabelStyle: {
    color: '#076725 !important',
    fontWeight: 'bold !important',
    fontSize: '16px !important'
  },
  customLabelInactive: {
    color: '#CF000B !important',
    fontWeight: 'bold !important',
    fontSize: '16px !important'
  },
  labelRoot: {
    alignItems: 'normal !important'
  }
})

const CustomStepIcon = ({ icon, active, completed }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx('flex justify-center items-center', classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icon}
    </div>
  )
}

const VerticalLinearStepper = ({ steps, activeStep, stepContent }) => {
  const classes = useStyles()
  return (
    <Stepper
      activeStep={activeStep}
      classes={{ root: classes.stepperRoot }}
      connector={<StepperConnector />}
      orientation="vertical"
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={CustomStepIcon}
            classes={{
              root: classes.labelRoot,
              iconContainer: classes.iconContainer,
              label:
                activeStep === index
                  ? classes.customLabelStyle
                  : activeStep > 0
                  ? classes.customLabelStyle
                  : classes.customLabelInactive
            }}
          >
            {label}
          </StepLabel>
          <StepContent classes={{ root: classes.contentRoot }} TransitionProps={{ in: true }}>
            {stepContent[index]}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  )
}

export default VerticalLinearStepper

CustomStepIcon.propTypes = {
  icon: PropTypes.node,
  active: PropTypes.bool,
  completed: PropTypes.bool
}

VerticalLinearStepper.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
  stepContent: PropTypes.node
}
