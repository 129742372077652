import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../../api/apiCore'
import { Container } from '../../Container'
import { DocumentForm } from '../../forms/admin/documents/DocumentForm'
import HeadingTag from '../../ui/HeadingTag'

export const DocumentNew = () => {
  const navigate = useNavigate()

  const onSubmit = async values => {
    try {
      const data = {
        ...values,
        institution_id: values.institution_id?.value || '',
        category_ids: values.category_ids?.map(el => el.value) || [],
        need_ruc: values.need_ruc === 'SI'
      }

      await request({ url: '/admin/documents', data, method: 'POST' })
      navigate('/admin/documentos-y-categorias')
    } catch (error) {
      return { [FORM_ERROR]: 'Ocurrio un error al registrar el documento.' }
    }
  }

  return (
    <Fragment>
      <Container className="py-16 pl-20 px-6">
        <HeadingTag title="Crear documento" className="text-center mb-4 md:text-4xl font-bold pb-7.5 md:pb-9" />
        <DocumentForm onSubmit={onSubmit} create />
      </Container>
    </Fragment>
  )
}
