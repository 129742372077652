import React, { Fragment } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { getJsonItemFromLocalStorage, setItemToLocalStorage } from '../../../helpers/helpers'
import { useDocumentData, usePatchDocument } from '../../../hooks/useDocuments'
import { Container } from '../../Container'
import { DocumentForm } from '../../forms/admin/documents/DocumentForm'
import HeadingTag from '../../ui/HeadingTag'

export const DocumentEdit = () => {
  const { id: documentId } = useParams()
  const { data: myDocument = {}, status } = useDocumentData(documentId, '/admin/documents')
  const { mutate: patchDocument } = usePatchDocument()
  const navigate = useNavigate()

  if (status !== 'success') return null

  const onSubmit = async values => {
    if (values.additional_links.length === 0 && values.additional_links_attributes === undefined)
      values.additional_links_attributes = []

    setItemToLocalStorage(
      `doc_${values.id}_names`,
      JSON.stringify(values.additional_links_attributes?.map(el => el.name))
    )

    myDocument.additional_links.forEach(doc => {
      if (values.additional_links_attributes.every(dat => dat.id !== doc.id)) {
        values.additional_links_attributes.push({ ...doc, _destroy: true })
      }
    })

    const data = {
      ...values,
      institution_id: values.institution_id?.value || '',
      category_ids: values.category_ids?.map(el => el.value) || [],
      need_ruc: values.need_ruc === 'SI'
    }

    try {
      patchDocument(data)
      navigate('/admin/documentos-y-categorias')
    } catch (error) {
      return { [FORM_ERROR]: 'Ocurrio un error al registrar el documento.' }
    }
  }
  const additionalLinkNames = getJsonItemFromLocalStorage(`doc_${myDocument.id}_names`)

  const validateRender = additionalLinkNames
    ? JSON.stringify(myDocument.additional_links?.map(el => el.name).sort()) ===
      JSON.stringify(additionalLinkNames.sort())
    : true
  return (
    <Fragment>
      <Container className="py-16 pl-20 px-6">
        <HeadingTag title="Editar documento" className="text-center mb-4 md:text-4xl font-bold pb-7.5 md:pb-9" />
        {validateRender && <DocumentForm initialValues={myDocument} onSubmit={onSubmit} />}
      </Container>
    </Fragment>
  )
}
