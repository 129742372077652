import React from 'react'

import IconButtonMUI from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Tooltip from '@/components/ui/Tooltip'

const useStyles = makeStyles(() => ({
  colorPrimary: {
    '&:hover': {
      backgroundColor: '#EDF0F4'
    },
    '&:active': {
      backgroundColor: '#E2FBFF'
    }
  }
}))

const IconButton = ({ text, children, onClick, ...props }) => {
  const classes = useStyles()
  return (
    <Tooltip title={text}>
      <IconButtonMUI
        type="button"
        color="primary"
        aria-label={text}
        onClick={onClick}
        classes={{ root: '!p-2.5', colorPrimary: classes.colorPrimary }}
        disableRipple
        {...props}
      >
        {children}
      </IconButtonMUI>
    </Tooltip>
  )
}

export default IconButton

IconButton.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func
}
