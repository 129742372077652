import React from 'react'

import PropTypes from 'prop-types'

const MessageReviewFile = ({ file }) => (
  <article className="ml-1">
    <p>{file.name}</p>
    <p className="text-blue-700 font-medium leading-5 underline break-all">{file.route}</p>
  </article>
)

MessageReviewFile.propTypes = {
  file: PropTypes.object
}

export default MessageReviewFile
