import React, { useState } from 'react'

import PropTypes from 'prop-types'

import FeedbackForm from './FeedbackForm'

const Feedback = ({ senderType }) => {
  const [feedbackSent, setFeedbackSent] = useState({ show: false, message: '' })

  return (
    <section className="mx-5 md:mx-auto mb-14 md:mb-18 max-w-fit">
      {feedbackSent.show ? (
        <p className="bg-gray-40 p-6 md:p-8 font-bold leading-6 text-center text-blue-700 rounded">
          {feedbackSent.message}
        </p>
      ) : (
        <FeedbackForm onFeedbackSent={setFeedbackSent} senderType={senderType} />
      )}
    </section>
  )
}

export default Feedback

Feedback.propTypes = {
  senderType: PropTypes.string
}
