import React from 'react'

import PropTypes from 'prop-types'

import LineIcon from '../../assets/images/line_icon.svg'
import DateField from './DateField'
import * as validations from './Validations'

export const DateRange = ({ initText, finalText }) => {
  return (
    <div className="pb-8 border-b border-blue-200 z-0">
      <h2 className="font-bold mb-2">Fecha de envío</h2>
      <div className="flex items-center">
        <DateField
          name="sent_in_from"
          containerClassName="rounded"
          margin="none"
          placeholder={initText}
          validate={validations.isDate()}
        />
        <img src={LineIcon} alt="line-icon" className="mx-3" />
        <DateField
          name="sent_in_to"
          containerClassName="rounded"
          margin="none"
          placeholder={finalText}
          validate={validations.isDate()}
        />
      </div>
    </div>
  )
}

DateRange.propTypes = {
  initText: PropTypes.string,
  finalText: PropTypes.string
}
