import React, { Fragment } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../../api/apiCore'
import { Container } from '../../../components/Container'
import HeadingTag from '../../../components/ui/HeadingTag'
import { urlSearchParamsData } from '../../../helpers/helpers'
import { InternalAreasForm } from '../InternalAreasForm'

const fetchArea = areaId => request({ url: `/admin/areas/${areaId}.json`, method: 'GET' })

export const AreaEdit = () => {
  const { id: areaId } = useParams()
  const urlSearchParams = urlSearchParamsData()
  const { data: area, status } = useQuery(['area', areaId], () => fetchArea(areaId))
  const navigate = useNavigate()

  const onSubmit = async ({ name }) => {
    try {
      const data = urlSearchParams.institution_gobpe_id
        ? { name, institution_gobpe_id: urlSearchParams.institution_gobpe_id }
        : { name }
      const response = await request({ url: `/admin/areas/${areaId}.json`, data, method: 'PATCH' })
      if (!response.id) return { [FORM_ERROR]: response.response.data.name }
      navigate(
        urlSearchParams.institution_gobpe_id
          ? '/admin/areas-y-usuarios?institution_gobpe_id=' + urlSearchParams.institution_gobpe_id
          : '/admin/areas-y-usuarios'
      )
    } catch (error) {
      return { [FORM_ERROR]: 'Ocurrio un error al registrar el área.' }
    }
  }

  if (status !== 'success') return null

  return (
    <Fragment>
      <Container className="py-16 pl-20 px-6">
        <HeadingTag title="Editar área" className="mb-4 font-bold text-3xl" />
        <InternalAreasForm initialValues={area} onSubmit={onSubmit} />
      </Container>
    </Fragment>
  )
}
