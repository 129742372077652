import React from 'react'

import ButtonMui from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const typeButton = {
  primary: 'contained',
  secondary: 'outlined',
  alternative: 'bg-blue-gray-800 hover:bg-blue-gray-500 text-white',
  text: 'text'
}

const sizeMap = {
  xs: 'max-w-32',
  s: 'max-w-40',
  m: 'max-w-56',
  l: 'max-w-72',
  xl: 'max-w-84',
  full: ''
}

const additionalMap = {
  primary: '',
  secondary: 'bg-white border-2 border-blue-700 hover:border-2'
}

const useStyles = makeStyles(theme => ({
  containedPrimary: {
    background: '#0056AC',
    borderRadius: 4,
    color: 'white',
    height: 52,
    width: '100%',
    textTransform: 'none',
    fontSize: 16,
    boxShadow: 'none',
    '&:hover': {
      background: '#024A93',
      boxShadow: 'none'
    }
  },
  outlinedPrimary: {
    borderRadius: 4,
    borderColor: '#0056AC',
    color: '#0056AC',
    paddingTop: 12,
    paddingBottom: 12,
    [theme.breakpoints.up('sm')]: { height: 52 },
    width: '100%',
    borderWidth: '2px',
    fontWeight: 700,
    textTransform: 'none',
    fontSize: 16,
    '&:hover': {
      borderWidth: '2px'
    }
  },
  textPrimary: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 700,
    color: '#0056AC',
    padding: '0px',
    '&:disabled': {
      color: '#6F777B'
    },
    '&:hover': {
      background: 'transparent'
    }
  },
  disabledButton: {
    backgroundColor: '#DEE3EA!important',
    color: '#6F777B!important',
    fontSize: 16,
    fontWeight: 700
  }
}))

export const Button = ({
  el: El = 'button',
  variant = 'primary',
  className,
  size = 'm',
  color = 'primary',
  fullWidth = true,
  classes = {},
  type = 'button',
  ...props
}) => {
  const styles = useStyles()

  return (
    <ButtonMui
      component={El}
      variant={typeButton[variant]}
      color={color}
      fullWidth={fullWidth}
      classes={{
        containedPrimary: styles.containedPrimary,
        outlinedPrimary: styles.outlinedPrimary,
        textPrimary: styles.textPrimary,
        disabled: styles.disabledButton,
        label: 'tracking-normal',
        ...classes
      }}
      className={clsx(sizeMap[size], additionalMap[variant], className)}
      type={type}
      disableRipple
      {...props}
    />
  )
}

Button.propTypes = {
  el: PropTypes.element,
  variant: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  classes: PropTypes.object,
  type: PropTypes.string
}
