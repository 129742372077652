import React from 'react'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'

import ArrowRightIcon from '@/assets/images/arrow_right_icon.svg'

const BackButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="text-blue-700 font-bold px-5 py-4 flex gap-2">
      <ReactSVG src={ArrowRightIcon} /> Regresar
    </button>
  )
}
BackButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
export default BackButton
