import React from 'react'

import PropTypes from 'prop-types'

const KnowMore = ({ linkPath, text }) => (
  <a
    className="font-bold md:font-medium text-blue-700 underline"
    target="_blank"
    rel="noopener noreferrer"
    href={linkPath}
  >
    {text}
  </a>
)

KnowMore.propTypes = {
  linkPath: PropTypes.string,
  text: PropTypes.string
}

export default KnowMore
