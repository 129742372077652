import React from 'react'
import { useForm } from 'react-final-form'

import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'

import RadioButtonField from '../fields/RadioButtonField'

const useStyles = makeStyles({
  checkboxInput: {
    margin: '0',
    padding: '0 10px 0 0',
    color: '#26292e',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  contolLabel: {
    margin: '0',
    padding: '0 10px 0 0'
  }
})

export const FilterRadioButtonField = ({ className, name, label, value, margin, icon, checkIcon }) => {
  const { change } = useForm()
  const handleClick = e => {
    if (e.target.value === value.toString()) {
      change(name, null)
    }
  }
  return (
    <RadioButtonField
      className={className}
      formControlClassName="w-full"
      name={name}
      label={label}
      value={value.toString()}
      margin={margin}
      icon={icon}
      checkIcon={checkIcon}
      customClass={useStyles}
      onClick={handleClick}
    />
  )
}

FilterRadioButtonField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  margin: PropTypes.string,
  icon: PropTypes.element,
  checkIcon: PropTypes.element
}
