import { useMutation, useQuery, useQueryClient } from 'react-query'

import { request } from '../api/apiCore'

const fetchModuleById = id => request({ url: `/admin/institution_modules/${id}.json` })

const fetchModules = () => request({ url: '/admin/institution_modules.json' })

const fetchModulesPermission = () => request({ url: '/admin/modules-permissions' })

const patchModule = data => request({ url: `/admin/institution_modules/${data.id}`, method: 'PATCH', data })

export const useModuleData = id => useQuery(['module', id], () => fetchModuleById(id))

export const useModulesData = () => useQuery('modules', fetchModules)

export const useModulesPermissionData = () => useQuery('modulesPermission', fetchModulesPermission)

export const usePatchModule = () => {
  const queryClient = useQueryClient()

  return useMutation(patchModule, {
    onSuccess: () => {
      queryClient.invalidateQueries('modules')
    }
  })
}
