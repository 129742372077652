import React, { Fragment, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../../api/apiCore'
import { Container } from '../../../components/Container'
import HeadingTag from '../../../components/ui/HeadingTag'
import { urlSearchParamsData } from '../../../helpers/helpers'
import { InternalAreasForm } from '../InternalAreasForm'

export const AreaNew = () => {
  const navigate = useNavigate()
  const urlSearchParams = urlSearchParamsData()

  const onSubmit = async ({ name }) => {
    try {
      const data = urlSearchParams.institution_gobpe_id
        ? { name, institution_gobpe_id: urlSearchParams.institution_gobpe_id }
        : { name }
      const response = await request({ url: '/admin/areas', data, method: 'POST' })
      if (!response.id) return { [FORM_ERROR]: response.response.data.name }
      navigate(
        urlSearchParams.institution_gobpe_id
          ? '/admin/areas-y-usuarios?institution_gobpe_id=' + urlSearchParams.institution_gobpe_id
          : '/admin/areas-y-usuarios'
      )
    } catch (error) {
      return { [FORM_ERROR]: 'Ocurrio un error al registrar el área.' }
    }
  }
  const initialValues = useMemo(
    () => ({
      name: ''
    }),
    []
  )

  return (
    <Fragment>
      <Container className="py-16 pl-20 px-6">
        <HeadingTag title="Crear área" className="mb-4 font-bold text-3xl" />
        <InternalAreasForm initialValues={initialValues} onSubmit={onSubmit} create />
      </Container>
    </Fragment>
  )
}
