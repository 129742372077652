import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { apiGetPdfRequest } from '../../api/apiCore'
import LoadingIcon from '../../assets/images/download_loader_icon.svg'
import { generateDocument } from '../../helpers/helpers'

const fetchData = async (url, setLoading) => {
  setLoading(true)
  const response = await apiGetPdfRequest(url)
  generateDocument(response, 'DocumentoGenerado.pdf')
  setLoading(false)
}

const LoadButton = ({ initialIcon, text, url, buttonClassName }) => {
  const [loading, setLoading] = useState(false)

  return (
    <button
      className={clsx(
        'flex items-center justify-center border-2 border-blue-700 text-blue-700 rounded',
        { 'cursor-not-allowed text-gray-500 bg-blue-200 border-gray-500': loading },
        buttonClassName
      )}
      disabled={loading}
      onClick={e => {
        e.stopPropagation()
        fetchData(url, setLoading)
      }}
    >
      <ReactSVG src={loading ? LoadingIcon : initialIcon} className={clsx({ 'animate-spin': loading })} />
      <p className="pl-1">{text}</p>
    </button>
  )
}

LoadButton.propTypes = {
  initialIcon: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  buttonClassName: PropTypes.string
}

export default LoadButton
