import React from 'react'

import PropTypes from 'prop-types'
import HeadingTag from '~/components/ui/HeadingTag'

const DialogTitle = ({ title }) => {
  return <HeadingTag title={title} className="font-bold text-xl pb-6 border-b border-neutral-300" />
}
DialogTitle.propTypes = {
  title: PropTypes.string
}
export default DialogTitle
