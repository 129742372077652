import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Container } from '../../../components/Container'
import { WebServiceForm } from '../../../components/forms/admin/WebServiceForm'
import Breadcrumb from '../../../components/ui/Breadcrumb'
import HeadingTag from '../../../components/ui/HeadingTag'
import { convertDataToBreadcrumbRoute } from '../../../helpers/helpers'
import { useCredentialData } from '../../../hooks/useWebServices'

export const WebServiceEdit = () => {
  const breadcrumbData = convertDataToBreadcrumbRoute('Credenciales vigentes')
  const [verifyCredential, setVerifyCredential] = useState({ status: true, data: null })
  const { id: credentialId } = useParams()
  const { data: credential } = useCredentialData(credentialId)

  useEffect(() => {
    if (credential) {
      const { client_id, client_secret } = credential
      setVerifyCredential({ ...verifyCredential, data: { client_id, client_secret } })
    }
  }, [credential])

  return (
    verifyCredential.data && (
      <Fragment>
        <Breadcrumb routesKey="webService" data={breadcrumbData} />
        <Container className="py-16 pl-20 px-6">
          <HeadingTag title="Credenciales vigentes" className="mb-4 font-bold text-3xl" />
          <WebServiceForm initialValues={credential} onSubmit={() => {}} verifyCredential={verifyCredential} />
        </Container>
      </Fragment>
    )
  )
}
