import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { Tooltip as TooltipMUI } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { tooltipSizes } from '@/utils/sizes'

const Tooltip = (
  { title, children, size = 'md', placement = 'bottom', classes, disableHoverListener, ...props },
  ref
) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  useImperativeHandle(ref, () => ({
    openTooltip: () => handleOpen()
  }))

  return (
    <TooltipMUI
      placement={placement}
      title={title}
      classes={{ tooltip: clsx('rounded bg-black !bg-gray-850', tooltipSizes[size]), ...classes }}
      disableHoverListener={disableHoverListener}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      {...props}
    >
      {children}
    </TooltipMUI>
  )
}

export default forwardRef(Tooltip)

Tooltip.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  disableHoverListener: PropTypes.bool,
  placement: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.node
}
