import React, { useContext } from 'react'
import { useQueryClient } from 'react-query'

import PropTypes from 'prop-types'

import Dialog from '../../components/ui/Dialog'
import DialogButtons from '../../components/ui/DialogButtons'
import DialogTitle from '../../components/ui/DialogTitle'
import { pluralize } from '../../helpers/helpers'
import useFilters from '../../hooks/useFilters'
import { destroyLabel } from '../../hooks/useLabels'
import { AlertContext } from '../../utils/context'

const DialogQuestion = ({ name, quantity }) => (
  <p className="pt-6">
    {quantity ? (
      <>
        ¿Estás seguro de quitar la etiqueta <b>{name}</b> de {pluralize(quantity, 'mensaje', 'mensajes')} y eliminarla?
      </>
    ) : (
      <>
        ¿Estás seguro de eliminar la etiqueta <b>{name}</b>?
      </>
    )}
  </p>
)

const LabelDeleteDialog = ({ label, onClose }) => {
  const { setAlertMessage } = useContext(AlertContext)
  const { filters, onFilter } = useFilters()
  const queryClient = useQueryClient()

  const handleDelete = async () => {
    await destroyLabel(label.id)
    await queryClient.invalidateQueries('labels')

    if (label.messages_number) {
      if (label.id.toString() === filters.label_id) {
        delete filters.label_id
        onFilter(filters)
      } else {
        await queryClient.invalidateQueries('messages-user.json')
      }
    }

    setAlertMessage(`Se eliminó la etiqueta <span class="font-bold">${label.name}.</span>`)
    onClose()
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <>
        <DialogTitle title="Eliminar etiqueta" />
        <DialogQuestion name={label.name} quantity={label.messages_number} />
        <DialogButtons confirmLabel="Eliminar" onCancel={onClose} onConfirm={() => handleDelete()} />
      </>
    </Dialog>
  )
}

DialogQuestion.propTypes = {
  name: PropTypes.string,
  quantity: PropTypes.number
}

LabelDeleteDialog.propTypes = {
  label: PropTypes.object.isRequired,
  onClose: PropTypes.func
}

export default LabelDeleteDialog
