import { useMutation, useQuery, useQueryClient } from 'react-query'
import { request } from '../api/apiCore'

const fetchDocumentById = (id, root) => request({ url: `${root}/${id}.json` })

export const useDocumentData = (id, root) => useQuery(['document', id], () => fetchDocumentById(id, root))

const patchDocument = data => request({ url: `/admin/documents/${data.id}`, method: 'PATCH', data })

export const usePatchDocument = () => {
  const queryClient = useQueryClient()

  return useMutation(patchDocument, {
    onSuccess: data => {
      const documentId = data.id
      queryClient.invalidateQueries('admin/documents.json')
      queryClient.invalidateQueries('documents')
      queryClient.invalidateQueries(['document', documentId])
    }
  })
}
