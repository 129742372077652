import React, { useContext } from 'react'

import PropTypes from 'prop-types'
import { AlertContext } from '~/utils/context'

import { usePatchMessage } from '../../hooks/useMessages'
import { LabelButton } from './LabelButton'
import { LabelListPopper } from './LabelListPopper'

export const LabelAs = ({ messageId, setOpenPopper }) => {
  const { mutate: patchMessage } = usePatchMessage()
  const { setAlertMessage } = useContext(AlertContext)

  const handleTagged = idLabel => {
    patchMessage({ id: messageId, label_id: idLabel })
    setOpenPopper(false)
  }

  return (
    <div className="w-62 bg-white shadow-label-window rounded-lg">
      <LabelListPopper
        title="Etiquetar como:"
        listClass="overflow-y-auto custom-scrollbar max-h-48"
        titleClass="px-4 pt-4"
        handleTagged={handleTagged}
      />
      <LabelButton
        parentClassName="px-4 py-3"
        buttonClassName="text-blue-700"
        onCreated={newLabel =>
          setAlertMessage(`Se generó la etiqueta <span class="font-bold">${newLabel.name}.</span>`)
        }
      />
    </div>
  )
}

LabelAs.propTypes = {
  messageId: PropTypes.string,
  highlight: PropTypes.bool,
  setOpenPopper: PropTypes.func,
  setLabel: PropTypes.func
}
