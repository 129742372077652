import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'

import EditIcon from '../../assets/images/edit_icon.svg'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import Alert from '../../components/ui/Alert'
import Breadcrumb from '../../components/ui/Breadcrumb'
import HeadingTag from '../../components/ui/HeadingTag'
import { convertDataToBreadcrumbRoute, formatPhoneNumber, getJsonItemFromLocalStorage } from '../../helpers/helpers'
import { useUserData } from '../../hooks/useUsers'

const ProfileSection = ({ title, className, children }) => (
  <section className={className}>
    <div className="p-6 md:p-10">
      <h2 className="font-bold text-2xl pb-1 md:pb-2">{title}</h2>
      {children}
    </div>
  </section>
)

const ProfileSectionRow = ({
  description,
  content,
  className = 'py-4',
  showBorder = true,
  showButton = false,
  buttonRoute
}) => (
  <div className={`${className} flex flex-col md:flex-row ${showBorder && 'border-b-2 border-white'}`}>
    <div className="basis-1/3 pb-2 md:pb-0">{description}</div>
    <div className="flex md:inline-flex justify-between basis-2/3">
      {content}
      {showButton && (
        <Link to={buttonRoute} className="flex items-center justify-end text-blue-700 font-medium underline">
          <ReactSVG src={EditIcon} className="hidden md:inline-flex pr-2.5" />
          Editar
        </Link>
      )}
    </div>
  </div>
)

export const ProfilePage = () => {
  const {
    user: { id }
  } = useAuth()
  const { data: user, status } = useUserData(id)
  const [alert, setAlert] = useState(getJsonItemFromLocalStorage('actionAlert'))
  const breadcrumbData = convertDataToBreadcrumbRoute('Mi perfil')

  if (['loading', 'error'].includes(status)) return null

  return (
    <>
      <Breadcrumb routesKey="myProfile" data={breadcrumbData} />
      <Container className="px-5 md:px-20 pt-8 md:pt-14 pb-14 md:pb-18">
        {alert && (
          <Alert type={alert.type} autoHideDuration={5000} setActionAlert={setAlert}>
            <span>{alert.text}</span>
          </Alert>
        )}
        <section className="mb-6 md:mb-9" aria-labelledby="profile-label">
          <HeadingTag title="Mi perfil" id="profile-label" className="font-bold text-4xl" />
          <p className="mt-4 md:mt-5">Administra tu cuenta de carpeta ciudadana</p>
        </section>
        <ProfileSection title="Información básica" className="max-w-3xl bg-gray-40">
          <ProfileSectionRow description="Nombres" content={user.name} />
          <ProfileSectionRow description="Apellidos" content={`${user.father_last_name} ${user.mother_last_name}`} />
          <ProfileSectionRow description="Tipo de documento" content={user.document_type_code.toUpperCase()} />
          <ProfileSectionRow description="Número de documento" content={user.document_number} />
          <ProfileSectionRow
            description="RUC"
            content={user.ruc || 'No registrado'}
            className="pt-4"
            showBorder={false}
            showButton
            buttonRoute={`/perfil/${id}/editar/ruc`}
          />
        </ProfileSection>
        <ProfileSection title="Información de contacto" className="max-w-3xl bg-gray-40 mt-6">
          <ProfileSectionRow
            description="Correo electrónico"
            content={user.email}
            showButton
            buttonRoute={`/perfil/${id}/editar/correo`}
          />
          <ProfileSectionRow
            description="Número de celular"
            content={formatPhoneNumber(user.phone_number)}
            className="pt-4"
            showBorder={false}
            showButton
            buttonRoute={`/perfil/${id}/editar/celular`}
          />
        </ProfileSection>
        <ProfileSection title="Contraseña" className="max-w-3xl bg-gray-40 mt-6">
          <p className="pt-4"> ************</p>
          <p className="pt-2 md:pt-2.5 pb-4 border-b-2 border-white">Última modificación: {user.last_modification}</p>
          <div className="pt-4 text-right">
            <Link to={`/perfil/${id}/editar/contrasena`} className="text-blue-700 font-medium underline">
              Cambiar contraseña
            </Link>
          </div>
        </ProfileSection>
      </Container>
    </>
  )
}

ProfileSection.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.array
}

ProfileSectionRow.propTypes = {
  description: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
  showBorder: PropTypes.bool,
  showButton: PropTypes.bool,
  buttonRoute: PropTypes.string
}
