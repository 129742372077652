import React from 'react'

import { Tooltip, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const TooltipInfoStyles = makeStyles({
  arrow: {
    color: '#26292E',
    left: '16px !important'
  },
  tooltip: {
    backgroundColor: '#26292E',
    fontSize: '16px',
    padding: '12px',
    'border-radius': '4px',
    'font-size': '12px',
    'line-height': '16px',
    top: '-7px',
    left: '-14px'
  }
})

export const TooltipField = ({ text, children }) => {
  const classes = TooltipInfoStyles()

  return (
    <Tooltip arrow classes={classes} title={text} placement="bottom-start">
      {children}
    </Tooltip>
  )
}

TooltipField.propTypes = {
  text: PropTypes.string,
  children: PropTypes.element
}
