import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const buttonClassNames = [
  'flex items-center',
  'p-4 md:p-6',
  'border border-blue-200 rounded-lg',
  'text-base text-left focus:text-white',
  'shadow-option-button',
  'hover:bg-blue-100 focus:bg-blue-700',
  'w-full'
]

const IconChangeButton = ({ label, className, handleClick, normalIcon, hoverIcon, pressedIcon }) => {
  const [icon, setIcon] = useState(normalIcon)

  return (
    <button
      className={clsx(buttonClassNames.join(' '), className)}
      onMouseEnter={() => setIcon(hoverIcon)}
      onMouseLeave={() => setIcon(normalIcon)}
      onFocus={() => setIcon(pressedIcon)}
      onClick={handleClick}
    >
      <ReactSVG src={icon} className="pr-3" />
      {label}
    </button>
  )
}

IconChangeButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  normalIcon: PropTypes.node,
  hoverIcon: PropTypes.node,
  pressedIcon: PropTypes.node
}

export default IconChangeButton
