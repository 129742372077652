import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../api/apiCore'
import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import DocumentGenerated from '../../components/documents/DocumentGenerated'
import DocumentUngenerated from '../../components/documents/DocumentUngenerated'
import Feedback from '../../components/feedback/Feedback'
import Alert from '../../components/ui/Alert'
import Breadcrumb from '../../components/ui/Breadcrumb'
import { convertDataToBreadcrumbRoute, getJsonItemFromLocalStorage } from '../../helpers/helpers'
import { useDocumentData } from '../../hooks/useDocuments'

export const DocumentDetailPage = () => {
  const { user, dispatch } = useAuth()
  const { documentId } = useParams()
  const { data: documentData } = useDocumentData(documentId, '/documents_user')
  const previousPage = getJsonItemFromLocalStorage('breadcrumbItem')
  const [alert, setAlert] = useState()

  if (!documentData) return null

  const breadcrumbData = previousPage
    ? [
        convertDataToBreadcrumbRoute(previousPage.parentTitle, previousPage.route, true),
        convertDataToBreadcrumbRoute(documentData.name)
      ]
    : convertDataToBreadcrumbRoute(documentData.name)

  const onSubmitDialog = async ({ person_type, check_digit }) => {
    const data = { ruc: `${person_type}${user.document_number}${check_digit}` }
    const response = await request({ url: `/users/${user.id}.json`, data, method: 'PATCH' })

    if (!response.id) return { [FORM_ERROR]: 'Ocurrio un error al editar número de RUC' }
    const contentActionAlert = {
      type: 'success',
      text: 'Se editó el RUC exitosamente. Recuerda que podrás editarlo desde la sección Mi Perfil.'
    }
    setAlert(contentActionAlert)
    dispatch({ type: 'Login', payload: { ...user, ruc: response.ruc } })
  }

  return (
    <>
      <Breadcrumb routesKey={previousPage ? 'documentDetail' : 'documentsSection'} data={breadcrumbData} />
      <Container>
        <div className="px-5 md:px-20 pt-8 md:pt-14 pb-40 md:pb-18">
          {alert && (
            <Alert type={alert.type} autoHideDuration={5000} setActionAlert={setAlert}>
              <span>{alert.text}</span>
            </Alert>
          )}
          {!documentData.is_generated ? (
            <DocumentUngenerated documentData={documentData} />
          ) : (
            <DocumentGenerated documentData={documentData} userData={user} onSubmitDialog={onSubmitDialog} />
          )}
          {documentData.additional_links.length > 0 && (
            <>
              <h2 className="font-bold text-2xl pb-4 md:pb-5">Información adicional</h2>
              <ul className="text-blue-700">
                {documentData.additional_links.map((link, key) => (
                  <li key={key} className={key > 0 ? 'pt-3' : 'pt-0'}>
                    <a className="underline" href={link.route} target="_blank" rel="noreferrer">
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </Container>
      <Feedback senderType="citizen" />
    </>
  )
}
