import React from 'react'
import { Link } from 'react-router-dom'

import { clsx } from 'clsx'
import PropTypes from 'prop-types'

export const MessageDetailPaginationOption = ({ value, to, icon }) => {
  return (
    <Link to={to} className={clsx(!value && 'invisible cursor-none')}>
      <div className="flex items-center justify-center text-blue-700 rounded hover:bg-gray-40 h-11 w-11">{icon}</div>
    </Link>
  )
}

MessageDetailPaginationOption.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.element,
  value: PropTypes.string
}
