import React from 'react'

import { useAuth } from '../../auth/Auth'
import { Container } from '../../components/Container'
import CardInfo from '../../components/ui/CardInfo'
import HeadingTag from '../../components/ui/HeadingTag'

export const HomePage = () => {
  const { admin } = useAuth()

  return (
    <Container className="md:h-auto">
      <div className="px-5 md:px-20 pt-8 md:pt-14 pb-14 md:pb-18">
        <HeadingTag title="Gestión de la Carpeta ciudadana" className="font-bold text-3xl md:text-4xl" />
        <p className="pt-4 md:pt-5">Gestiona las secciones de Carpeta ciudadana</p>
        <div className="mt-10 md:pb-72px">
          {admin.role !== 'collaborator' && (
            <h2 className="pb-8 text-xl font-bold">Configuración de la carpeta ciudadana</h2>
          )}
          <div className="block md:flex">
            {admin.is_any_admin && (
              <CardInfo
                title="Configuración de los módulos"
                infoText="Activa, edita o desactiva los modulos disponibles para tu entidad."
                buttonText="Ir a configuración"
                cardUrl="/admin/modulos"
                cardClassName="md:mr-5 mb-5 md:mb-0"
              />
            )}
            {admin.is_not_collaborator && (
              <CardInfo
                title="Áreas y usuarios"
                infoText="Gestiona las áreas y usuarios de la entidad."
                buttonText="Ir a configuración"
                cardUrl="/admin/areas-y-usuarios"
                cardClassName="md:mr-5 mb-5 md:mb-0"
              />
            )}
            {admin.is_admin_carpeta && (
              <CardInfo
                title="Admin Mis documentos"
                infoText="Gestiona los documentos y sus categorías"
                buttonText="Ir a configuración"
                cardUrl="/admin/documentos-y-categorias"
                cardClassName="md:mr-5 mb-5 md:mb-0"
              />
            )}
          </div>
          <div className="block md:flex mt-8">
            {admin.is_admin_carpeta && (
              <CardInfo
                title="Web services"
                infoText="Genera credenciales para las entidades"
                buttonText="Ir a configuración"
                cardUrl="/admin/web-services"
                cardClassName="md:mr-5 mb-5 md:mb-0"
              />
            )}
          </div>
          <h2 className="py-8 text-xl font-bold">Módulos activos</h2>
          <CardInfo
            title="Notificaciones"
            infoText="Envía notificaciones y comunicaciones, y revisa los mensajes enviados."
            buttonText="Ir a notificaciones"
            cardUrl="/admin/notificaciones"
          />
        </div>
      </div>
    </Container>
  )
}
