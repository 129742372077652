import React from 'react'

import PropTypes from 'prop-types'

import { documentDownload } from '../../helpers/helpers'

const MessageContentFile = ({ file }) => (
  <div className="grid mt-1.5">
    <div className="inline-block">
      <span className="font-medium">Nombre: </span>
      {file.name}
    </div>
    <a
      className="cursor-pointer"
      rel="noreferrer"
      onClick={() => documentDownload(`/file-infos/${file.id}.pdf`, `${file.name}.pdf`)}
    >
      <span className="font-medium">Ruta: </span>
      <span className="text-blue-700 underline break-all !font-normal">{file.route}</span>
    </a>
  </div>
)

MessageContentFile.propTypes = {
  file: PropTypes.object
}

export default MessageContentFile
